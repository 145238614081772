import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Box, FormControl, Grid, MenuItem, Select, Stack, ThemeProvider, Tooltip, Typography } from '@mui/material';
import { styled } from "@mui/system";
import React, { useEffect, useState } from 'react';
import theme from "../../../theme";

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    width: '100%',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '400px',
        marginLeft: '10px'
    },
}));

function DisplayDefectPercentageBar(item) {
    return (
        <Grid container spacing={2} style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
        }}>
            <Grid item xs={12} sm={12} md={8}>
                {item.DefectName}
            </Grid>
            <Grid item xs={4} sm={6} md={4}>
                <Box sx={{ width: 'auto', borderRadius: 16, overflow: 'hidden', marginLeft: '10px', marginRight: '10px' }}>
                    <Stack direction="row" spacing={0}>
                        <Tooltip title={`Positive Indicators: ${item.PercentComplete}%`}>
                            <Box sx={{ width: `${item.PercentComplete}%`, bgcolor: theme.palette.error.main, height: 10, filter: 'brightness(75%)' }} />
                        </Tooltip>
                        <Tooltip title={`Missing Indicators: ${item.PercentUnknown}%`}>
                            <Box sx={{ width: `${item.PercentUnknown}%`, bgcolor: theme.palette.grey.main, height: 10 }} />
                        </Tooltip>
                        <Tooltip title={`Counter Indicators: ${item.PercentBurnt}%`}>
                            <Box sx={{ width: `${item.PercentBurnt}%`, bgcolor: theme.palette.error.main, height: 10, opacity: .5 }} />
                        </Tooltip>
                    </Stack>
                </Box>
            </Grid>
        </Grid>

    );
}
export default function DefectDetailDropdown(props) {

    const categorizedData = props.data.reduce((result, currentValue) => {
        const groupKey = currentValue.Likelihood;
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(currentValue);
        return result;
    }, {});

    const getDropdownItemName = (item) => {
        return item.DefectName;
    }

    const handleDropdownChange = (value) => {
        let item = props.data.find(dr => dr.RecipeStateID == value);
        if (item) {
            let tempValue = {
                index: value,
                name: getDropdownItemName(item),
                item: item
            }

            props.handleDropdownChange(tempValue.index, tempValue.name, item.RecipeID, item);
        }
    };

    return (
        <CacheProvider value={muiCache}>
            <Box sx={{
                overflow: 'auto',
                flexGrow: 1, // To fill the available space and make the Stack scrollable
                height: '700px',
            }}>
                <Typography style={{
                    alignItems: 'center',
                    width: 'auto',
                }}>{props.title}</Typography>
                <StyledFormControl>
                    {Object.entries(categorizedData).map(([category, items]) => [<StyledMenuItem value={category} key={Date.now()} sx={{ pointerEvents: 'none' }} onClick={() => preventDefault()}>
                        {
                            <ThemeProvider theme={theme}>
                                <Grid container spacing={2} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    borderBottom: '1px solid black',
                                    paddingLeft: '10px',
                                    fontWeight: 'bold',
                                    color: 'black'
                                }}>
                                    <Grid item xs={12} sm={12} md={8}>
                                        {category} ({items.length})
                                    </Grid>
                                </Grid>
                            </ThemeProvider>
                        }</StyledMenuItem>, ...items.map((item) =>
                            <StyledMenuItem value={item.RecipeStateID} key={item.RecipeStateID} onClick={() => handleDropdownChange(item.RecipeStateID)}>
                                {
                                    <ThemeProvider theme={theme}>
                                        {DisplayDefectPercentageBar(item)}
                                    </ThemeProvider>
                                }</StyledMenuItem>
                        )])}
                </StyledFormControl>
            </Box>
        </CacheProvider>
    );
}