import React, { useState, useEffect, useRef } from 'react';
import { Paper, Typography, Box } from '@mui/material';

const Carousel = ({ AlarmNotificationActionSteps }) => {
    // State management for active step and image load failures
    const [activeStep, setActiveStep] = useState(0);
    const [failedToLoad, setFailedToLoad] = useState({});
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1 === AlarmNotificationActionSteps.length ? 0 : prevActiveStep + 1);
        }, 3000); // Change interval duration as necessary
        return () => clearInterval(interval); // Clean up on component unmount
    }, [AlarmNotificationActionSteps.length]);

    const handleImageError = (stepId) => {
        setFailedToLoad({ ...failedToLoad, [stepId]: true });
    };

    return (
        <Box sx={{ maxWidth: '100%', flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant="h5" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Recommended Remediation Steps
            </Typography>
            {AlarmNotificationActionSteps.map((step, index) => (
                <div key={step.ActionStepID} style={{ display: index === activeStep ? 'block' : 'none', padding: 16 }}>
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {/*{console.log(step.imageUrl)}*/}
                        {step.imageUrl ? (
                            <img
                                src={step.imageUrl}
                                alt={`Attachment for Step ${step.Ordering}`}
                                style={{ maxWidth: '61%', height: 'auto' }}
                                onError={() => handleImageError(step.ActionStepID)}
                            />
                        ) : (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 250,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#ddd',
                                    border: '2px dashed #ccc',
                                    borderRadius: '4px',
                                }}
                            >
                                <Typography variant="h4" component="div">
                                    No Image Available
                                </Typography>
                            </Box>
                        )}
                        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
                            {`Step ${step.Ordering}`}
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            {step.Summary}
                        </Typography>
                    </Paper>
                </div>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {AlarmNotificationActionSteps?.length > 1 && AlarmNotificationActionSteps.map((step, index) => (
                    <Box
                        key={step.ActionStepID}
                        sx={{
                            width: 10,
                            height: 10,
                            m: 0.5,
                            borderRadius: '50%',
                            backgroundColor: activeStep === index ? 'primary.main' : 'grey.400',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                        }}
                        onClick={() => setActiveStep(index)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default Carousel;