import React, { useState } from 'react';
import { Paper, Grid, IconButton } from '@mui/material';
import FormikSelectField from '../../Generic/FormikSelectField';
import FormikSwitch from '../../Generic/FormikSwitch';
import HelpIcon from '@mui/icons-material/Help';
import theme from "../../../theme";
import ClassificationHelpModal from "./ClassificationHelpModal";

const defectLevels = [
    {
        "value": 0,
        "label": "None"
    },
    {
        "value": 1,
        "label": "Minimal"
    },
    {
        "value": 2,
        "label": "Advanced"
    }
]

export default function VBTxConfigurationStep4(props) {

    const [open, setOpen] = useState(false);

    const helpButtonClick = () => {
        setOpen(true);
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >
                <Grid item xs={11} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'defectLevel'}
                        label={'Desired Defect Classification Level'}
                        data={defectLevels}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["defectLevel"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                <Grid item xs={1} justifyContent="center" alignItems="center">
                    <IconButton onClick={helpButtonClick} sx={{ float: 'left', ml: 2, mt: 2, color: theme.palette.primary.main }}>
                        <HelpIcon fontSize={'large'} />
                    </IconButton>
                </Grid>
                {props.formik?.values.defectLevel == 2 && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={'maximizeNodes'}
                        label={'Maximize Nodes per ControlGate'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["maximizeNodes"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
            </Grid>
            <ClassificationHelpModal
                open={open}
                handleModalClose={handleModalClose}
            />
        </Paper>
    )
}