import React, { useState, useEffect } from 'react';
import AccountCompanyInviteDataTable from './AccountCompanyInviteDataTable';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { FormatDate, RemoveTimeFromDate } from '../Generic/MiscFunctions';
import { useTheme } from "@mui/material/styles";

import {
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Typography,
    Box,
    Button,
} from '@mui/material';

export default function AccountCompanyInvitationsPending({ userInvites, handleInvitationResponse }) {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pendingInvites, setpendingInvites] = useState([]);

    useEffect(() => {
        if (userInvites && Array.isArray(userInvites)) {
            const filteredData = userInvites.filter(record => record.AcceptedOn === null && record.DeclinedOn !== null);
            setpendingInvites(filteredData);
        } else {
            setpendingInvites([]);
        }
    }, [userInvites]);

    const columns = [
        {
            name: 'CompanyName',
            label: 'Company Name',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'InvitedOn',
            label: 'Date Invited',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const invite = tableMeta.rowData; // Assuming each rowData has the full data object

                    return (
                        <Typography variant="body1" component="div" sx={{ fontSize: '1.2rem' }}>
                            {RemoveTimeFromDate(FormatDate(invite[1]))}
                        </Typography>                        
                    );
                }
            },
        },
        {
            name: 'InviterUserName',
            label: 'Invited By',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const invite = tableMeta.rowData; // Assuming each rowData has the full data object

                    return (
                        <Typography variant="body1" component="div" sx={{ fontSize: '1.2rem' }}>
                            {invite?.InviterUsername}{' '}({invite?.InviterEmail})
                        </Typography>
                    );
                }
            },
        },
        {
            name: '',
            label: '',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const invite = tableMeta.rowData; // Assuming each rowData has the full data object

                    return (
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                variant="outlined"
                                onClick={() => handleInvitationResponse(invite?.userID, invite?.companyID, true )}
                                sx={{ mr: 1 }}
                                startIcon={<ThumbUpIcon />}
                                color="success"
                            >
                                Accept 
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => handleInvitationResponse(invite?.userID, invite?.companyID, false)}
                                startIcon={<ThumbDownIcon />}
                                color="error"
                            >
                                Decline
                            </Button>
                        </div>
                    );
                },
            },
        },
    ];

    
    const options = {
        search: true,
        selectableRows: 'none',
        rowsPerPage: rowsPerPage,
        page: page,
        onChangePage: (currentPage) => {
            setPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setRowsPerPage(numberOfRows);
        },
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ paddingTop: 4 }} // Add padding to the top
        >  
            <Card sx={{ minWidth: 275, boxShadow: 0 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: theme.palette.primary.main }} aria-label="company">
                            <PendingActionsIcon />
                        </Avatar>
                    }
                    title="Pending Invitations"
                    titleTypographyProps={{ variant: 'h5', component: 'div' }}
                />
                <CardContent>                
                    <AccountCompanyInviteDataTable
                        title=""
                        data={pendingInvites}
                        columns={columns}
                        options={options}
                    />
                </CardContent>
            </Card>
        </Box>
    );
}