import React, { useState } from 'react';
import { TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { useClasses } from '../../customHooks';
import SyncAssetTableExpandedRow from './SyncAssetTableExpandedRow';
import createCache from '@emotion/cache';

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

const styles = {
    overrides: {
        MUIDataTableSelectCell: {
            expandDisabled: {
                visibility: 'hidden',
            },
        },
        MuiChip: {
            root: {
                backgroundColor: "gray"
            }
        },
        MUIDataTableFilter: {
            filterPaper: {
                width: "250px",
            },
        },
        MuiPopover: {
            paper: {
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 10,
                minWidth: '90%'
            }
        }
    },
}

export default function SyncAssetTableExpandable(props) {
    const classes = useClasses(styles);
    const theme = useTheme();

    const [rowsExpanded, setRowsExpanded] = useState([]);
    const [searchText, setSearchText] = useState("");

    const updateRowsExpanded = (allRowsExpanded) => {
        let myRowsExpanded = allRowsExpanded.map(item => {
            return item.dataIndex;
        });
        props.onRowExpansionChange(myRowsExpanded)
    }

    const updateRowsPerPage = (rowsPerPage) => {
        props.onChangeRowsPerPage(rowsPerPage);
    }

    const updateCurrentPage = (currentPage) => {
        props.onChangeCurrentPage(currentPage);
    }

    const updateSearchText = (currentSearchText) => {
        props.onChangeSearchText(currentSearchText);
        updateCurrentPage(0);
    }

    const handleSyncAsset = (asset, updateRow) => {
        props.handleSyncAsset(asset).then(updatedAsset => {
            if (updateRow && updatedAsset) {
                updateRow(updatedAsset);
            }
        });
    };

    const columns = [
        {
            name: "ParentPath",
            label: " ",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            {props.data[dataIndex].ParentPath}
                        </span>
                    );
                },
                setCellProps: () => ({
                    style: { width: '100%', maxWidth: '100%', borderTop: "1px solid rgba(224, 224, 224, 1)" }
                }),
                searchable: true,
                filter: false,
                sort: true,
                //customHeadRender: () => null,
            },
        }
    ]

    const options = {
        elevation: 5,
        pagination: false,
        filter: false,
        search: true,
        serverSide: false,
        viewColumns: false,
        download: false,
        print: false,
        selectableRows: 'none',
        responsive: 'standard',
        enableNestedDataAccess: ".",
        page: props.tablePageNum,
        onChangePage: (pageNum) => { updateCurrentPage(pageNum); },
        rowsPerPage: props.tableRowsPerPage,
        onChangeRowsPerPage: (numberOfRows) => { updateRowsPerPage(numberOfRows); },
        selectableRowsHideCheckboxes: true,
        searchAlwaysOpen: true,
        searchPlaceholder: 'Search',
        searchText: props.searchText,
        onSearchChange: (searchText) => { updateSearchText(searchText); },
        searchProps: {
            onBlur: (e) => {
            },
            onKeyUp: (e) => {
            }
        },
        //customSearch: (searchQuery, currentRow, columns, allRowsExpanded) => {
        //    const asset = props.data.find(item => item.ParentPath === currentRow[0]);
        //    if (!asset) return false;

        //    return hasSearchTextMatch([asset.ParentPath, asset.ChildrenDescriptions].concat(...asset.Children.map(c => [c.Name, c.Code])));
        //},
        rowsExpanded: props.tableRowsExpanded,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
            updateRowsExpanded(allRowsExpanded);
        },
        renderExpandableRow: (rowData, rowMeta) => {
            let childData = props.data[rowMeta.dataIndex]?.Children;

            //console.log("CHILDDATA: " + JSON.stringify(childData));

            return (
                <TableRow>
                    <SyncAssetTableExpandedRow
                        data={childData}
                        selectOptions={props.options}
                        selections={props.selections}
                        onSelectionChange={props.onSelectionChange}
                        colLength={columns.length}
                        companyInfo={props.companyInfo}
                        onUndoClick={props.handleUndoClick}
                        searchText={props.searchText}
                        onChangeSearchText={props.onChangeSearchText}
                        handleTriggerTestEvent={props.handleTriggerTestEvent}
                        handleSyncAsset={handleSyncAsset}
                        rowNameOverrides={props.rowNameOverrides}
                        toggleAssetNameOverride={props.toggleAssetNameOverride}
                        toggleAssetSaving={props.toggleAssetSaving}
                        assetLoadingStates={props.assetLoadingStates}
                    />
                </TableRow>
            );
        },
    };

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };

    return (
        <MUIDataTable
            title={props.title}
            data={props.data}
            columns={columns}
            options={options}
            className={classes.overrides}
            components={components}
        />
    );
}
