import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from '@mui/material';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import EmptyAsset from '../images/EmptyAsset.png';
import AssetDashboardTableExpandable from "./AssetDashboardTableExpandable";
import { useParams } from 'react-router-dom';
import { CompanyInfoContext } from '../../App';
import CustomHeader from '../Generic/CustomHeader';
import { GetSeverityStatusColorVIB, CalculateTimeDifference } from '../Generic/MiscFunctions';
import { fetchCompanyInfo } from '../Company/CompanyQueryFunctions';
import {
    fetchVibrationEnums,
    fetchVibrationDashboard
} from './VibrationQueryFunctions';
import { fetchImage } from '../images/ImageQueryFunctions';



export default function AssetDashboard() {
    const navigate = useNavigate();
    // Consume parameters from URL access
    const { companyID, userID, viewAll } = useParams();
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);

    // State to handle recurring DB interactions
    const initalState = 0;
    const [refresh, setRefresh] = useState(initalState);
    const [vibrationObjects, setVibrationObjects] = useState([]);
    const [vibrationObjectNodes, setVibrationObjectNodes] = useState([]);
    const [nodeDiagnosticStatus, setNodeDiagnosticStatus] = useState([]);
    const [nodeCheckInStatus, setNodeCheckInStatus] = useState([]);
    const [nodeMaxAlarmValues, setNodeMaxAlarmValues] = useState([]);

    // State to handle relevant enumerations
    const [driveType, setDriveType] = useState([]);
    const [machineType, setMachineType] = useState([]);
    const [positionType, setPositionType] = useState([]);
    const [alarmStatusInfo, setAlarmStatusInfo] = useState([]);

    // State to control rendered dashboard objects
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [dashboardData, setDashboardData] = useState([]);
    const [dashboardRowsExpanded, setDashboardRowsExpanded] = useState([]);
    const [dashboardRowsPerPage, setDashboardRowsPerPage] = useState(10);
    const [dashboardPage, setDashboardPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [errorState, setErrorState] = useState();
    const [imagesLoading, setImagesLoading] = useState(true);
    const [vendorIcon, setVendorIcon] = useState(null);
    const [failedToLoad, setFailedToLoad] = useState({});

    // Force react components to refresh every 10 seconds (will run on refresh variable change)
    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(refresh + 1);
        }, 10000);
        return () => clearInterval(interval)
    }, [refresh]);

    const { isLoading, error, data } =
        useQuery(["companyInfo", companyID], fetchCompanyInfo, {
        onSuccess: (data) => {
            setCompanyInfo(data);
            },
        onError: (error) => {
            // Handle the error here. For example, you can log the error or set an error state.
            //console.log("An error occurred while fetching company info:", JSON.stringify(enumsError));
            // Optionally, you can set an error state to display an error message to the user.
            setErrorState(error || "An unexpected error occurred.");
        }
    });

    const handleImageError = (vendorName) => {
        setFailedToLoad({ ...failedToLoad, [vendorName]: true });
    };

    const fetchVendorImage = async (ImageFileName) => {
        if (!ImageFileName) {
            setImagesLoading(false);
            return;
        }

        try {
            const image = await fetchImage(ImageFileName, process.env.REACT_APP_IMAGE_CONTAINER_NOTIFICATION);
            setVendorIcon(image);
        } catch (error) {
            console.error('Error fetching image:', error);
            // Handle error appropriately
        } finally {
            setImagesLoading(false);
        }
    };
    useEffect(() => {
        if (imagesLoading) {
            fetchVendorImage('fiix_logo_color_center.png');
        }
    }, []);

    // Fetch Enumerations from DB
    const { isLoading: isLoadingEnums, error: enumsError, data: dataE, refetch: refetchEnums } =
        useQuery(["enums"], fetchVibrationEnums, {
            onSuccess: (dataE) => {
                setDriveType(dataE.DriveTypes);
                setMachineType(dataE.MachineTypes);
                setAlarmStatusInfo(dataE.AlarmStatusInfo);
                setPositionType(dataE.PositionTypes)
                setIsLoadedEnums(true)
            },        
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });


    // Fetch Vibration Objects to populate dashboard from DB (only react on DB changes)
    const { isLoading: isLoadingVibrationObjects, error: errorVibrationObjects, data: dataA, refetch: refetchDashboard } =
        useQuery(["dashboard", companyID, userID, { refresh }], fetchVibrationDashboard, {
            onSuccess: (dataA) => {
                //console.log("Fresh Dashboard Information Retreived...");

                // Handle structural changes
                if (JSON.stringify(dataA.VibrationObjects) !== JSON.stringify(vibrationObjects)) {
                    setIsLoadingDashboard(true);
                    setVibrationObjects(dataA.VibrationObjects);
                }
                if (JSON.stringify(dataA.VibrationObjectNodes) !== JSON.stringify(vibrationObjectNodes)) {
                    setIsLoadingDashboard(true);
                    setVibrationObjectNodes(dataA.VibrationObjectNodes);
                }

                // Handle data changes
                let newNodeCheckInStatus = nodeCheckInStatus.map(obj => {
                    let newObj = { ...obj };
                    delete newObj.SecondsSinceCheckIn
                    return newObj;
                })

                if (JSON.stringify(dataA.NodeDiagnosticStatuses) !== JSON.stringify(nodeDiagnosticStatus)) {
                    setIsLoadingData(true);
                    setNodeDiagnosticStatus(dataA.NodeDiagnosticStatuses);
                }
                if (JSON.stringify(dataA.NodeCheckInStatuses) !== JSON.stringify(newNodeCheckInStatus)) {
                    setIsLoadingData(true);
                    setNodeCheckInStatus(dataA.NodeCheckInStatuses);
                }
                if (JSON.stringify(dataA.NodeMaxAlarmValues) !== JSON.stringify(nodeMaxAlarmValues)) {
                    setIsLoadingData(true);
                    setNodeMaxAlarmValues(dataA.NodeMaxAlarmValues);
                }
            },
            onError: (errorVibrationObjects) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorVibrationObjects || "An unexpected error occurred.");
            }
        });


    // Update Dashboard Structure when objects change
    useEffect(() => {
        if (isLoadingDashboard && isLoadedEnums && !imagesLoading) {
            handleDashboardStructureChange();
        }
    }, [vibrationObjects, vibrationObjectNodes, machineType, positionType, driveType, imagesLoading]);


    // Update Dashboard Structure when objects change
    useEffect(() => {
        if (isLoadingData && !isLoadingDashboard && isLoadedEnums && !imagesLoading) {
            handleDashboardDataChange();
        }
    }, [dashboardData, isLoadingData, isLoadedEnums, imagesLoading]);


    // Handler for structural change to network
    const handleDashboardStructureChange = async () => {

        // Cycle through each DB vibration object

        let newDashboardData = [];
        vibrationObjects.map((v)  => {
            //console.log("v: " + JSON.stringify(v));
            //Creating dashboard data object per asset

            const obj = {
                AccelerationID: v.AccelerationID,
                AnalysisTypeID: v.AnalysisTypeID,
                DisplayName: v.DisplayName,
                DriveType: driveType[v.DriveTypeID],
                EnabledVibrationChannels: v.EnabledVibrationChannels,
                FixedRPM: v.FixedRPM,
                GearMeshValue: v.GearMeshValue,
                HighRPM: v.HighRPM,
                ID: v.ID,
                IndividualNodeAlarms: v.IndividualNodeAlarms,
                IsConnected: v.IsConnected,
                LowRPM: v.LowRPM,
                MachineType: machineType[v.MachineTypeID],
                SamplingPeriod: v.SamplingPeriod,
                Nodes: [],
                NodeNames: v.DisplayName + " ",
                AssetStatus: {},
                VendorName: v.VendorName,
                VendorIcon: vendorIcon,
                ObjectDefectSummary: v.ObjectDefectSummary
            };

            // Cycle through each DB node object
            vibrationObjectNodes.filter(n => n.ObjectID === v.ID && n.NodeID != undefined).map((n) => {
                const node = {
                    NodeID: n.NodeID,
                    DisplayName: n.NodeDisplayName,
                    OrientationID: n.OrientationID,
                    PositionType: positionType.find(p => p.ID === n.PositionID),
                    IsConnected: n.IsConnected,
                    CheckinInfo: {},
                    SignalInfo: {},
                    BatteryInfo: {},
                    StatusInfo: {}
                };
                obj.Nodes.push(node);

                // Node names used for search function in datatable
                obj.NodeNames += (n.NodeDisplayName ? n.NodeDisplayName + " " : " ");
            });
            newDashboardData.push(obj);
        })

        // Update State
        setDashboardData(newDashboardData);
        setIsLoadingDashboard(false);
        setIsLoadingData(true);

    }


    // Handler for newly received data
    const handleDashboardDataChange = async () => {

        // Cycle through each asset object and look for new data
        dashboardData.map((o) => {

            let diagnostics = nodeDiagnosticStatus.filter(s => s.ObjectID === o.ID);
            o.Diagnostics = [];
            if (diagnostics && diagnostics.length > 0) {
                o.Diagnostics = o.Diagnostics.concat(diagnostics);
            }

            let checkin = nodeCheckInStatus.filter(s => s.ObjectID === o.ID);

            o.CheckinInfo = checkin;
            //console.log("checkin: " + JSON.stringify(checkin));

            let maxAlarm = nodeMaxAlarmValues.filter(a => a.ObjectID === o.ID);
            o.MaxAlarm = maxAlarm;
            //console.log("maxAlarm: " + JSON.stringify(maxAlarm));
            o.AssetStatus = parseAssetStatus(maxAlarm, checkin);
            //console.log("Asset: " + JSON.stringify(o.AssetStatus));

            // Cycle through each object node
            o.Nodes.map((n) => {
                n.SignalInfo = parseNodeSignal(o.ID, n.NodeID);
                n.BatteryInfo = parseNodeBattery(o.ID, n.NodeID);
                n.CheckinInfo = parseNodeCheckin(o.ID, n.NodeID);
                n.StatusInfo = parseNodeStatus(o.ID, n.NodeID, n.CheckinInfo);
            });
        });

        // Update state
        if (dashboardData.length > 0) {
            setDashboardData(dashboardData);

            //console.log("Updated Data:")
            //console.log(dashboardData)
        }
        setIsLoadingData(false);
    }


    // Pull wireless signal diagnostic from  incoming DB information
    const parseNodeSignal = (objectID, nodeID) => {
        const info = nodeDiagnosticStatus.find(
            d => d.ObjectID === objectID
                && d.NodeID === nodeID
                && d.DataTypeDisplayName.includes('Signal Strength')
        );

        if (info && Object.keys(info).length > 0) {
            return info;
        } else {
            return {};
        }
    }


    // Pull battery diagnostic from incoming DB information
    const parseNodeBattery = (objectID, nodeID) => {
        const info = nodeDiagnosticStatus.find(
            d => d.ObjectID === objectID
                && d.NodeID === nodeID
                && d.NodeChannelDisplayName === 'Battery Voltage'
        );

        if (info && Object.keys(info).length > 0) {
            return info;
        } else {
            return {};
        }

    }

    const parseAssetStatus = (maxAlarm, checkin) => {
        const maxAlarmValues = maxAlarm.map((alarm) => alarm.MaxAlarmValue);
        //console.log("maxAlarmValues" + JSON.stringify(maxAlarmValues));

        const nonNullValues = maxAlarmValues.filter((value) => value !== null);
        //console.log("nonNullValues" + JSON.stringify(nonNullValues));

        const maxAlarmValue = nonNullValues.length > 0 ? Math.max(...nonNullValues) : null;
        //console.log("maxAlarmValue" + JSON.stringify(maxAlarmValue));


        const secondsSinceCheckIns = checkin.map((checkIn) => CalculateTimeDifference(checkIn.CheckInTimeZone));
        const nonsecNullValues = secondsSinceCheckIns.filter((value) => value !== null);
        const minSecondsSinceCheckIn = nonsecNullValues.length > 0 ? Math.min(...nonsecNullValues) : null;

        return GetSeverityStatusColorVIB(
            maxAlarmValue,
            alarmStatusInfo,
            minSecondsSinceCheckIn
        );
    }

    // Pull node status from incoming DB information
    const parseNodeStatus = (objectID, nodeID, checkinInfo) => {
        const nodeStatus = nodeMaxAlarmValues.find(
            d => d.ObjectID === objectID
                && d.NodeID === nodeID
        );
        let status = nodeStatus ? nodeStatus.MaxAlarmValue : null;

        return GetSeverityStatusColorVIB(
            status,
            alarmStatusInfo,
            checkinInfo ? checkinInfo.SecondsSinceCheckIn : null
        );
    }


    // Pull node checkin info from incoming DB information
    const parseNodeCheckin = (objectID, nodeID) => {
        let checkin = nodeCheckInStatus.find(
            d => d.ObjectID === objectID
                && d.NodeID === nodeID
        );

        //console.log("CHECKIN 1: " + JSON.stringify(checkin));
        if (checkin && Object.keys(checkin).length > 0) {            
            const seconds = checkin.CheckInTimeZone ?
                CalculateTimeDifference(checkin.CheckInTimeZone) : null;
            //console.log(JSON.stringify(seconds));
            checkin.SecondsSinceCheckIn = seconds;

            //console.log("CHECKIN 2: " + JSON.stringify(checkin));

            return checkin;
        } else {
            return {};
        }
    }


    // Handle an Add Asset Button Click
    const handleAddButtonClick = () => {
        navigate(`/Vibration/AddAsset/${companyID}/${userID}/${viewAll}`);
    }

    const handleRowsPerPageChange = (rowsPerPage) => {
        setDashboardRowsPerPage(rowsPerPage)
    }
    // Handle a change in expanded rows
    const handleDashboardRowChange = (expandedRows) => {
        setDashboardRowsExpanded(expandedRows)
    }

    const handlePageChange = (currentPage) => {
        setDashboardPage(currentPage)
    }

    // Handle a change in search text
    const handleSearchTextChange = (searchText) => {
        if (!searchText) {
            setSearchText("");
        } else {
            setSearchText(searchText);
        }
    }

    // Handle MoreMenu open/close
    const handleMoreMenuChange = (expandedMenu) => {
        setMoreMenuExpanded(expandedMenu)
    }


    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }

    return (
        <Box sx={pageStyle}>
            <CustomHeader headerText={"Asset Dashboard"}/>
            {(isLoadingEnums || isLoadingVibrationObjects || isLoadingDashboard)
                && <StatusBackdrop open={(isLoadingEnums || isLoadingVibrationObjects || isLoadingDashboard) && refresh === 0} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Asset Dashboard"
                    //statusCode={errorState.request.status}
                    message={errorState.message}
                    error={errorState}
                />
            }
            {/*<GearBoxModal />*/}
            <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                <Grid container spacing={1} columns={6}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} align="right" justifyContent="right" alignItems="center">
                            <Button onClick={handleAddButtonClick} variant="contained">Add Asset</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {(((!dashboardData) || (dashboardData.length === 0)) && (!isLoadingDashboard) && (!isLoadingData)) &&
                    <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <img src={EmptyAsset} />
                        </Grid>
                    </Grid>
                }
            </Grid>
            {((dashboardData) && (dashboardData.length > 0) && (!isLoadingDashboard) && (!isLoadingData)) && (!imagesLoading) &&
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <AssetDashboardTableExpandable
                            title={''}
                            data={dashboardData}
                            alarmStatusInfo={alarmStatusInfo}
                            companyID={companyID}
                            userID={userID}
                            onRowExpansionChange={handleDashboardRowChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            onChangeCurrentPage={handlePageChange}
                            dashboardRowsExpanded={dashboardRowsExpanded}
                            dashboardRowsPerPage={dashboardRowsPerPage}
                            onChangeSearchText={handleSearchTextChange}
                            searchText={searchText}
                            dashboardPage={dashboardPage}
                            viewAll={viewAll}
                            searchAlwaysOpen={true}
                        />
                    </Grid>
                    {/*<h1 id="hidden-basic" sx={{ display: { lg: 'none' } }} >{refresh} </h1>*/}
                </Grid>
            }
        </Box>

    );
}

