import React from 'react';
import { Grid, Button } from "@mui/material";
import AddAlarmThreshold from './AddAlarmThreshhold';


export default function AssetAlarmingModalContent(props) {

    return (
        <React.Fragment>
            <Grid container spacing={1} columns={12}>
                <Grid container spacing={1} columns={12}>
                    <AddAlarmThreshold
                        positionIdx={props.positionIdx}
                        enums={props.enums}
                        users={props.users}
                        userGroups={props.userGroups}
                        value={props.dangerThreshold}
                        onChange={props.handleAlarmingChange}
                        thresholdID="DangerThreshold"
                        recipientIDs={props.dangerRecipientIDs}
                        key={"danger" + props.positionIdx}
                        name="Danger Alarm"
                        color='#a30f0f'
                        error={props.dangerThresholdError}
                    />
                </Grid>
                <Grid container spacing={1} columns={12}>
                    <AddAlarmThreshold
                        positionIdx={props.positionIdx}
                        enums={props.enums}
                        users={props.users}
                        userGroups={props.userGroups}
                        value={props.warningThreshold}
                        onChange={props.handleAlarmingChange}
                        thresholdID="WarningThreshold"
                        recipientIDs={props.warningRecipientIDs}
                        key={"warning" + props.positionIdx}
                        name="Warning Alarm"
                        color='#f2cb2e'
                        error={props.warningThresholdError}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} columns={12}>
                <Grid item xs={8} />
                {/* Save Button Grid Item */}
                <Grid item xs={2}>
                    <Button fullWidth onClick={props.closeModals} variant="contained">Save</Button>
                </Grid>
                {/* Cancel Button Grid Item */}
                <Grid item xs={2}>
                    <Button fullWidth onClick={props.closeModals} variant="contained">Cancel</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}