import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import {
    HighchartsProvider,
    HighchartsChart,
    Chart,
    XAxis,
    YAxis,
    Title,
    Legend,
    LineSeries,
    Tooltip
} from 'react-jsx-highcharts';

export default function LineChart(props) {
    const [seriesData, setSeriesData] = useState([]);
    const [yAxisTitle, setYAxisTitle] = useState('');

    const insertSpaceBeforeCapitals = (str) => {
        // Check if the string is null or empty
        if (str === null || str === '' || typeof str === 'undefined') {
            return '';
        }

        return str.split('').map((char, index) => {
            // Add a space before a capital letter if it's not the first character
            if (char === char.toUpperCase() && index !== 0) {
                return ' ' + char;
            }
            return char;
        }).join('');
    };


    useEffect(() => {
        if (props.plotData) {
            const significantGap = props.dataGap; // Define your time gap threshold here, e.g., 1 hour in milliseconds
            let modifiedData = [];
            let previousPoint = null;

            // Sort and adjust the data
            const sortedData = [...props.plotData].sort((a, b) => a.ComputedOnEpochSeconds - b.ComputedOnEpochSeconds);
            const adjustedData = sortedData.map(item => [item.ComputedOnEpochSeconds * 1000, Number(item.ConvertedComputedValue?.toFixed(3)) ]);

            // Insert null points to create breaks in the line
            adjustedData.forEach(point => {
                if (previousPoint && (point[0] - previousPoint[0]) > significantGap) {
                    // Insert a null point to create a break in the line
                    modifiedData.push([previousPoint[0] + ((point[0] - previousPoint[0]) / 2), null]);
                }
                modifiedData.push(point);
                previousPoint = point;
            });

            setSeriesData(modifiedData);
            if (props.plotData[0]?.DataTypeDisplayName) {
                setYAxisTitle(`${props.plotData[0]?.DataTypeDisplayName} (${props.plotData[0]?.ComputedValueSymbol})`);
            } else {
                setYAxisTitle(`No Data`);
            }
        }
    }, [props.plotData]);

    return (
        <>
            <HighchartsProvider Highcharts={Highcharts}>
                <HighchartsChart>
                    <Chart />

                    <Title>{props.title}</Title>

                    <Legend layout="horizontal" align="center" verticalAlign="bottom" />

                    <Tooltip
                        valueSuffix={!props.plotData[0]?.ComputedValueSymbol ?
                            '' :
                            ` (${props.plotData[0]?.ComputedValueSymbol})`}
                        valueDecimals={3}
                    />

                    <XAxis
                        type="datetime"
                        labels={{ format: '{value: %m/%e/%y}', rotation: 45, align: 'left' }}                        
                    >
                        <XAxis.Title>Sampled On</XAxis.Title>
                    </XAxis>


                    <YAxis>
                        <YAxis.Title>{yAxisTitle}</YAxis.Title>
                        <LineSeries
                            data={seriesData}
                            name={yAxisTitle}
                            showInLegend={typeof (props.showLegend) == undefined ? true : props.showLegend}
                            marker={{
                                enabled: true,
                                radius: 3  // Set the marker radius 
                            }}
                            plotOptions={{
                                series: {
                                    dataGrouping: {
                                        enabled: true // Enable data grouping handles breaks in time
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: function () {
                                    return Highcharts.dateFormat('%I:%M %p %d %b %Y', this.x) + '<br/>' + 'Value: ' + this.y.toFixed(3);
                                }
                            }}
                        />
                    </YAxis>
                </HighchartsChart>
            </HighchartsProvider>
        </>
    );
};

