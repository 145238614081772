import React, { useState } from 'react';
import { Typography, Stack, Modal, Button, IconButton, Paper, Grid, Tooltip, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import theme from "../../../theme";

const helpText = [
    {
        title: "Defect Confidence Bar",
        text: <Typography align="center">The Defect Confidence Bar provides a confidence level on any particular fault being the
            cause of an alarm. Confidence is increased with more data available that indicate specific defect indicators.</Typography>
    },
    {
        title: "What is an Indicator?",
        text: <Typography align="center">With every defect, there are certain indicators that are expected to be present.
            The unique combination of indicators for each defect allows us to isolate defects.</Typography>
    },
    {
        title: "Positive Indicators:",
        text: <Typography align="center">If the data we collect triggers one of the indicators for a fault, it is considered a
            <strong> Positive Indicator.</strong></Typography>
    },
    {
        title: "Missing Indicators:",
        text: <Typography align="center">If we did not collect the data required to evaluate one of the indicators, it is considered a
            <strong> Missing Indicator.</strong> This can happen if additional nodes are required for relationships between points on the
            machine (Phase Analysis) or it requires data that isn't being captured by the system.</Typography>
    },
    {
        title: "Counter Indicators:",
        text: <Typography align="center">If we collected the data required to evaluate one of the indicators for a fault but it does
            not trigger the indicator, it is considered a <strong>Counter Indicator.</strong></Typography>
    },
    {
        title: "Confidence Level",
        text: <Stack>
            <Typography align="center" sx={{ mb: 2 }}><strong>Positive Indicators:</strong> The presence of Positive Indicators increase
                our confidence that a given fault <strong> IS</strong> occurring. </Typography>
            <Typography align="center" sx={{ mb: 2 }}><strong>Missing Indicators:</strong> he presence of Missing Indicators decrease our
                ability to confidently assess whether a given fault is occurring or not. </Typography>
            <Typography align="center" sx={{ mb: 2 }}><strong>Counter Indicators:</strong> The presence of Counter Indicators increase
                our confidence that a given fault <strong> IS NOT</strong> occurring. </Typography>
        </Stack>
    },
]

export default function DefectHelpModal({ open, handleModalClose }) {
    return (
        <Modal
            open={open}
        >
            <Paper className="help-modal">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                        <IconButton sx={{ float: "right" }} onClick={handleModalClose}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">What Does This Bar Mean?</Typography>
                    </Grid>
                </Grid>
                <Stack alignItems="center">
                    <Box sx={{ width: '75%', overflow: 'hidden', marginLeft: '10px', marginRight: '10px', mb: 1 }}>
                        <Stack direction="row" spacing={0}>
                            <Box sx={{ width: `33%` }}>
                                <Typography align="center">POSITIVE INDICATORS</Typography>
                            </Box>
                            <Box sx={{ width: `33%` }}>
                                <Typography align="center">MISSING INDICATORS</Typography>
                            </Box>
                            <Box sx={{ width: `34%` }}>
                                <Typography align="center">COUNTER INDICATORS</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{ width: '75%', borderRadius: 16, overflow: 'hidden', marginLeft: '10px', marginRight: '10px' }}>
                        <Stack direction="row" spacing={0}>
                            <Box sx={{ width: `33%`, bgcolor: theme.palette.error.main, height: 15, filter: 'brightness(75%)' }} />
                            <Box sx={{ width: `33%`, bgcolor: theme.palette.grey.main, height: 15 }} />
                            <Box sx={{ width: `34%`, bgcolor: theme.palette.error.main, height: 15, opacity: .5 }} />
                        </Stack>
                    </Box>
                    {helpText.map((block, index) => {
                        return (
                            <Box key={index} sx={{ my: 1 }} >
                                <Typography variant="body1" fontWeight="bold" align="center">{block.title}</Typography>
                                {block.text}
                            </Box>
                        )
                    })}
                </Stack>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Button variant="contained" sx={{ mx: '25%', width: '50%' }} onClick={handleModalClose}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}