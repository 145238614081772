import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import BigAssetImage from './BigAssetImage';
import { Stack } from '@mui/material';

//green: #5CB85C

const CHART_WIDTH = 8

const imageStyle = {
    width: '46%',
    maxHeight: 350,
    marginLeft: "29.5%"
};

export default function AmplitudeDefectElevated(props) {
    const handleColumnClick = (dashboardDataID) => {
        const redirectUrl = new URL(document.referrer);
        redirectUrl.pathname = '/Data';
        redirectUrl.searchParams.set('NodeChannelIDs', dashboardDataID);
        window.top.location = redirectUrl;
    };
    const formatThresholdSeries = (data, attr) => {
        let thresholdSeries = [];
        thresholdSeries.length = CHART_WIDTH;
        if (data.length > 0) {
            let val = data[0][attr];
            for (let i = 0; i < CHART_WIDTH; i++) {
                thresholdSeries[i] = [i - 0.4, val];
            }
            thresholdSeries[0][0] += 0.01;
            thresholdSeries.push([thresholdSeries[thresholdSeries.length - 1][0] + 0.99, thresholdSeries[thresholdSeries.length - 1][1]]);
        }
        return thresholdSeries
    }

    const categories = []
    props.seriesData.forEach(item => {
        categories.push({
            value: item.chartColumn.category,
            label: item.name
        })
    });
    
    const chartOptions = {
        chart: {
            type: 'column',
        },
        title: {
            text: props.ingredient.IngredientDescription
        },
        subtitle: {
            text: props.assetName
        },
        xAxis: {
            type: 'linear', // Linear axis for custom increments
            tickInterval: 0.5,  // Set increment to 0.5
            max: CHART_WIDTH - 1,
            labels: {
                autoRotationLimit: 40,
                style: {
                    fontSize: 14
                },
                formatter: function () {
                    // Map x values to custom category labels
                    const customLabel = categories.find(item => item.value == this.value);
                    return customLabel ? customLabel.label : ''; // Use label or fallback to numeric
                }
            },
        },
        yAxis: {
            title: {
                text: `Vibration Amplitude (${props.ingredient.UnitTypeSymbol || 'ips'})`,
                style: {
                    fontSize: 16
                }
            },
            labels: {
                style: {
                    fontSize: 14
                }
            },
        },
        legend: {            
            enabled: false, // Enable the legend
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
        },
        tooltip: {
            formatter: function () {
                // Accessing custom properties from the data point
                return `<b>${this.point.name}</b>: ${this.point.y.toFixed(3)} (${ props.ingredient?.PrimaryComputedData.UnitTypeSymbol })<br/> ${this.point.channelDisplayName}`;
            }
        },
        plotOptions: {
            column: {
                grouping: false, // Ensures that columns don't overlap if there are multiple series
                pointPadding: 0.3, // Padding between columns
                groupPadding: 0.1, // Padding between groups of columns
                point: {
                    events: {
                        click: function () {
                            console.log(this.options);
                            handleColumnClick(this.options.dashboardDataID);
                        }
                    }
                }
            }
        },
        series: [
            ...props.seriesData.map((item, index) => ({
                type: 'column',
                name: item.name,    // Use the category name as the series name for legend
                data: [{
                    y: item.y,                 // The data point value
                    color: item.color,         // Use the specified color
                    x: item.chartColumn.position,
                    name: item.name,
                    channelDisplayName: item.channelDisplayName,  // Add the channelDisplayName to the point data
                    dashboardDataID: item.dashboardDataID
                }],
                showInLegend: true, 
                enableMouseTracking: true
            })),
            {
                type: 'line',  // Set the type to 'line' for this series
                name: 'warning', // Customize the name for the legend
                data: formatThresholdSeries(props.seriesData, "warningAlarmLevel"), 
                color: '#F0AD4E',  
                showInLegend: false,
                marker: {
                    enabled: false
                },
                enableMouseTracking: false
            },
            {
                type: 'line',  // Set the type to 'line' for this series
                name: 'danger', // Customize the name for the legend
                data: formatThresholdSeries(props.seriesData, "dangerAlarmLevel"),
                color: '#B30101',
                showInLegend: false,
                marker: {
                    enabled: false
                },
                enableMouseTracking: false
            }
        ], 
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 580
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    }
                }
            }]
        }
    }

    return (
        <Stack>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
            <BigAssetImage style={imageStyle} />
        </Stack>
        
    );
}
