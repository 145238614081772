import React, { useState, useEffect, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import FormikSelectField from '../Generic/FormikSelectField';
import NumericInputField from '../Generic/NumericInputField';
import GroupSelectField from '../Generic/GroupSelectField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
    Stack, 
    Switch, 
    Typography, 
    Paper,
    FormControlLabel,
    Grid
} from '@mui/material';



export default function AlarmStep2Content(props) {
    const [switchStatus, setSwitchStatus] = useState(props.formik?.values['sendNotification'] ? 'Enabled' : 'Disabled');
    const [dataTypeUnitTypes, setDataTypeUnitTypes] = useState([]);

    const upperCaseThresholdDataTypeName = props.formik?.values['thresholdDataTypeName']?.toUpperCase();
    
    const filteredAlarmStatusInfo = props.alarmStatusInfo.filter(
        (statusInfo) => statusInfo.label !== "Off" && statusInfo.label !== "Normal"
    );

    //console.log("Step 2: " + JSON.stringify(props.notifications));
    //console.log("recipients: " + JSON.stringify(props.recipients));

    useEffect(() => {
        let dtUnitTypes = props.unitTypes?.filter(
            (x) => x.dataTypeID === props.formik?.values['thresholdDataTypeID']
        );

        if (dtUnitTypes && dtUnitTypes.length > 0) {
            setDataTypeUnitTypes(dtUnitTypes);
        } else {
            setDataTypeUnitTypes([]);
        }
    }, [props.formik?.values['thresholdDataTypeID']]);

    const StyledTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const handleSwitchChange = (event) => {
        //props.formik?.setFieldValue('sendNotification', event.target.checked)
        // Updating the switch status
        setSwitchStatus(event.target.checked ? 'Enabled' : 'Disabled');
    };


    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid 
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={3}
            >
                <Grid item xs={12}>
                    <Typography variant="h4" fontWeight="bold" align="center" gutterBottom>
                        If {upperCaseThresholdDataTypeName} channel is
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <FormikSelectField
                            name="thresholdComparisonID"
                            id="thresholdComparisonID"
                            label="Comparison Operator"
                            data={props.comparisonOperatorTypes}
                            onChange={props.formik?.handleChange}
                            onBlur={props.formik?.onBlur}
                            form={props.formik}
                            width={'80%'}
                            enabled={'true'}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <NumericInputField
                            name="thresholdTriggerValue"
                            label="Threshold Trigger Value"
                            onChange={props.formik?.handleChange}
                            onBlur={props.formik?.onBlur}
                            form={props.formik}
                            step={0.01}
                            width={'80%'}
                            placeholder={"Enter Threshold"}
                            digits={2}
                            preventempty="true"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <FormikSelectField
                            name="thresholdUnitTypeID"
                            id="thresholdUnitTypeID"
                            label="Threshold Unit Type"
                            data={dataTypeUnitTypes}
                            onChange={props.formik?.handleChange}
                            onBlur={props.formik?.onBlur}
                            width={'80%'}
                            enabled={'true'}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <FormikSelectField
                            name="alarmSeverityID"
                            id="alarmSeverityID"
                            label="Alarm Severity Level"
                            data={filteredAlarmStatusInfo}
                            onChange={props.formik?.handleChange}
                            onBlur={props.formik?.onBlur}
                            width={'80%'}
                            enabled={'true'}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <StyledTooltip
                            title={
                                <Fragment>
                                    <Typography color="inherit">{switchStatus}</Typography>
                                </Fragment>
                            }
                        >
                            <FormControlLabel control={
                                <Switch
                                    checked={props.formik?.values['sendNotification']}
                                    onChange={(event) => {
                                        props.formik?.handleChange(event);
                                        handleSwitchChange(event);
                                        // Log Formik values after handleChange is called
                                    }}
                                    name="sendNotification"
                                    id="sendNotification"
                                />
                            }
                                label="Send Notifications"
                            />
                        </StyledTooltip>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <FormikSelectField
                            name="notificationID"
                            id="notificationID"
                            label="Notification"
                            data={props.notifications}
                            onChange={props.formik?.handleChange}
                            onBlur={props.formik?.onBlur}
                            width={'80%'}
                            formik={props.formik}
                            enabled={switchStatus === 'Enabled' ? 'true' : 'false'}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <GroupSelectField
                            name="notificationRecipients"
                            id="notificationRecipients"
                            label="Notification Recipients"
                            data={props.recipients}
                            onChange={props.formik?.handleChange}
                            onBlur={props.formik?.onBlur}
                            width={'80%'}
                            formik={props.formik}
                            enabled={switchStatus === 'Enabled' ? 'true' : 'false'}
                        />
                    </Stack>
                </Grid>

            </Grid>
            
        </Paper>
    );




}
