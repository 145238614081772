import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { ListItemText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from "react-router-dom";

export class ObjectDefectSummary {
    constructor(vibrationObjectID, assetName, recipeStateID, defectName, defectDescription, percentComplete, percentBurnt, percentUnknown, likelihood, totalDefectCount) {
        this.vibrationObjectID = vibrationObjectID;
        this.assetName = assetName;
        this.recipeStateID = recipeStateID;
        this.defectName = defectName;
        this.defectDescription = defectDescription;
        this.percentComplete = percentComplete;
        this.percentBurnt = percentBurnt;
        this.percentUnknown = percentUnknown;
        this.likelihood = likelihood;
        this.totalDefectCount = totalDefectCount;
    }
}

export default function DefectSummaryButton(props) {
    const { companyID, userID, objectDefectSummary } = props;

    const navigate = useNavigate();
    //console.log(objectDefectSummary);

    const handleClick = () => {
        navigate(`/Defect/Details/${companyID}/${userID}/${objectDefectSummary.VibrationObjectID}/${objectDefectSummary.RecipeStateID}`);
    };

    //const getTotalDefectText = () => {
    //    return objectDefectSummary.TotalDefectCount > 1 ? ` (${objectDefectSummary.TotalDefectCount})` : '';
    //};

    //const getConfidenceText = () => {
    //    return (objectDefectSummary.PercentComplete * 100).toFixed(0) + '%';
    //};

    //const getDefectTitleText = () => {
    //    return objectDefectSummary.DefectName;
    //};

    const getStartIcon = () => {
        return (<NotificationImportantIcon />);
    }

    let buttonText = `View Identified Defects`;

    return (
        objectDefectSummary != null && objectDefectSummary?.TotalDefectCount > 0
        &&
        <>
            <ListItemIcon>{getStartIcon()}</ListItemIcon>
            <ListItemText onClick={handleClick}>{buttonText}</ListItemText>
        </>
    );
}