import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

const initialState = {
    confirmVisible: false,
    confirmCallback: null,
    confirmOptions: {},
    confirmResponse: null,
};

const SET_CONFIRM_VISIBLE = 'SET_CONFIRM_VISIBLE';
const SET_CONFIRM_CALLBACK = 'SET_CONFIRM_CALLBACK';
const SET_CONFIRM_OPTIONS = 'SET_CONFIRM_OPTIONS';
const SET_CONFIRM_RESPONSE = 'SET_CONFIRM_RESPONSE';

export const setConfirmVisible = visible => ({
    type: SET_CONFIRM_VISIBLE,
    visible,
});

export const setConfirmCallback = callback => ({
    type: SET_CONFIRM_CALLBACK,
    callback,
});

export const setConfirmOptions = options => ({
    type: SET_CONFIRM_OPTIONS,
    options,
});

export const setConfirmResponse = response => ({
    type: SET_CONFIRM_RESPONSE,
    response,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIRM_VISIBLE:
            return { ...state, confirmVisible: action.visible };
        case SET_CONFIRM_CALLBACK:
            return { ...state, confirmCallback: action.callback };
        case SET_CONFIRM_OPTIONS:
            return { ...state, confirmOptions: action.options };
        case SET_CONFIRM_RESPONSE:
            return { ...state, confirmResponse: action.response };
        default:
            return state;
    }
};

export const store = createStore(reducer, applyMiddleware(thunk));
