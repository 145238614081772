import React, { useContext, useRef, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Toast } from 'primereact/toast';
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CompanyInfoContext } from '../../App';
import { fetchCompanyInfo } from '../Company/CompanyQueryFunctions';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import { fetchApiSubscription, fetchUserInfo, postApiSubscription } from './APIManagementQueryFunctions';

export default function APIManagement() {
    const toast = useRef(null);
    const { companyID, userID } = useParams();
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);
    const [userInfo, setUserInfo] = useState(null);
    const [apiSubscription, setApiSubscription] = useState(null);
    const [errorState, setErrorState] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState(null);
    const [isValidPassword, setIsValidPassword] = useState(false);

    const { isCompanyLoading } =
        useQuery(["companyInfo", companyID], fetchCompanyInfo, {
            onSuccess: (data) => {
                setCompanyInfo(data);
            },
            onError: (error) => {
                setErrorState(error);
            }
        });

    const { isUserLoading } =
        useQuery(["userInfo", companyID, userID], fetchUserInfo, {
            onSuccess: (data) => {
                setUserInfo(data);
                setSearchApiSubscriptionEnabled(true);
            },
            onError: (error) => {
                setErrorState(error);
            }
        });

    const [searchApiSubscriptionEnabled, setSearchApiSubscriptionEnabled] = useState(false);
    const { isApiSubscriptionLoading } =
        useQuery(["searchApiSubscription", companyID, userInfo?.Email], fetchApiSubscription, {
            enabled: searchApiSubscriptionEnabled,
            onSuccess: (data) => {
                setApiSubscription(data);
            },
            onError: (error) => {
                setApiSubscription(null);
                if (error?.response?.status != 404) {
                    setErrorState(error);
                }
            }
        });


    const mutation = useMutation(postApiSubscription);
    const handleRequestAPIAccess = (event) => {
        event.preventDefault();
        setIsSaving(true);
        mutation.mutate({
            queryKey: ["postApiSubscription", {
                CompanyID: companyID,
                CompanyName: companyInfo.CompanyName,
                FirstName: userInfo.FirstName,
                LastName: userInfo.LastName,
                Email: userInfo.Email,
                Password: password
            }]
        },
            {
                onSuccess: (data) => {
                    // Handle success
                    setIsSaving(false);
                    setApiSubscription(data);
                },
                onError: (data) => {
                    setIsSaving(false);
                    const toastMessage = {
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Registration was not accepted.',
                        life: 5000,
                        position: 'center'
                    };
                    toast.current.show(toastMessage);
                    console.error(data);
                }
            });
    };

    const handleViewDeveloperPortal = () => {
        window.open(apiSubscription.DeveloperPortalUrl, '_blank', 'noopener,noreferrer');
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setIsValidPassword(/^[a-z0-9!@#$%\^&*()_?+\-=]{8,15}$/i.test(event.target.value));
    };

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }
    return (
        <Box sx={pageStyle}>
            <Toast ref={toast} />
            <Typography variant="h4" component="h2" className="header">
                Maintenance Hub API
                <ArrowForwardIcon sx={{ marginLeft: '15px', marginRight: '15px' }} />
                {companyInfo.CompanyName}
            </Typography>
            <div style={{ padding: '10px' }}>
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                    {(isCompanyLoading || isUserLoading || isApiSubscriptionLoading || isSaving)
                        && <StatusBackdrop open={(isCompanyLoading || isUserLoading || isApiSubscriptionLoading || isSaving)} />}
                    {errorState &&
                        <StatusMessage
                            open={errorState}
                            severity="error"
                            location="Maintenance Hub API"
                            statusCode={errorState?.request?.status}
                            message={errorState.message}
                            error={errorState}
                        />
                    }
                    {(apiSubscription?.Email == null && !isCompanyLoading && !isUserLoading && !isApiSubscriptionLoading && !isSaving) &&
                        <Grid container spacing={2} columns={3} align="center" justifyContent="center" alignItems="center">
                            <Grid item xs={3}>
                                <Typography variant="button" display="block" fontWeight="bold" gutterBottom sx={{ textAlign: 'center', borderBottom: '#ccc solid 1px' }}>
                                    DEVELOPER API ACCOUNT
                                </Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}>
                                <TextField
                                    required
                                    hiddenLabel
                                    type={"text"}
                                    fullWidth
                                    value={userInfo?.Email || ""}
                                    disabled
                                    enabled={"false"}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}>
                                <TextField
                                    required
                                    type={showPassword ? "text" : "password"}
                                    label="Password"
                                    fullWidth
                                    value={password || ""}
                                    onChange={handlePasswordChange}
                                    pattern=""
                                    error={!isValidPassword}
                                    helperText={!isValidPassword ? <Typography variant="div">
                                        Minimum 8 characters, Maximum 15<br />Special characters are allowed
                                    </Typography> : ""}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" color="primary" fullWidth
                                    disabled={!isValidPassword}
                                    onClick={handleRequestAPIAccess}>REQUEST ACCESS</Button>
                            </Grid>
                        </Grid>
                    }
                    {(apiSubscription?.Email != null && !isCompanyLoading && !isUserLoading && !isApiSubscriptionLoading && !isSaving) &&
                        <Grid container spacing={2} columns={3} align="center" justifyContent="center" alignItems="center">
                            <Grid item xs={3}>
                                <Typography variant="button" display="block" fontWeight="bold" gutterBottom sx={{ textAlign: 'center', borderBottom: '#ccc solid 1px' }}>
                                    DEVELOPER API ACCOUNT
                                </Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}>
                                <TextField
                                    required
                                    hiddenLabel
                                    type={"text"}
                                    fullWidth
                                    value={userInfo?.Email || ""}
                                    disabled
                                    enabled={"false"}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" color="primary" fullWidth onClick={handleViewDeveloperPortal}>VIEW DEVELOPER PORTAL</Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </div>
        </Box>
    );
}