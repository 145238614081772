import apiClient from "../../http-common";
class AuthService {
    login(username, password) {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/login`;
        return apiClient
            .post(url, {
                username,
                password,
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    getCurrentUser() {
        const user = localStorage.getItem('user');
        return typeof user == 'string' ? JSON.parse(user) : null;
    }
}

export default new AuthService();