import apiClient from "../../http-common";
import { fetchRequest } from "../Generic/MiscFunctions";

export const fetchUserAccountMgmt = async ({ queryKey }) => {
    const [_key, userID] = queryKey;

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/User/userMgmt/${userID}`, "Failed to fetch User Account Mgmt");
};

export const acceptEULA = async ({ userID, versionID }) => {

    try {
        const response = await apiClient.post(`/User/usermgmt/addeula/${userID}/${versionID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error accepting EULA:", error);
        throw new Error("Error accepting EULA: ");
    }
};

export const setDefaultCompany = async ({ userID, companyID }) => {

    try {
        const response = await apiClient.post(`/User/userMgmt/defaultco/${companyID}/${userID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error setting default company:", error);
        throw new Error("Error setting default company:");
    }
};

export const updateUserProfile = async ({ userID, body }) => {

    try {
        let requestBody = {
            AdminID: body.adminID,
            UserID: body.userID,
            FirstName: body.firstName,
            LastName: body.lastName, 
            JobTitle: body.jobTitle,
            Department: body.department,
            PhoneCountryCode: body.phoneCountryCode,
            PhoneNumber: body.phoneNumber,
            EmailOptOut: body.emailOptOut,
            TextOptOut: body.textOptOut,
            TimeZoneID: body.timeZoneID,
            IsEnabled: body.isEnabled
        };

        const response = await apiClient.post(`/User/userMgmt/update/${userID}`, requestBody);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error setting default company:", error);
        throw new Error("Error setting default company:");
    }
};

export const respondToInvitation = async ({ userID, companyID, accept }) => {

    try {
        const response = await apiClient.post(`/User/userMgmt/respinvite/${companyID}/${userID}/${accept}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error responding to invitation:", error);
        throw new Error("Error responding to invitation:");
    }
};

export const fetchTimeZones = async () => {
 
    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Company/gettimezone`, "Failed to fetch timezones");
};

export const formatPhoneNumber = (countryCode, phoneNumber) => {
    if (!countryCode || !phoneNumber) {
        return 'Invalid phone number';
    }

    const trimmedCountryCode = countryCode.trim();

    const formatUSPhoneNumber = (number) => {
        const match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return number;
    };

    if (trimmedCountryCode === '1') {
        return `+${trimmedCountryCode} ${formatUSPhoneNumber(phoneNumber)}`;
    } else {
        return `+${trimmedCountryCode} ${phoneNumber}`;
    }
};

// FETCH
export const fetchUserUnitTypeSettings = async ({ queryKey }) => {
    const [_key, companyID, userID] = queryKey;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`UnitType/User/${companyID}/${userID}`, "Failed to fetch User Unit Type Settings");
};


export const fetchCompanyUnitTypeSettings = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`UnitType/Company/${companyID}`, "Failed to fetch Company Unit Type Settings");
};

// POST
export const postUnitTypeSettings = async ({ queryKey }) => {
    const [_key, userID, body] = queryKey;

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }

    if (!body) {
        throw new Error("The 'body' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`UnitType/User/${userID}`, "Failed to post Unit Type Settings", body);
};
