import React, { useState } from 'react';
import { Typography, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, TextField, Modal, Divider, Grid } from '@mui/material';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
export default function InviteUsers(props) {

    return (
        <TableContainer sx={{ mx: "auto", width: "50%" }} component={Paper}>
            <Typography variant="h5" align="center">Pending Invitations</Typography>
            {props.invites.length > 0 ? (<Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><b>User Email</b></TableCell>
                        <TableCell align="left"><b>Invited By</b></TableCell>
                        <TableCell align="left"><b>Invited On</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.invites.map((user) => (
                        <TableRow
                            key={user.Original}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {user.Original}
                            </TableCell>
                            <TableCell align="left">{user.InvitedByEmail}</TableCell>
                            <TableCell align="left">{user.InvitedOn}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>) : (<Box>
                    <br />
                    <Typography align="center">You have no pending invitations.</Typography>
                </Box>)
            }
            <br />
            <Box sx={{ margin: "2%" }} textAlign='right'>
                <Button variant="contained" onClick={props.handleInviteUsersClick}>Invite Users</Button>
            </Box>
            <Modal
                open={props.inviteModalOpen}
            >
                <Paper sx={modalStyle}>
                    <Typography variant="h6">Invite Users</Typography>
                    <Divider />
                    <br />
                    <Typography>Email Address(s)</Typography>
                    <TextField variant="outlined" fullWidth value={props.emailList} onChange={props.handleEmailListChange} />
                    <Typography>Enter one or more email address that you would like to invite to your company. Email addresses are case-insensitive and may be separated by commas, i.e. 'john@gracesense.com,jane@gracesense.com'</Typography>
                    <br />
                    <Grid container direction="row" alignItems="center" spacing={1}>
                        <Grid item xs={6}>
                            <Button variant="contained" style={{ float: "left" }} onClick={props.handleCloseInviteClick}>Close</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" style={{ float: "right" }} onClick={props.handleSendInviteClick}>Send Invites</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </TableContainer>
    )
}