import React, { useState, useEffect } from 'react';
import CustomAlarmChip from '../Generic/CustomAlarmChip';
import {
    Typography,
    Tooltip,
    Grid,
    Paper,
    Button,
    Box,
    Chip
} from '@mui/material/';

export default function AlarmStep3Content(props) {
    const [severityLevelObj, setSeverityLevelObj] = useState(props.alarmStatusInfo(props.formik?.values['alarmSeverityID']));
    const [objArray] = useState(props.dataTypeTargets);
    const [dataArray] = useState(props.formik?.values['assignedComputedDataSourceIDs']);
    const [expandedLoc, setExpandedLoc] = useState(false);
    const [showFullText, setShowFullText] = useState(false);
    const [recipients] = useState(props.formik?.values['notificationRecipients']);
    const [notificationID] = useState(props.formik?.values['notificationID']);
    const [matchingLabels, setMatchingLabels] = useState([]);
    const [alarmSummary] = useState(props.formik?.values?.['alarmSummary']?.trim());

    useEffect(() => {
        const inputs = document.querySelectorAll('input');
        inputs.forEach(input => {
            input.setAttribute('autocomplete', 'off');
            input.setAttribute('data-lpignore', 'true');
        });
    }, []);

    // Show the first 2 items if not expandedLoc
    const visibleItems = (expandedLoc && matchingLabels.length >= 2) ? matchingLabels : matchingLabels.slice(0, 2);

    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    const searchForData = (obj, dataValuesToFind) => {
        const stringArray = dataValuesToFind.map(num => num.toString());

        if (Array.isArray(obj)) {
            for (const item of obj) {
                searchForData(item, stringArray);
            }
        } else if (typeof obj === 'object' && obj !== null) {
            const dataValue = obj.data;
            const label = obj.label;

            if (dataValue && label && stringArray.includes(dataValue)) {
                setMatchingLabels(prevLabels => [...prevLabels, obj]);
            }
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    searchForData(obj[key], stringArray);
                }
            }
        }
    };

    useEffect(() => {
        setMatchingLabels([]); // Clear previous matching labels
        searchForData(objArray, dataArray);
    }, []);

    useEffect(() => {
        setMatchingLabels([]); // Clear previous matching labels
        searchForData(objArray, dataArray);
    }, [objArray, dataArray]);


    const handleSeeMoreClick = () => {
        setExpandedLoc(!expandedLoc);
    };

    const getUniqueGroups = (data) => {
        const groups = new Set();
        data.forEach(item => groups.add(item.group));
        return Array.from(groups);
    }

    const getThresholdTriggerValue = () => {

        const thresholdTriggerValue = parseFloat(props.formik?.values['thresholdTriggerValue']);

        let displayedValue;
        if (!isNaN(thresholdTriggerValue)) {
            // If it's a whole number, display as an integer. Otherwise, use toFixed(4).
            displayedValue = (thresholdTriggerValue === parseInt(thresholdTriggerValue))
                ? parseInt(thresholdTriggerValue)
                : thresholdTriggerValue.toFixed(4);
        } else {
            // If it's not a number, keep the original value
            displayedValue = props.formik?.values['thresholdTriggerValue'];
        }

        return (
            <Typography variant="h6" gutterBottom>
                {displayedValue}
            </Typography>
        );
    }

    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} >
                    <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                        Alarm Name
                    </Typography>
                    {/*<Tooltip title={props.formik?.values['alarmName']} arrow>*/}
                    <Typography
                        variant="h6"
                        display="block"
                        gutterBottom
                        style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
                    >
                        {props.formik?.values['alarmName']}
                    </Typography>
                    {/*    </Tooltip>*/}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                        Alarm Severity Level
                    </Typography>
                    {
                        (severityLevelObj) &&
                        <CustomAlarmChip
                            statusDisplayName={severityLevelObj.StatusDisplayName}
                            statusSeverityLevel={severityLevelObj.StatusSeverityLevel}
                            color={severityLevelObj.StatusColor}
                            keyName={
                                severityLevelObj.SystemStatusID + "-" +
                                severityLevelObj.CompanyID}

                        />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                        Summary
                    </Typography>
                    <Box overflow="hidden">
                        {alarmSummary && alarmSummary.length > 0 && (
                            <Typography variant="h6" gutterBottom>
                                {(showFullText || alarmSummary.length < 100)
                                    ? alarmSummary
                                    : `${alarmSummary.slice(0, 100)}...`}
                            </Typography>
                        )}
                    </Box>
                    {alarmSummary && alarmSummary.length >= 200 && (
                        <Button onClick={toggleShowFullText}>
                            {showFullText ? 'See less' : 'See more...'}
                        </Button>
                    )}
                </Grid>

                <Grid item xs={12} sm={3} >
                    <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                        Threshold Data Type
                    </Typography>
                    <Typography variant="h6" display="block" gutterBottom>
                        {props.formik?.values['thresholdDataTypeName']}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                        Comparison Operator
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {props.comparisonOperatorTypes.find(
                            x => x.value === props.formik?.values['thresholdComparisonID'])?.label
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                        Threshold Trigger Value
                    </Typography>
                    {getThresholdTriggerValue()}
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                        Threshold Unit Type
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {props.unitTypes.find(
                            x => x.value === props.formik?.values['thresholdUnitTypeID'])?.label
                        }
                    </Typography>
                </Grid>
                <br />
                <Grid container item xs={12}>
                    {props.formik?.values['sendNotification'] && (
                        <>
                            <Grid item xs={12} sm={3}>
                                <Typography variant="button" display="block" fontWeight="bold" fontWeight="bold" gutterBottom>
                                    Notification
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    {notificationID > 0 ? props.notifications.find(
                                        x => x.value === notificationID).label : "No Notification Selected"
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                                    Notification Recipients
                                </Typography>
                                {recipients.length > 0 && getUniqueGroups(recipients).map(group => (
                                    <Grid item xs key={"griditem-" + group}>
                                        <Typography key={"typography-" + group} variant="button" display="block" fontWeight="bold" gutterBottom>
                                            {group}
                                        </Typography>
                                        <ul key={"list-" + group}>
                                            {recipients.filter(item => item.group === group).map(item => (
                                                <li key={item.group + "-" + item.value}>{item.label}</li>
                                            ))}
                                        </ul>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={12}>
                        <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
                            Alarm Targets
                        </Typography>
                        <Grid container rowSpacing={1} columnSpacing={1}>
                            {visibleItems.map((item, index) => (
                                <Grid item xs={6} sm={6} md={6} lg={6} display="inline" key={index}>
                                    <Typography paragraph={false} component="div" variant="body2" gutterBottom key={index}>
                                        <Tooltip title={item.hierarchyPath || item.label} placement="top" arrow>
                                            <Chip color="secondary" label={item.hierarchyPath || item.label} />
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                        {matchingLabels.length > 2 && (
                            <Button onClick={handleSeeMoreClick}>
                                {expandedLoc ? 'See less' : 'See more...'}
                            </Button>
                        )}
                    </Grid>
                </Grid>

            </Grid>
        </Paper>
    );
}




//<Grid item xs={6}>
//    <Typography variant="button" display="block" fontWeight="bold" gutterBottom>
//        Notification Frequency
//    </Typography>
//    <Typography variant="h6" gutterBottom>
//        {props.notificationPeriods.find(
//            x => x.value === props.formik?.values['notificationPeriodID']).label
//        }
//    </Typography>
//</Grid>