import React, { useState } from 'react';
import { Grid, IconButton, Typography } from "@mui/material";
import theme from "../../../theme";
import HelpIcon from '@mui/icons-material/Help';
import PeriodicTableHelpModal from "./PeriodicTableHelpModal";
import DefectPeriodicTableRow from './DefectPeriodicTableRow'; // Adjust the import path as necessary

export default function DefectPeriodicTableGrid({ tableElements, recipeID, activeDefects, defaultRecipeStateID, handleElementChange, userID }) {
    const [open, setOpen] = useState(false);

    const helpButtonClick = () => {
        setOpen(true);
    }

    const handleModalClose = () => {
        setOpen(false);
    }
    return (
        <div>
            <IconButton onClick={helpButtonClick} sx={{ float: 'right', mr: 2, color: theme.palette.primary.main }}>
                <HelpIcon fontSize={"large"} />
            </IconButton>
            {Array.from({ length: 7 }, (_, i) => i + 1).map(rowNumber => (
                <DefectPeriodicTableRow
                    key={rowNumber}
                    tableElements={tableElements}
                    recipeID={recipeID}
                    rowNumber={rowNumber}
                    activeDefects={activeDefects}
                    handleElementChange={handleElementChange}
                    userID={userID}
                />
            ))}
            <PeriodicTableHelpModal
                open={open}
                handleModalClose={handleModalClose}
            />
        </div>
    );
}
