export const VibrationAnalysisType = {
    AdvancedPanelMount: 1,
    AdvancedVBT: 2,
    BasicVBTCore: 3,
    BasicVBTCoreVFD: 4,
    BasicVBTLowFreq: 5,
    BasicVBTLowFreqVFD: 6,
    IntermediateVBT: 7,
    BasicVBTTemp: 9,
    BasicVBTTempVFD: 10
}