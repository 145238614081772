import React, { useRef, useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import StatusBackdrop from "../Generic/StatusBackdrop";
import { useQuery, useMutation } from "react-query";
import {
    fetchAlarmAssignments,
    deleteAlarmFromComputedDataSourceID,
    deleteAlarmFromAll
} from '../Alarm/AlarmQueryFunctions';
import { Typography, Button, Box, Grid, List, ListItem, ListItemText, Collapse } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function ToastAlarmDelete(props) {
    const [assignedAlarms, setAssignedAlarms] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);

    const alarmID = props.alarmID;
    const userID = props.userID;
    const computedDataSourceID = props.computedDataSourceID;

    const toast = useRef(null);
    const nodeListToast = useRef(null);
    const successToast = useRef(null);

    const { isLoading, error, data } =
        useQuery(["assignments", props.alarmID], fetchAlarmAssignments,
            {
                onSuccess: (data) => {
                    if (data && data.length > 0) {
                        setAssignedAlarms(data);
                    }
                }
            });

    //console.log(alarmID + "   " + userID + "   " + computedDataSourceID);

    useEffect(() => {
        if (assignedAlarms && assignedAlarms.length > 0) {
            showConfirm();
        }
    }, [assignedAlarms]);

    const mutationOne = useMutation(
        ({ computedDataSourceID, alarmID, userID }) =>
            deleteAlarmFromComputedDataSourceID({ computedDataSourceID, alarmID, userID }),
        {
            onSuccess: () => {
                successToast.current.show(
                    {
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Alarm deleted successfully!'
                    });
            },
            onError: (error) => {
                successToast.current.show(
                    {
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error deleting alarm!  ' + JSON.stringify(error)
                    });
            }
        });

    const handleDeleteForThisChannel = (alarmID, userID, computedDataSourceID) => {
        setIsDeleting(true);
        event.preventDefault();
        mutationOne.mutate({ alarmID, userID, computedDataSourceID });
        toast.current.clear();
        setIsDeleting(false);
    };


    const mutationAll = useMutation(
        ({ alarmID, userID }) => deleteAlarmFromAll({ alarmID, userID }), {
        onSuccess: () => {
            successToast.current.show({ severity: 'success', summary: 'Success', detail: 'Alarm deleted successfully!' });
            //console.log('Alarm deleted successfully!');
        },
        onError: (error) => {
            successToast.current.show({ severity: 'error', summary: 'Error', detail: 'Error deleting alarm! ' + JSON.stringify(error) });
            //console.log('Error deleting alarm:', error);
        }
    });

    const extractLastSegment = (str) => {
        const segments = str.split('>');
        return segments[segments.length - 1].trim();
    };

    const handleDeleteForAllChannels = () => {
        toast.current.clear();
        nodeListShow();
    };

    const handleConfirmDelete = () => {
        setIsDeleting(true);
        event.preventDefault();
        mutationAll.mutate({ alarmID, userID }); // Pass the correct arguments here
        nodeListToast.current.clear();
        setIsDeleting(false);
    };


    const nodeListShow = () => {
        nodeListToast.current.show({
            severity: 'warn', sticky: true, content: (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" gutterBottom>
                            <WarningAmberIcon sx={{ fontSize: '3rem' }} /> The alarm will be deleted for the following {assignedAlarms.length > 1 && `${assignedAlarms.length}`} channels:
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'left', maxHeight: '300px', overflow: 'auto', width: '100%' }}>
                        {assignedAlarms.map((alarm, index) => (
                            <Typography
                                key={index}
                                gutterBottom
                                style={{
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {(assignedAlarms.length > 1 ? (index + 1) + ': ' : '') + extractLastSegment(alarm.NodeDisplayName) + ' > ' + alarm.NodeChannelDisplayName}
                            </Typography>
                        ))}
                    </Box>
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Confirm to proceed
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={6}>
                                <Button
                                    id="deleteOne"
                                    name="deleteOne"
                                    variant="contained"
                                    color="info"
                                    onClick={handleConfirmDelete}
                                >
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    id="deleteAll"
                                    name="deleteAll"
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        nodeListToast.current.clear();
                                        toast.current.clear();
                                    }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            ),
            onClose: () => props.onToastClose && props.onToastClose()
        });
    }

    const showConfirm = () => {
        const hasAssignedAlarms = assignedAlarms && assignedAlarms.length > 0;
        const currentAlarmName = hasAssignedAlarms
            ? assignedAlarms[0].AlarmDisplayName
            : ""; // default value if undefined

        const nodeChannels = {};
        if (hasAssignedAlarms) {
            assignedAlarms.forEach(a => {
                const nodeName = a.NodeDisplayName.split('>').pop();
                const channelName = a.NodeChannelDisplayName;
                nodeChannels[nodeName] = nodeChannels[nodeName] || new Set();
                nodeChannels[nodeName].add(channelName);
            });
        }

        const nodes = Object.keys(nodeChannels);
        let channelCount = 0;

        toast.current.show({
            severity: 'warn',
            sticky: true,
            margin: 5,
            padding: 5,
            content: (
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h4" style={{ whiteSpace: 'pre-line', paddingBottom: '10px' }}><WarningAmberIcon sx={{ fontSize: '3rem' }} /> Delete &quot;{currentAlarmName}&quot; Alarm?</Typography>
                        <List sx={{
                            textAlign: 'left', position: 'relative',
                            overflow: 'auto',
                            maxHeight: '300px',
                            width: '100%'
                        }}>
                            {nodes.map((node, index) => {
                                const channels = Array.from(nodeChannels[node]);
                                channelCount += channels.length;
                                return (<>
                                    <ListItem>
                                        <ListItemText sx={{ borderBottom: 'solid 1px black' }} primary={`${node}  (${channels.length} Channels)`} />
                                    </ListItem>
                                    <Collapse in={true}>
                                        <List component="div" disablePadding sx={{ textAlign: 'left' }}>
                                            <ListItem inset sx={{ display: 'block' }}>
                                                {channels
                                                    .map((channel, index) => <ListItemText sx={{ display: 'block', textAlign: 'left' }} primary={(channels.length > 1 ? `${index + 1}: ` : '') + channel} />)}
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </>);
                            })}
                        </List>
                    </Box>
                    <Box sx={{ textAlign: 'left', maxHeight: '300px', overflow: 'auto', width: '100%', paddingTop: '10px' }}>
                        <Button
                            style={{
                                whiteSpace: 'nowrap'
                            }}
                            variant="contained"
                            color="warning"
                            onClick={() => handleDeleteForThisChannel(alarmID, userID, computedDataSourceID)}
                        >
                            Delete From This Channel
                        </Button>
                        <Button
                            style={{
                                whiteSpace: 'nowrap',
                                marginLeft: '10px'
                            }}
                            variant="contained"
                            color="secondary"
                            onClick={handleDeleteForAllChannels}
                        >
                            Delete From All Channels {hasAssignedAlarms ? `(${channelCount})` : ''}
                        </Button>
                    </Box>
                </Box>
            ),
            onClose: () => props.onToastClose && props.onToastClose()
        });
    }

    return (
        <div>
            {(isDeleting || isLoading)
                && <StatusBackdrop open={isDeleting || isLoading} />}
            <Toast ref={toast} position="center" style={{ width: 'auto', maxWidth: '100%' }} />
            <Toast ref={nodeListToast}
                className={(assignedAlarms && assignedAlarms.length > 25) ? 'scrollable-toast-content' : ''}
                position="center"
                style={{ width: 'auto', maxWidth: '100%' }} />
            <Toast ref={successToast} position="center" style={{ width: 'auto', maxWidth: '100%' }} />
        </div>
    );
}