import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import apiClient from "../../http-common";

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value, suggestions) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
        return [];
    }

    const stuff = suggestions
        .map(section => {
            return {
                title: section.title,
                data: section.results.filter(searchitem => searchitem.displayName.toLowerCase().includes(escapedValue.toLowerCase()))
            };
        })
        .filter(section => section.data.length > 0);

    return stuff;
}

function getSuggestionValue(suggestion) {
    return suggestion.displayName;
}

function renderSuggestion(suggestion) {
    return (
        <span style={{ fontSize: "17px", color: 'darkgreen' }}>
            {suggestion.displayName}<br />
            <span style={{ fontSize: "15px", color: 'darkorange' }}>{suggestion.productionReadableSN}</span>
        </span>
    );
}

function renderSectionTitle(section) {
    return (
        <span><strong>{section.title}</strong></span>
    );
}

function getSectionSuggestions(section) {
    return section.data;
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        disableUnderline: true,
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

class InputComponent extends React.Component {
    render() {
        return <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInput {...this.props.inputProps} />
        </Search>;
    }
}

const renderInputComponent = inputProps => {
    return <InputComponent inputProps={inputProps} />;
};

export default class MenuSearch extends React.Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: [],
            filteredSuggestions: []
        };
    }

    componentDidMount() {
        //apiClient
        //    .get("/NodeSearchResults?searchTerm=&CompanyID=132")
        //    .then((response) => {
        //        this.setState({ suggestions: response.data });
        //    })
        //    .catch((error) => {
        //    });

    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue.toString()
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            filteredSuggestions: getSuggestions(value.toString(), this.state.suggestions)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            filteredSuggestions: []
        });
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
        event.preventDefault();
        if (method === 'click' || method === 'enter') {
            this.props.navigation.navigate('/Vibration/AssetDashboard', { assetID: suggestion.objectID });
        }
    }

    render() {
        const { value, filteredSuggestions } = this.state;
        const inputProps = {
            type: "search",
            placeholder: "Search",
            value,
            onChange: this.onChange,

        };

        return (
            <React.Fragment>

                <Autosuggest
                    multiSection={true}
                    suggestions={filteredSuggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    renderSectionTitle={renderSectionTitle}
                    renderInputComponent={renderInputComponent}
                    getSectionSuggestions={getSectionSuggestions}
                    inputProps={inputProps}
                />
            </React.Fragment>
        );
    }
}

