import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Battery90Icon from '@mui/icons-material/Battery90';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';
import ErrorIcon from '@mui/icons-material/Error';


export default function DataVisMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>       
                <Tooltip title="Data Charts and Reports">                    
                    <Button 
                        onClick={handleClick}                        
                        sx={{ ml: 2}}
                        aria-controls={open ? 'datavis-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        color="inherit"
                        
                    >
                        Data 
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon /> }                        
                    </Button>
                </Tooltip>

            <Menu
                anchorEl={anchorEl}
                id="datavis-menu"
                open={open}
                onClose={handleClose}              
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <AssessmentIcon />
                    </ListItemIcon>
                    Time-History Data
                </MenuItem>
                <Divider />
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <Battery90Icon />
                    </ListItemIcon>
                        Battery Report
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <SignalWifiStatusbarConnectedNoInternet4Icon />
                    </ListItemIcon>
                        Connectivity Report
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <ErrorIcon />
                    </ListItemIcon>
                        Error Report
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}


