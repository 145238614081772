import * as Yup from 'yup';
import { VBTxConfigurationFormModel } from './VBTxConfigurationFormModel';

const {
    formField: {
        assetName,
        machineType,
        driveType,
        p1AssignedNode,
        p2AssignedNode,
        p3AssignedNode,
        p4AssignedNode,
        gearboxLocation,
        bladeCount,
        inputToothCount,
        outputToothCount,
        shaftSpeed,
        shaftSpeedHiBound,
        shaftSpeedLoBound,
        vibrationSamplingPeriod,
        temperatureSamplingPeriod,
        triggerSource,
        threshold,
        maxTriggers,
        defectLevel,
        maximizeNodes
    }
} = VBTxConfigurationFormModel;

const nodeSchema = Yup.object().shape({
    nodeID: Yup.number(),
    axisAlignment: Yup.number(),
    shaftSpeedSource: Yup.number(),
    xEnabled: Yup.boolean(),
    yEnabled: Yup.boolean(),
    zEnabled: Yup.boolean(),
    warningThreshold: Yup.number(),
    dangerThreshold: Yup.number(),
    warningRecipientIDs: Yup.array().of(Yup.string()),
    dangerRecipientIDs: Yup.array().of(Yup.string()),
});

const VBTxConfigurationValidationSchema = [
    Yup.object().shape({
        [assetName.name]: Yup.string()
            .required(`${assetName.requiredErrorMsg}`)
            .max(255, `${assetName.invalidErrorMsg}`),
        [machineType.name]: Yup.number().required(`${machineType.requiredErrorMsg}`),
    }),
    Yup.object().shape({
        [driveType.name]: Yup.number().required(`${driveType.requiredErrorMsg}`),
        [p1AssignedNode.name]: nodeSchema,
        [p1AssignedNode.nodeID.name]: Yup.number(),
        [p2AssignedNode.name]: nodeSchema,
        [p3AssignedNode.name]: nodeSchema,
        [p4AssignedNode.name]: nodeSchema,
        [gearboxLocation.name]: Yup.number().required(`${gearboxLocation.requiredErrorMsg}`),
        [bladeCount.name]: Yup.number()
            .required(`${bladeCount.requiredErrorMsg}`)
            .max(255, `${bladeCount.maxErrorMsg}`)
            .min(0, `${bladeCount.minErrorMsg}`),
        [inputToothCount.name]: Yup.number()
            .required(`${inputToothCount.requiredErrorMsg}`)
            .max(255, `${inputToothCount.maxErrorMsg}`)
            .min(0, `${inputToothCount.minErrorMsg}`),
        [outputToothCount.name]: Yup.number()
            .required(`${outputToothCount.requiredErrorMsg}`)
            .max(255, `${outputToothCount.maxErrorMsg}`)
            .min(0, `${outputToothCount.minErrorMsg}`),
        [shaftSpeed.name]: Yup.number()
            .required(`${shaftSpeed.requiredErrorMsg}`)
            .max(5400, `${shaftSpeed.maxErrorMsg}`)
            .min(600, `${shaftSpeed.minErrorMsg}`),
        [shaftSpeedHiBound.name]: Yup.number()
            .required(`${shaftSpeedHiBound.requiredErrorMsg}`)
            .max(5400, `${shaftSpeedHiBound.maxErrorMsg}`)
            .min(600, `${shaftSpeedHiBound.minErrorMsg}`),
        [shaftSpeedLoBound.name]: Yup.number()
            .required(`${shaftSpeedLoBound.requiredErrorMsg}`)
            .max(5400, `${shaftSpeedLoBound.maxErrorMsg}`)
            .min(600, `${shaftSpeedLoBound.minErrorMsg}`),
    }).test('at-least-one-node', 'At least one node must be assigned', function (values) {
        const { p1AssignedNode, p2AssignedNode, p3AssignedNode, p4AssignedNode } = values;
        // Check if any node has a defined and valid nodeID
        const isValid = [p1AssignedNode, p2AssignedNode, p3AssignedNode, p4AssignedNode]
            .some(node => node && node.nodeID !== undefined && node.nodeID !== null && node.nodeID != 0);
        if (!isValid) {
            return this.createError({
                path: `p1AssignedNode.nodeID`, // This is the key where the error will be set
                message: 'At least one node must be assigned',
            });
        }
        return true;
    })
    .test('axis-alignment', 'Assigned node must have axis alignment set', function (values) {
        const { p1AssignedNode, p2AssignedNode, p3AssignedNode, p4AssignedNode } = values;
        // Check if any node has a defined and valid nodeID
        const nodes = [p1AssignedNode, p2AssignedNode, p3AssignedNode, p4AssignedNode];
        const locations = [`p1AssignedNode`, `p2AssignedNode`, `p3AssignedNode`, `p4AssignedNode`]
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i] && nodes[i].nodeID != 0 && nodes[i].axisAlignment == 0) {
                return this.createError({
                    path: locations[i],
                    message: "Assigned node must have axis alignment set",
                });
            }
        }
        return true;
    })
    .test('shaft-speed-bounds', 'Lower bound must not be greater than upper bound', function (values) {
        const { shaftSpeedLoBound, shaftSpeedHiBound } = values;
        if (shaftSpeedLoBound > shaftSpeedHiBound - 240) {
            return this.createError({
                path: `shaftSpeedLoBound`,
                message: "Lower bound must be at least 240 RPM less than upper bound",
            });
        }
        return true;
    })
        .test('output-shaft-speed-input', 'Both tooth counts must be greater than 0', function (values) {
            const { gearboxLocation, inputToothCount, outputToothCount } = values;
            if (gearboxLocation != 0 && (inputToothCount == 0 || outputToothCount == 0)) {
                return this.createError({
                    path: `inputToothCount`,
                    message: "Both tooth counts must be greater than 0",
                });
            }
            return true;
        })
        .test('output-shaft-speed-output', 'Both tooth counts must be greater than 0', function (values) {
            const { gearboxLocation, inputToothCount, outputToothCount } = values;
            if (gearboxLocation != 0 && (inputToothCount == 0 || outputToothCount == 0)) {
                return this.createError({
                    path: `outputToothCount`,
                    message: "Both tooth counts must be greater than 0",
                });
            }
            return true;
        })    ,
    Yup.object().shape({
        [vibrationSamplingPeriod.name]: Yup.number().required(`${vibrationSamplingPeriod.requiredErrorMsg}`),
        [temperatureSamplingPeriod.name]: Yup.number().required(`${temperatureSamplingPeriod.requiredErrorMsg}`),
        [triggerSource.name]: Yup.number().required(`${triggerSource.requiredErrorMsg}`),
        [threshold.name]: Yup.number().required(`${threshold.requiredErrorMsg}`),
        [maxTriggers.name]: Yup.number().required(`${maxTriggers.requiredErrorMsg}`),
    }),
    Yup.object().shape({
        [defectLevel.name]: Yup.number().required(`${defectLevel.requiredErrorMsg}`),
        [maximizeNodes.name]: Yup.boolean().required(`${maximizeNodes.requiredErrorMsg}`),
    })
];


export default VBTxConfigurationValidationSchema;
