import React, { useState } from 'react';
import { Paper, Grid, FormGroup, FormControlLabel, Switch } from '@mui/material';
import FormikSelectField from '../../Generic/FormikSelectField';

export default function VBTxConfigurationStep3(props) {
    const [seperateSampling, setSeperateSampling] = useState(false);

    const handleSeperateSamplingChange = (event) => {
        if (!event.target.checked) {
            props.formik?.setFieldValue('temperatureSamplingPeriod', props.formik?.values.vibrationSamplingPeriod);
        }
        setSeperateSampling(event.target.checked);
    }
    const handleVibrationSamplingChange = (event) => {
        props.formik?.handleBlur({
            target: {
                name: 'vibrationSamplingPeriod',
            },
        });
        if (!seperateSampling) {
            props.formik?.setFieldValue('temperatureSamplingPeriod', props.formik?.values.vibrationSamplingPeriod);
        }
    }

    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >
                <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={seperateSampling} onChange={handleSeperateSamplingChange} />}
                            label="Sample vibration and temperature separately"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'vibrationSamplingPeriod'}
                        label={'Vibration Sampling Period'}
                        data={props.samplingPeriods}
                        onBlur={handleVibrationSamplingChange}
                        errors={props.formik?.errors["vibrationSamplingPeriod"]}
                        width={'100%'}
                        enabled={true}//+(!seperateSampling)}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'temperatureSamplingPeriod'}
                        label={'Temperature Sampling Period'}
                        data={props.samplingPeriods}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["temperatureSamplingPeriod"]}
                        width={'100%'}
                        enabled={seperateSampling}
                    />
                </Grid>
                {/*<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'triggerSource'}
                        label={'Trigger Source'}
                        data={props.triggerSources}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["triggerSource"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>*/}
                {/*(props.formik?.values.triggerSource == 3 || props.formik?.values.triggerSource == 4) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'threshold'}
                        label={'Trigger Threshold'}
                        placeholder={'Trigger Threshold'}
                        form={props.formik}
                        step={1}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["threshold"]}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>*/}
                {/*(props.formik?.values.triggerSource != 1) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'maxTriggers'}
                        label={'Max Triggers per Period'}
                        placeholder={'Max Triggers per Period'}
                        form={props.formik}
                        step={1}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["maxTriggers"]}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>*/}
            </Grid>
        </Paper>
    )
}