import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import { Droppable } from 'react-beautiful-dnd';


export default function DroppableList(props)  {

    return (  
            <Droppable droppableId={props.droppableId} style={{ alignItems: 'center' }} >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                    style={{
                        backgroundColor: snapshot.isDraggingOver ? 'grey' : 'white',
                        minHeight: props.items.length === 0 ? '100%' : 'inherit'
                    }}
                        {...provided.droppableProps}
                    >
                        {props.items.map(({ primary, secondary, icon, nodeID, menuItems }, index) => {
                            return (                                
                                <DraggableListItem
                                    primary={primary}
                                    secondary={secondary}
                                    icon={icon}
                                    draggableId={props.droppableId + index}
                                    index={index}
                                    nodeID={nodeID}
                                    key={index}
                                    menuItems={menuItems}
                                    handleModalOpen={props.handleModalOpen}
                                />                               
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
    );
}

