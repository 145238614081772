import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Chip, Grid, IconButton, Modal, Paper, Typography, Tooltip } from '@mui/material';
import { Toast } from 'primereact/toast';

import { useQuery } from "react-query";
import FormikSelectField from '../Generic/FormikSelectField';
import InputField from '../Generic/InputField';
import { KeysToLowerCase } from "../Generic/MiscFunctions";
import MultiSelectTreeField from '../Generic/MultiSelectTreeField';
import MultilineInputField from '../Generic/MultilineInputField';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import { fetchAlarmNodeChannels } from './AlarmQueryFunctions';
import CloseIcon from '@mui/icons-material/Close';

export default function AlarmStep1Content(props) {
    const toastTargets = useRef(null);
    const [lastThresholdDataTypeID, setLastThresholdDataTypeID] = useState(null);
    const [isLoadedTargets, setIsLoadedTargets] = useState(false);
    const [dataTypeTargets, setDataTypeTargets] = useState(props.alarmTargetsList);//AlarmInfoNodes edit
    const [errorState, setErrorState] = useState();
    const [viewSelectedTargets, setViewSelectedTargets] = useState(false);
    const [matchingLabels, setMatchingLabels] = useState([]);

    useEffect(() => {
        const inputs = document.querySelectorAll('input');
        inputs.forEach(input => {
            input.setAttribute('autocomplete', 'off');
            input.setAttribute('data-lpignore', 'true');
        });
    }, []);

    useEffect(() => {
        if (props.formik?.values['thresholdDataTypeID']) {
            refetch();
            let dataTypeName = props.dataTypes.find(x =>
                x.value === props.formik?.values['thresholdDataTypeID']);

            props.formik?.setFieldValue("thresholdDataTypeName", dataTypeName.label);
        }
    }, [props.formik?.values['thresholdDataTypeID']]);

    const { isLoading: isLoadingTargets, error: targetsError, data: dataT, refetch } = useQuery(
        ["nodeChannels", props.companyID, props.formik?.values['thresholdDataTypeID']],
        fetchAlarmNodeChannels,
        {
            enabled: !!props.formik?.values['thresholdDataTypeID'], // Only enable if thresholdDataTypeID exists
            onSuccess: (dataT) => {
                // After successfully fetching, update lastThresholdDataTypeID
                setLastThresholdDataTypeID(props.formik?.values['thresholdDataTypeID']);

                if (lastThresholdDataTypeID !== props.formik?.values['thresholdDataTypeID'] &&
                    (dataT.length === 1 && dataT[0].Id === "")) {
                    toastTargets.current.show({
                        severity: 'warn',
                        summary: 'Warning',
                        detail: 'There are no targets associated with the selected data type.  Please choose another data type.',
                        life: 5000
                    });

                    const getEmptyOptions = (emptyMessage) => {
                        return [{
                            key: '0',
                            label: emptyMessage
                        }];
                    }

                    setDataTypeTargets(getEmptyOptions('No Available Targets'));

                } else {
                    let tempTargets = KeysToLowerCase(dataT);
                    setDataTypeTargets(tempTargets);

                    props.onDataTypeTargetsChange(tempTargets);
                    setIsLoadedTargets(true);
                }
            },
            onError: (targetsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(targetsError || "An unexpected error occurred.");
            }
        }
    );

    useEffect(() => {
        if (dataTypeTargets !== null) {
            const checkedKeys = props.formik.values['isCheckedTargets'];

            const extractData = (items) => {
                if (checkedKeys !== null && items) {
                    let dataValues = [];
                    items.forEach((item) => {
                        if (checkedKeys[item.key]?.checked &&
                            !isNaN(parseInt(item.data)) &&
                            isFinite(item.data)) {
                            dataValues.push(item.data);
                        }

                        if (item.children && item.children.length > 0) {
                            dataValues.push(...extractData(item.children));
                        }
                    });
                    return dataValues;
                }
            };
            const filteredItems = extractData(dataTypeTargets);

            props.formik.setFieldValue('assignedComputedDataSourceIDs', filteredItems);
        }
    }, [props.formik.values['isCheckedTargets']]);


    // New handler for tracking the selected value
    const handleDropdownChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    // Combined handler that calls both props.formik?.handleChange and handleDropdownChange
    const combinedOnChangeHandler = (event) => {
        // Call the existing handler
        if (props.formik?.handleChange) {
            props.formik.handleChange(event);
        }

        // Call handleDropdownChange with the event object
        handleDropdownChange(event);
    };

    useEffect(() => {
        setMatchingLabels([]); // Clear previous matching labels
        const objArray = dataTypeTargets ? dataTypeTargets : [];
        const dataArray = props.formik?.values['assignedComputedDataSourceIDs'];
        searchForData(objArray, dataArray);
    }, [dataTypeTargets, props.formik?.values['assignedComputedDataSourceIDs']]);

    const searchForData = (obj, dataValuesToFind) => {
        const stringArray = dataValuesToFind?.map(num => num.toString());

        if (Array.isArray(obj)) {
            for (const item of obj) {
                searchForData(item, stringArray);
            }
        } else if (typeof obj === 'object' && obj !== null) {
            const dataValue = obj.data;
            const label = obj.label;

            if (dataValue && label && stringArray.includes(dataValue)) {
                setMatchingLabels(prevLabels => [...prevLabels, obj]);
            }
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    searchForData(obj[key], stringArray);
                }
            }
        }
    };

    const handleSeeSelectedClick = () => {
        setViewSelectedTargets(!viewSelectedTargets);
    };

    const handleAlarmTargetsModalClose = () => {
        setViewSelectedTargets(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 480,
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    };

    return (
        <Box sx={pageStyle}>
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            {(isLoadingTargets && !isLoadedTargets) &&
                <StatusBackdrop open={(isLoadingTargets && !isLoadedTargets)} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Alarm Step 1"
                    statusCode={errorState.request.status}
                    message={errorState.message}
                    error={errorState}
                />
            }
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >
                <Toast
                    ref={toastTargets}
                    position='center'
                />
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">

                    <InputField
                        name={'alarmName'}
                        label={'Name'}
                        placeholder={'Alarm Name'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['alarmName']}
                        width={'100%'}
                    />

                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">

                    <MultilineInputField
                        name={'alarmSummary'}
                        label={'Summary'}
                        placeholder={'Alarm Summary'}
                        onBlur={props.formik?.onBlur}
                        onChange={props.formik?.handleChange}
                        errors={props.formik?.errors["alarmSummary"]}
                        width={'100%'}
                    />

                </Grid>
                <Grid container item xs={12} display="flex" spacing={2} justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={12} md={6} display="flex" justifyContent="flex-start" alignItems="center">

                        <FormikSelectField
                            name={'thresholdDataTypeID'}
                            label={'Threshold Data Type'}
                            data={props.dataTypes}
                            onChange={(e) => combinedOnChangeHandler(e)} // Pass the combined handler
                            onBlur={props.formik?.onBlur}
                            errors={props.formik?.errors["thresholdDataTypeID"]}
                            width={'100%'}
                            enabled={'true'}
                        />

                    </Grid>
                    <Grid item xs={12} md={6} display="flex" justifyContent="flex-end" alignItems="center">

                        <MultiSelectTreeField
                            name={'isCheckedTargets'}
                            label={(<span>Alarm Targets {matchingLabels.length > 1 && <Button onClick={handleSeeSelectedClick}>
                                {'See selected...'}
                            </Button>}</span>)}
                            onChange={props.formik?.handleChange}
                            values={props.formik?.values["isCheckedTargets"]}
                            errors={props.formik?.errors["isCheckedTargets"]}
                            options={dataTypeTargets ? dataTypeTargets : []}
                            labelOverride={'hierarchyPath'}
                            width={'100%'}
                            placeholder={"Select Alarm Targets"}
                            emptyMessage={'No Available Targets'}
                        />

                        {/* Alarm Targets Modal */}
                        <Modal
                            open={viewSelectedTargets}
                            onClose={handleAlarmTargetsModalClose}
                            aria-labelledby="alarm-targets-modal-title"
                            aria-describedby="alarm-targets-modal-description"
                        >
                            <Box sx={modalStyle}>
                                <IconButton onClick={handleAlarmTargetsModalClose} sx={{ float: 'right', top: '5px', right: '5px', position: 'absolute' }}>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="button" display="block" fontWeight="bold" gutterBottom sx={{ textAlign: 'center', borderBottom: '#ccc solid 1px' }}>
                                    Selected Alarm Targets
                                </Typography>
                                <Grid container rowSpacing={1} columnSpacing={1}>
                                    {matchingLabels.map((item, index) => (
                                        <Grid item xs={6} sm={6} md={6} lg={6} display="inline" key={index}>
                                            <Typography paragraph={false} component="div" variant="body2" gutterBottom key={index}>
                                                <Tooltip title={item.hierarchyPath || item.label} placement="top" arrow>
                                                    <Chip color="primary" sx={{ background: 'rgb(48, 168, 255, .4)', fontWeight: 'bold' }} label={item.hierarchyPath || item.label} />
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Modal>

                    </Grid>
                </Grid>


            </Grid>
            </Paper>
        </Box>
    );

}

