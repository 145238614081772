import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


export default function AccountMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>            
            <Tooltip title="Account settings" sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
            <IconButton
                onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                aria-controls={open ? 'primary-account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                color="inherit"
            >
                <AccountCircle sx={{ width: 32, height: 32, display: { xs: 'none', md: 'flex' } }} />
                <ListItemIcon sx={{ display: { xs: 'flex', md: 'none' } }}><AccountCircle  /></ListItemIcon>
                <Typography sx={{ display: { xs: 'flex', md: 'none' } }}>My Account</Typography>
                </IconButton>                
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="primary-account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}                
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    component={Link}
                    to={"/Account/MyAccount"}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    My account
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <SquareFootIcon />
                    </ListItemIcon>
                        User Unit Type Settings
                </MenuItem>
                <Divider />                
            </Menu>
        </React.Fragment>
    );
}


