import React, { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HandymanIcon from '@mui/icons-material/Handyman';
import { Box, Button, Card, CardActions, CardContent, Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useQuery } from "react-query";
import { useParams } from 'react-router-dom';
import { CompanyInfoContext } from '../../App.js';
import {
    fetchDataRange,
    fetchMostRecentDataPoint
} from '../DataPage/DataPageQueryFunctions';
import Carousel from '../Generic/Carousel';
import CustomAlarmChip from '../Generic/CustomAlarmChip';
import { CalculateTimeDifference, GetSeverityStatusColor, GetSeverityStatusColorVIB } from '../Generic/MiscFunctions';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import StatusTrafficLightDisplay from '../Generic/StatusTrafficLightDisplay';
import { fetchImage } from '../images/ImageQueryFunctions';
import LineChart from './AlarmLineChart';
import {
    fetchCompanyEnums, fetchStdAlarmDetailsForView, fetchStdAlarmDetailsFromURL, fetchVIBAlarmDetailsForView, fetchVIBAlarmDetailsFromURL
} from './AlarmQueryFunctions';
import NotificationPanel from './NotificationPanel';
import DefectSummaryButton from "../Vibration/DefectClassification/DefectSummaryButton";
import TimelineIcon from '@mui/icons-material/Timeline';

const getFetchDetails = (params) => {
    // Convert string vib to boolean
    let isVib = params.vib.toLowerCase() === 'true';

    if (params.urlFragment && !isVib) {
        return { fetchFunction: fetchStdAlarmDetailsFromURL, queryKey: ["alarmEventDetails", params.urlFragment] };
    } else if (params.urlFragment && isVib) {
        return { fetchFunction: fetchVIBAlarmDetailsFromURL, queryKey: ["alarmEventDetails", params.urlFragment] };
    } else if (params.cdsid && params.alarmID && !isVib) {
        return { fetchFunction: fetchStdAlarmDetailsForView, queryKey: ["alarmEventDetails", params.cdsid, params.alarmID, params.companyID, params.userID] };
    } else if (params.cdsid && params.alarmID && isVib) {
        return { fetchFunction: fetchVIBAlarmDetailsForView, queryKey: ["alarmEventDetails", params.cdsid, params.alarmID, params.companyID, params.userID] };
    } else {
        // If none of the expected parameters are present, throw an error
        // This will be handled in the component itself
        throw new Error("Required parameters are missing.");
    }
};

export default function AlarmEventDetails(props) {
    // To handle two different routes to get to the `AlarmEventDetails` 
    // page with different parameters, you can use React Router's `useParams` 
    // to extract the necessary parameters from the URL and then conditionally 
    // choose which data fetching function to call based on the presence of specific parameters.
    const { urlFragment, vib, alarmID, cdsid, userID, companyID } = useParams();
    const companyInfo = useContext(CompanyInfoContext);
    const [alarmEventDetails, setAlarmEventDetails] = useState({});
    const [objectDefectSummary, setObjectDefectSummary] = useState({});
    const [alarmNotificationLogs, setAlarmNotificationLogs] = useState([]);
    const [remediationSteps, setRemediationSteps] = useState([]);
    const [remediationImages, setRemediationImages] = useState([]);
    const [plotData, setPlotData] = useState([]);
    const [mostRecent, setMostRecent] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [errorState, setErrorState] = useState();
    const { fetchFunction, queryKey } = getFetchDetails({ urlFragment, vib, alarmID, cdsid, userID, companyID });
    const [urlDate, setUrlDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [queryCompanyID, setQueryCompanyID] = useState(companyID || null);
    const [imagesLoading, setImagesLoading] = useState(true);
    // State to handle relevant enumerations
    const [alarmStatusInfo, setAlarmStatusInfo] = useState([]);
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchImagesForSteps = async (actionSteps) => {
        if (!actionSteps) {
            setImagesLoading(false);
            return;
        }

        try {
            const actionStepsWithImages = await Promise.all(actionSteps.map(async step => {
                if (step.AttachmentFileName && step.AttachmentFileName.match(/^.+[.].+$/) != null) {
                    const imageUrl = await fetchImage(step.AttachmentFileName, process.env.REACT_APP_IMAGE_CONTAINER_NOTIFICATION);
                    return { ...step, imageUrl };
                }
                return step;
            }));

            setRemediationSteps(actionStepsWithImages);
            //console.log(remediationSteps);
            //console.log("Images have been loaded!");
        } catch (error) {
            console.error('Error fetching images:', error);
            // Handle error appropriately
        } finally {
            setImagesLoading(false);
        }
    };



    // Use useQuery hook conditionally if fetch function and query key are set
    const alarmDetailsQuery = useQuery(
        queryKey,
        () => fetchFunction({ queryKey }),
        {
            onSuccess: async (data) => {
                setIsDataLoading(true);
                setAlarmEventDetails(data);
                setUrlDate(data.CreatedOn);
                setQueryCompanyID(data.CompanyID);
                setAlarmNotificationLogs(data.AlarmNotificationLogs);
                fetchImagesForSteps(data.AlarmNotificationActionSteps);

                //console.log(JSON.stringify(data));

                // Process AlarmNotificationActionSteps
                //const actionSteps = data.AlarmNotificationActionSteps;
                //const actionStepsWithImages = await Promise.all(actionSteps.map(async step => {
                //    const imageResponse = await fetchNotificationStepImage(step.AttachmentFileName);
                //    //const blob = await imageResponse.blob();
                //    //return URL.createObjectURL(blob);
                //    return { ...step, imageUrl: imageResponse };
                //    //return { ...step, imageUrl: URL.createObjectURL(blob) };
                //}));

                setIsDataLoading(false);
            },
            onError: (error) => {
                setIsDataLoading(false);
                setErrorState(error || "An unexpected error occurred.");
            },
            enabled: !!fetchFunction && !!queryKey
        }
    );
    // Fetch Enumerations from DB
    const viewAll = true;
    const { isLoading: isLoadingEnums, error: enumsError, data: dataE } =
        useQuery(["enums", queryCompanyID, viewAll], fetchCompanyEnums, {
            enabled: !!fetchFunction && !!queryKey && queryCompanyID > 0,
            onSuccess: (dataE) => {

                setAlarmStatusInfo(dataE.CompanyStdAlarmStatuses);
                setIsLoadedEnums(true);
            },
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });

    const { isLoading, error, data } = alarmDetailsQuery;

    useEffect(() => {
        const formatDateForSQL = (date) => {
            // Formats the date in 'YYYY-MM-DD' format
            return date.toISOString().slice(0, 10);
        };

        const endDate = new Date();
        endDate.setHours(0, 0, 0, 0); // Normalize today's date to midnight for comparison

        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 1);
        // Include today's data + buffer for UTC
        endDate.setDate(endDate.getDate() + 2);

        setFromDate(formatDateForSQL(startDate));
        setToDate(formatDateForSQL(endDate));
    }, [urlDate]);

    const { isLoading: isLoadingPlots, error: dataError, data: dataP } = useQuery(
        ["sensorData", alarmEventDetails.TriggeredComputedDataSourceID,
            fromDate, toDate, queryCompanyID, userID], fetchDataRange,
        {
            onSuccess: (dataP) => {
                setIsDataLoading(true);
                setPlotData(dataP);
                setIsDataLoading(false);
            },
            onError: (dataError) => {
                // Handle the error here. For example, you can log the error or set an error state.
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dataError || "An unexpected error occurred.");
            },
            enabled: !!alarmEventDetails.TriggeredComputedDataSourceID && !!fromDate && !!toDate
        });


    const {
        isLoading: isLoadingMostRecent,
        error: mostRecentDataError,
        data: mostRecentDataP
    } = useQuery(
        ["sensorData", alarmEventDetails.TriggeredComputedDataSourceID, userID], fetchMostRecentDataPoint,
        {
            onSuccess: (mostRecentDataP) => {
                setMostRecent(mostRecentDataP);
            },
            onError: (mostRecentDataError) => {
                // Handle the error here. For example, you can log the error or set an error state.
                setErrorState(mostRecentDataError || "An unexpected error occurred.");
            },
            enabled: !!alarmEventDetails.TriggeredComputedDataSourceID
        });


    const ColoredAlarmStatus = ({ color, children }) => {
        return (
            <Typography
                variant="h5"
                component="div"
                style={{ color: color, fontWeight: 'bold' }}
                sx={{ mb: 2 }}
            >
                {children}
            </Typography>
        );
    };

    const ColoredNormalText = ({ color, children, tooltip }) => {
        return (
            <Tooltip title={tooltip || children} placement={"top"} arrow>
                <Typography
                    component="div"
                    style={{ color: color }}
                    sx={{ mb: 2 }}
                >
                    {children}
                </Typography>
            </Tooltip>
        );
    };

    // Use configured Alarm's severity
    const getAlarmStatus = () => {
        let triggeredLevel = alarmEventDetails.Severity;
        return getStatus(triggeredLevel);
    };

    // Use triggered Data Point's severity
    const getCurrentStatus = () => {
        let triggeredLevel = mostRecent.TriggeredOn != null ? alarmEventDetails.Severity : null;
        return getStatus(triggeredLevel);
    };

    const getStatus = (triggeredLevel) => {
        const secondsSinceCheckIn = CalculateTimeDifference(mostRecent.CurrentComputedOnTimeZone);

        var status = vib.toLowerCase() === 'true'
            ? GetSeverityStatusColorVIB(triggeredLevel, alarmStatusInfo, secondsSinceCheckIn)
            : GetSeverityStatusColor(triggeredLevel, alarmStatusInfo, secondsSinceCheckIn, mostRecent.CurrentComputedValue);
        return status;
    };

    const heights = {
        minHeight: '480px',
        maxHeight: '600px',
        height: '540px',
        overflow: 'auto'
    };
    return (
        <>
            {(isDataLoading || isLoading || isLoadingMostRecent || isLoadingPlots || imagesLoading)
                && <StatusBackdrop open={(isDataLoading || isLoading || isLoadingMostRecent || isLoadingPlots)} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Alarm Event Details"
                    statusCode={errorState?.request?.status}
                    message={errorState.message}
                    error={errorState}
                />
            }
            {(!isLoading && !isDataLoading && !errorState && !isLoadingMostRecent && !isLoadingPlots && !imagesLoading && isLoadedEnums) &&
                <Box>
                    <Grid container spacing={1} columns={4} justifyContent="center">
                        <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                            <Card sx={{ backgroundColor: '#F0F0F0', ...heights }}>
                                <CardContent>
                                    <Typography variant="h4" color="text.secondary" gutterBottom sx={{ borderBottom: '1px solid darkgray' }}>
                                        Alarm Details
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Name
                                    </Typography>
                                    <Typography variant="h6" component="div">
                                        <b>{alarmEventDetails.AlarmDisplayName}</b>
                                    </Typography>

                                    <Typography variant="h5" component="div">
                                        Location
                                    </Typography>
                                    <Typography variant="body2" sx={{ textWrap: 'balance' }} component="div">
                                        <b>{alarmEventDetails.NodeLocation}</b>
                                    </Typography>

                                    <Typography variant="h5" component="div">
                                        Node
                                    </Typography>
                                    <Typography variant="body2" sx={{ textWrap: 'balance' }} component="div">
                                        <b>{alarmEventDetails.NodeDisplayName}</b>
                                    </Typography>

                                    <Typography variant="h5" component="div">
                                        Channel
                                    </Typography>
                                    <Typography variant="body2" sx={{ textWrap: 'balance' }} component="div">
                                        <b>{alarmEventDetails.NodeChannelDisplayName}</b>
                                    </Typography>

                                    <Typography variant="h5" component="div">
                                        Severity
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        <CustomAlarmChip
                                            statusDisplayName={alarmEventDetails.SeverityDisplayName}
                                            statusSeverityLevel={alarmEventDetails.Severity}
                                            color={getAlarmStatus().StatusColor}
                                        />
                                    </Typography>

                                    <Typography variant="h5" component="div">
                                        Threshold
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        <ColoredNormalText>{alarmEventDetails.DataTypeDisplayName}{' '}{alarmEventDetails.OperatorDisplayName}{' '}{Number(alarmEventDetails.ConvertedAlarmThresholdValue?.toFixed(3))}{' '}{alarmEventDetails.ConfigSymbol}{alarmEventDetails.ThresholdUnitTypeDisplaySymbol != alarmEventDetails.ConfigSymbol ? ` (${alarmEventDetails.AlarmThresholdValue?.toFixed(3)} ${alarmEventDetails.ThresholdUnitTypeDisplaySymbol})` : ''}</ColoredNormalText>
                                    </Typography>


                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                            <Card sx={{ width: '100%', backgroundColor: '#F0F0F0', ...heights }}>
                                <CardContent>
                                    <Typography variant="h4" color="text.secondary" gutterBottom sx={{ borderBottom: '1px solid darkgray' }}>
                                        <Tooltip title={`${moment(fromDate).format('M/D/YY')} through ${moment(toDate).format('M/D/YY')}`}><span>Last 30 Days</span></Tooltip>
                                    </Typography>
                                    <div style={{ width: '100%', height: '100%' }}>
                                        <LineChart
                                            plotData={plotData}
                                            title={`${alarmEventDetails.NodeDisplayName} | ${alarmEventDetails.NodeChannelDisplayName}`}
                                            dataGap={60000} //1 minute
                                            showLegend={false}
                                            legend={`${alarmEventDetails.NodeDisplayName} | ${alarmEventDetails.NodeChannelDisplayName}`}
                                        />
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => { window.open((document.referrer ? new URL(document.referrer).origin : null) + '/Data', '_blank'); }} sx={{ display: 'block', width: '100%' }} variant="contained"><TimelineIcon /> &nbsp; View Historical Data</Button>

                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                            <Card sx={{ minWidth: '300px', backgroundColor: '#F0F0F0', ...heights }}>
                                <CardContent>
                                    <Typography variant="h4" color="text.secondary" gutterBottom sx={{ borderBottom: '1px solid darkgray' }}>
                                        Alarm Status &nbsp;
                                        <StatusTrafficLightDisplay
                                            Status={getCurrentStatus()}
                                            alarmStatusInfo={alarmStatusInfo}
                                            keyName={alarmEventDetails.NodeID}
                                        />
                                    </Typography>

                                    <Typography variant="h5" component="div">
                                        Last Check In
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        <b>{Number(mostRecent.CurrentComputedValue?.toFixed(3))}&nbsp;{mostRecent.ConversionSymbol}</b> is in the <b style={{ color: getAlarmStatus().StatusColor }}>{getCurrentStatus().StatusDisplayName}</b> status as of <b>{moment(mostRecent.CurrentComputedOnTimeZone).format('M/D/YY h:mm a')}</b>&nbsp;(about {moment(mostRecent.CurrentComputedOnTimeZone).fromNow()})
                                    </Typography>

                                    <Typography variant="h5" component="div">
                                        Last Trigger
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        <b>{Number(alarmEventDetails.ConvertedTriggeredValue?.toFixed(3))}&nbsp;{alarmEventDetails.ConfigSymbol}</b> is in the <b style={{ color: getAlarmStatus().StatusColor }}>{getAlarmStatus().StatusDisplayName}</b> status as of <b>{moment(alarmEventDetails.TriggeredComputedOnTimeZone).format('M/D/YY h:mm a')}</b>&nbsp;(about {moment(alarmEventDetails.TriggeredComputedOnTimeZone).fromNow()})
                                    </Typography>

                                    <Typography variant="h5" component="div">
                                        Notifications
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        {alarmNotificationLogs.length > 0 && <NotificationPanel notifications={alarmNotificationLogs} />}
                                        {alarmNotificationLogs.length <= 0 && <Typography variant="body2">N/A</Typography>}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={handleOpen} sx={{ display: 'block', width: '100%' }} variant="contained" disabled={remediationSteps.length <= 0}><HandymanIcon /> &nbsp; View Remediation Steps ({remediationSteps.length})</Button>
                                    <DefectSummaryButton
                                        companyID={companyID}
                                        userID={userID}
                                        objectDefectSummary={objectDefectSummary} />
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4
                        }}>
                            <IconButton onClick={handleClose} sx={{ float: 'right', top: '5px', right: '5px', position: 'absolute' }}>
                                <CloseIcon />
                            </IconButton>
                            {remediationSteps.length > 0 && <Carousel AlarmNotificationActionSteps={remediationSteps} />}
                            {mostRecent.TriggeredOn != null && remediationSteps.length <= 0 && <Typography variant="body1" >
                                No remediation steps configured.
                            </Typography>}
                            {mostRecent.TriggeredOn == null && remediationSteps.length <= 0 && <Typography variant="body1" >
                                No active Alarm to display remediation steps.
                            </Typography>}
                        </Box>
                    </Modal>
                </Box>
            }
        </>
    );
};

