import React, { useState } from 'react';
import { Typography, Stack, Modal, Button, IconButton, Paper, Grid, Tooltip, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PhaseChart from '../images/PhaseChart.png'
import PhaseAngle from '../images/PhaseAngle.png'

const helpText = [
    {
        title: "What is Phase Analysis?",
        text: <Typography align="center">Phase Analysis is used to determine how different positions of the asset move in relation to other positions.
            This requires synchronous vibration sampling across all devices on an asset and comparing all vibration signatures (represented as sine waves) to determine
            the <strong>Phase Angle</strong> between each set of signals. A familiar relationship can be drawn from electrical systems. In a 3 Phase (3Ø) Electrical System,
            120° between phases is expected and anything outside of that would be cause for concern.</Typography>
    },
    {
        title: "What do the Phase Symbols Mean?",
        text: <Stack>
            <img
                src={PhaseAngle}
                alt={'Phase Angle'}
                style={{
                    width: '10%',
                    margin: 'auto',
                    marginTop: 10,
                    marginBottom: 5,
                }}
            />
            <Typography align="center" sx={{ mb: 1 }}>A single Phase Angle by itself is random and will change between vibration samples. What matters is how
                different angles relate to each other when an alarm occurs. </Typography>
            <Typography align="center"><strong>There are Two Main Phase Relationships we look at:</strong></Typography>
            <Typography align="center"><strong>Same Position:</strong> In a healthy system, we expect ~90° Between Horizontal and Vertical
                Vibration Signatures at the same node/bearing</Typography>
            <Typography align="center"><strong>Different Position, Same Axis:</strong> In a healthy system, we expect In Phase (~0°) relationships
                between different positions with the same axis </Typography>
        </Stack>
    },
    {
        title: "Why Perform Phase Analysis?",
        text: <Typography align="center">If a vibration alarm occurs, <strong>Phase Relationships</strong> can help us identify which type of defect is
            causing a fault.</Typography>
    },
]

export default function PhaseHelpModal({ open, handleModalClose }) {
    return (
        <Modal
            open={open}
        >
            <Paper className="help-modal">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                        <IconButton sx={{ float: "right" }} onClick={handleModalClose}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Stack alignItems="center">
                    {<img
                        src={PhaseChart}
                        alt={'Phase Chart'}
                        style={{
                            width: '45%'
                        }}
                    />}
                    {helpText.map((block, index) => {
                        return (
                            <Box key={index} sx={{ my: 0.5 }}>
                                <Typography variant="body1" fontWeight="bold" align="center">{block.title}</Typography>
                                {block.text}
                            </Box>
                        )
                    })}
                </Stack>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Button variant="contained" sx={{ mx: '25%', width: '50%' }} onClick={handleModalClose}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}