import React  from 'react';
import { Paper, Modal, Button, Typography, Grid, Box, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
export default function AssignNewCompanyModal(props) {
    const formStyle = {
        my: '3%'
    }
    
    return (
        <Box>
            <Modal
                open={props.assignModalOpen}
            >
                <Paper className="modal-paper">
                    <Grid container direction="row">
                        <Grid item xs={8}>
                            <Typography variant="h5">Assign to New Company</Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <br />
                    <Typography>{`Move ${props.assignObject?.DisplayName} to a different company. Items moved will no longer be visible within this company.`}</Typography>
                    <br />
                    <FormControl
                        sx={formStyle}
                        fullWidth
                    >
                        <InputLabel id="company-select">Select company</InputLabel>
                        <Select
                            labelId="company-select"
                            label="Select company"
                            value={props.selectedCompany}
                            onChange={props.handleSelectedCompanyChange}
                            
                        >
                            {props.companyList.map((company, index) => {
                                return (<MenuItem key={index} value={company.CompanyID}>{company.CompanyName}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    <br /><br />
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <Button variant="contained" style={{ float: "left" }} onClick={props.handleAssignModalClose}>
                                Close
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" style={{ float: "right" }} onClick={props.handleAssignment}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </Box>

    )
}