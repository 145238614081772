import { fetchRequest, RequestType } from "../Generic/MiscFunctions";

export const getRecipientGroups = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    return fetchRequest(`recipientgroup?companyID=${companyID}`, "Failed to fetch recipient groups.", null, RequestType.GET);
};

export const createRecipientGroup = async ({ queryKey }) => {
    const [_key, createRequest] = queryKey;

    if (!createRequest) {
        throw new Error("The 'createRequest' parameter is required.");
    }
    
    return fetchRequest(`recipientgroup/create`, "Failed to create recipient group.", createRequest, RequestType.POST);
};

export const renameRecipientGroup = async ({ queryKey }) => {
    const [_key, renameRequest] = queryKey;

    if (!renameRequest) {
        throw new Error("The 'renameRequest' parameter is required.");
    }

    const recipientGroupID = renameRequest.RecipientGroupID;

    if (!recipientGroupID) {
        throw new Error("The 'recipientGroupID' parameter is required.");
    }

    return fetchRequest(`recipientgroup/${recipientGroupID}/rename`, "Failed to post recipient group rename request.", renameRequest, RequestType.POST);
};

export const deleteRecipientGroup = async ({ queryKey }) => {
    const [_key, deleteRecipientRequest] = queryKey;

    if (!deleteRecipientRequest) {
        throw new Error("The 'deleteRecipientRequest' parameter is required.");
    }

    const recipientGroupID = deleteRecipientRequest.RecipientGroupID;

    if (!recipientGroupID) {
        throw new Error("The 'recipientGroupID' parameter is required.");
    }

    return fetchRequest(`recipientgroup/${recipientGroupID}`, "Failed to delete recipient group.", null, RequestType.DELETE);
};

export const getAvailableRecipients = async ({ queryKey }) => {
    const [_key, recipientGroupID] = queryKey;

    if (!recipientGroupID) {
        throw new Error("The 'recipientGroupID' parameter is required.");
    }

    return fetchRequest(`recipientgroup/${recipientGroupID}/recipients`, "Failed to fetch available recipients for recipient group.", null, RequestType.GET);
};

export const addRecipient = async ({ queryKey }) => {
    const [_key, addRecipientRequest] = queryKey;

    const recipientGroupID = addRecipientRequest.RecipientGroupID;
    const userID = addRecipientRequest.UserID;

    if (!recipientGroupID) {
        throw new Error("The 'recipientGroupID' parameter is required.");
    }

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }

    return fetchRequest(`recipientgroup/${recipientGroupID}/recipient/${userID}`, "Failed to add recipient to recipient group.", addRecipientRequest, RequestType.POST);
};


export const modifyRecipient = async ({ queryKey }) => {
    const [_key, modifyRecipientRequest] = queryKey;

    if (!modifyRecipientRequest) {
        throw new Error("The 'modifyRecipientRequest' parameter is required.");
    }

    const recipientGroupID = modifyRecipientRequest.RecipientGroupID;
    const userID = modifyRecipientRequest.UserID;

    if (!recipientGroupID) {
        throw new Error("The 'recipientGroupID' parameter is required.");
    }

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }

    return fetchRequest(`recipientgroup/${recipientGroupID}/recipient/${userID}`, "Failed to modify recipient.", modifyRecipientRequest, RequestType.PATCH);
};