import { fetchRequest } from "../Generic/MiscFunctions";


export const fetchDataRange = async ({ queryKey }) => {
    const [_key, dataSourceID, fromDate, toDate, companyID, userID] = queryKey;

    if (!dataSourceID || !fromDate || !toDate) {
        throw new Error('FetchDataRange: Data fetching failed due to missing parameters');
    }

    let requestBody = {
        SourceID: dataSourceID,
        CompanyID: companyID,   
        UserID: userID,
        FromDate: fromDate,
        ToDate: toDate
    };

    // Perform the post request with the body parameters
    return fetchRequest(`/SensorData/sensor-data/plot-data`, 
        `Failed to fetch Data Range: ${dataSourceID}, ${fromDate}, ${toDate}`, requestBody);
  
};

export const fetchMostRecentDataPoint = async ({ queryKey }) => {
    const [_key, computedDataSourceID, userID] = queryKey;

    if (!computedDataSourceID) {
        throw new Error("The 'computedDataSourceID' parameter is " +
            "required and cannot be null or empty.");
    }

    if (userID != null) {
        return fetchRequest(`/SensorData/sensor-data/most-recent-datapoint/${computedDataSourceID}?userID=${userID}`,
            `Failed to fetch most recent data point for ${computedDataSourceID}`);
    } else {
        return fetchRequest(`/SensorData/sensor-data/most-recent-datapoint/${computedDataSourceID}`,
            `Failed to fetch most recent data point for ${computedDataSourceID}`);
    }
};
