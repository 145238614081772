import React, { useContext } from 'react';
import { CompanyInfoContext } from '../../App.js';
import { Typography } from '@mui/material';

export default function CustomHeader(props) {
    const companyInfo = useContext(CompanyInfoContext);

    let headerContent = props.headerText;

    if (companyInfo) {
        headerContent += ` - ${companyInfo.companyInfo.CompanyName}`;
    }

    return (
        <Typography variant="h4" component="h2" className="header">
            {headerContent}
        </Typography>
    );
}

//export default function CustomHeader(props) {
//    const { sessionData, updateSessionData } = useContext(SessionContext);

//    let headerContent = props.headerText;

//    if (sessionData) {
//        headerContent += ` - ${sessionData.currentCompanyName}`;
//    }

//    return (
//        <Typography variant="h4" component="h2" className="header">
//            {headerContent}
//        </Typography>
//    );
//}
