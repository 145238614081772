import React, { useState, useEffect, useRef, createContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './custom.css';
import theme from "./theme";

import AccountManagement from './components/Account/AccountManagement';
import AlarmDashboard from './components/Alarm/AlarmDashboard';
import AlarmEventDetails from './components/Alarm/AlarmEventDetails';
import AlarmStepperAddNew from './components/Alarm/AlarmStepperAddNew';
import AlarmStepperEdit from './components/Alarm/AlarmStepperEdit';
import { useAuth } from './components/Auth/AuthContext';
import Login from './components/Auth/Login';
import CompanyManagement from './components/Company/CompanyManagement';
import AssetIntegration from './components/Integration/AssetIntegration';
import FormComponent from './components/Integration/FormComponent';
import AppNavBar from './components/MainMenuBar/AppNavBar';
import APIManagement from './components/PublicAPI/APIManagement';
import AddAsset from './components/Vibration/AddAsset';
import AssetDashboard from './components/Vibration/AssetDashboard';
import AssetDetail from './components/Vibration/AssetDetail';
import DefectDashboard from './components/Vibration/DefectClassification/DefectDashboard';
import EditAsset from './components/Vibration/EditAsset';
import DefectDetail from './components/Vibration/DefectClassification/DefectDetail';
import ControlGateConfiguration from './components/Control/ControlGateConfiguration';
import NodeSettings from './components/NodeSettings/NodeSettings';

export const CompanyInfoContext = React.createContext();
import UnitTypeSettings from './components/Account/UnitTypeSetting';
import { SessionProvider } from './SessionContext';
import RecipientGroupPage from './components/RecipientGroup/RecipientGroupPage';

export default function App() {
    const [companyInfo, setCompanyInfo] = useState({});
    const authInfo = useAuth();
    const location = useLocation();
    const appRef = useRef(null);
    let scrollHeight = 0;

    useEffect(() => {
        const id = setInterval(() => {
            if (appRef.current) {
                const currentScrollHeight = appRef.current.scrollHeight;
                if (scrollHeight !== currentScrollHeight) {
                    scrollHeight = currentScrollHeight;
                    const payload = { url: window.location.href, height: currentScrollHeight };
                    window.parent.postMessage(payload, '*');
                }
            }
        }, 200);

        return () => clearInterval(id); // Cleanup on component unmount
    }, []); // Empty dependency array ensures this effect runs only once

    const PrivateRoute = ({ children }) => {
        return authInfo.isAuthenticated() ? children : <Navigate to={`/login?returnUrl=${encodeURIComponent(location.pathname)}`} />;
    };

    return (
        <>
            <SessionProvider>
            <CompanyInfoContext.Provider value={{ companyInfo, setCompanyInfo }}>
                <StyledEngineProvider injectFirst>
                    <MuiThemeProvider theme={theme}>
                    <PrimeReactProvider>
                        <ThemeProvider theme={theme}>
                            <div ref={appRef}>
                                    {/*Don't show AppNavBar for MH 2.5*/}
                                    {process.env.REACT_APP_ENABLE_AUTH && <AppNavBar />}
                                    {/*<AppNavBar />*/}
                                    {/*<BreadCrumbs />*/}
                                    <Routes>
                                        <Route path="/login" element={<Login />} />
                                        <Route exact path='/' element={<AssetDashboard />} breadcrumb='Home' />
                                        <Route path='/Account/AccountManagement/:companyID/:userID' element={<AccountManagement />} />
                                        <Route path='/Vibration/AssetDashboard/:companyID/:userID/:viewAll' element={<AssetDashboard />} />
                                        <Route path='/Vibration/AddAsset/:companyID/:userID/:viewAll' element={<AddAsset />} />
                                        <Route path='/Vibration/AddAsset/:companyID/:userID/:objectID/:viewAll' element={<AddAsset />} />
                                        <Route path='/Vibration/EditAsset/:companyID/:userID/:objectID/:viewAll' element={<EditAsset />} />
                                        <Route path='/Vibration/AssetDetail/:companyID/:userID/:objectID/:viewAll' element={<AssetDetail />} />
                                        <Route path='/Alarm/AlarmDashboard/:companyID/:userID/:viewAll' element={<AlarmDashboard />} />
                                        <Route path='/Alarm/Add/:companyID/:userID/:viewAll' element={<AlarmStepperAddNew />} />
                                        <Route path='/Alarm/Edit/:companyID/:userID/:alarmID/:cdsid/:viewAll/:crudType' element={<AlarmStepperEdit />} />
                                        <Route path='/Alarm/Clone/:companyID/:userID/:alarmID/:cdsid/:viewAll/:crudType' element={<AlarmStepperEdit />} />
                                        <Route path='/Alarm/EventDetails/:userID/:urlFragment/:vib' element={<AlarmEventDetails />} />
                                        <Route path='/Alarm/EventDetails/view/:companyID/:userID/:cdsid/:alarmID/:vib' element={<AlarmEventDetails />} />
                                        <Route path='/Defect/DefectDashboard/:userID/:companyID' element={<DefectDashboard />} />
                                        <Route path='/Vendor/Form/:companyID/:userID/:vendorID' element={<FormComponent />} />
                                        <Route path='/AssetIntegration/:companyID/:userID/:vendorID/:viewAll?' element={<AssetIntegration />} />
                                        <Route path='/Defect/Details/:companyID/:userID/:vibrationObjectID/:defaultRecipeStateID' element={<DefectDetail />} />
                                        <Route path='/Defect/Details/:companyID/:userID/:vibrationObjectID' element={<DefectDetail />} />
                                        <Route path='/Defect/Details/:companyID/:userID' element={<DefectDetail />} />
                                        <Route path='/PublicAPI/:companyID/:userID' element={<APIManagement />} />
                                        <Route path='/Company/:companyID' element={<PrivateRoute><CompanyManagement /></PrivateRoute>} />
                                        <Route path='/Control/:companyID' element={<PrivateRoute><ControlGateConfiguration /></PrivateRoute>} />
                                        <Route path='/UnitTypeSettings/:companyID/:userID' element={<UnitTypeSettings />} />
                                        <Route path='/RecipientGroups/:companyID' element={<PrivateRoute><RecipientGroupPage /></PrivateRoute>} />
                                        <Route path='/NodeSettings/:companyID' element={<PrivateRoute><NodeSettings /></PrivateRoute>} />
                                        {/*<Route path={ApplicationPaths.Login} element={<Login action={LoginActions.Login}></Login>} />*/}
                                        {/*<Route path={ApplicationPaths.LoginFailed} element={<Login action={LoginActions.LoginFailed}></Login>} />*/}
                                        {/*<Route path={ApplicationPaths.LoginCallback} element={<Login action={LoginActions.LoginCallback}></Login>} />*/}
                                        {/*<Route path={ApplicationPaths.Profile} element={<Login action={LoginActions.Profile}></Login>} />*/}
                                        {/*<Route path={ApplicationPaths.Register} element={<Login action={LoginActions.Register}></Login>} />*/}
                                        {/*<Route path={ApplicationPaths.LogOut} element={<Logout action={LogoutActions.Logout}></Logout>} />*/}
                                        {/*<Route path={ApplicationPaths.LogOutCallback} element={<Logout action={LogoutActions.LogoutCallback}></Logout>} />*/}
                                        {/*<Route path={ApplicationPaths.LoggedOut} element={<Logout action={LogoutActions.LoggedOut}></Logout>} />*/}
                                    </Routes>
                                </div>
                                </ThemeProvider>
                            </PrimeReactProvider>
                        </MuiThemeProvider>                        
                    </StyledEngineProvider>
                </CompanyInfoContext.Provider>
            </SessionProvider>
        </>
    );
}


