import React from 'react';
import GraceTrafficLight from '../Generic/GraceTrafficLight';

export default function StatusTrafficLightDisplay(props) {
    // for standard alarms 0 is the 'off' status - check the database
    // for vibration 1 is Off and 0 is normal

    let disabledColor = props.alarmStatusInfo.find(x => x.StatusDisplayName == "Off").StatusColor;
    let greenColor =props.alarmStatusInfo.find(x => x.StatusDisplayName == "Normal").StatusColor;
    let yellowColor = props.alarmStatusInfo.find(x => x.StatusDisplayName == "Warning").StatusColor;
    let redColor = props.alarmStatusInfo.find(x => x.StatusDisplayName == "Danger").StatusColor;

    return (
        <GraceTrafficLight
            GreenOn={props.Status.StatusDisplayName === "Normal" ? true : false}
            YellowOn={props.Status.StatusDisplayName === "Warning" ? true : false}
            RedOn={props.Status.StatusDisplayName === "Danger" ? true : false}
            RedColor={props.Status.StatusDisplayName === "Danger" ? props.Status.StatusColor : redColor}
            YellowColor={props.Status.StatusDisplayName === "Warning" ? props.Status.StatusColor : yellowColor}
            GreenColor={props.Status.StatusDisplayName === "Normal" ? props.Status.StatusColor : greenColor}
            DisabledColor={props.Status.StatusDisplayName === "Off" ? props.Status.StatusColor : disabledColor}
            Horizontal
            key={props.keyName}
        />
    );
      
    
}