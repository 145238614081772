import React, { useState } from 'react';
import { Paper, Modal, Button, Typography, Grid, TableContainer, Table, TableBody, Box, TextField, Divider, IconButton } from '@mui/material';
import FolderRow from './FolderRow';
import ClearIcon from '@mui/icons-material/Clear';

export default function FolderModal(props) {
    const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");

    const handleNewFolderButtonClick = () => {
        setCreateFolderModalOpen(true);
    }

    const handleCreateFolderModalClose = () => {
        setCreateFolderModalOpen(false);
    }

    const handleNewFolderNameChange = (event) => {
        setNewFolderName(event.target.value);
    }

    const handleCreateFolderClick = () => {
        setCreateFolderModalOpen(false);
        props.handleCreateFolderClick(newFolderName);
    }

    return (
        <Box>
            <Modal
                open={props.folderModalOpen}
            >
                <Paper className="modal-paper">
                    <Grid container direction="row" spacing={2} >
                        <Grid item xs={6}>
                            <Typography variant="h5" sx={{ my: .5 }}>Manage Folders</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" style={{ float: "right" }} onClick={handleNewFolderButtonClick} sx={{ my: .5 }}>
                                Create Folder
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton sx={{ float: "right" }} onClick={props.handleFolderModalClose}>
                                <ClearIcon fontSize={"large"} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider />
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {props.folders?.map((folder) => {
                                    return (
                                        folder.ParentObjectID != -1 && <FolderRow
                                            key={folder.ObjectID}
                                            folder={folder}
                                            handleFolderNameChange={props.handleFolderNameChange}
                                            handleFolderNameSave={props.handleFolderNameSave}
                                            handleDeleteFolderClick={props.handleDeleteFolderClick}
                                            viewAll={props.viewAll}
                                            handleAssignModalOpen={props.handleAssignModalOpen}
                                        />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <Button variant="contained" style={{ float: "left" }} onClick={props.handleFolderModalClose}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
            <Modal open={createFolderModalOpen}>
                <Paper className="modal-paper">
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="h5">Create New Folder</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton sx={{ float: "right" }} onClick={handleCreateFolderModalClose}>
                                <ClearIcon fontSize={"large"} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider />
                    <br />
                    <TextField
                        fullWidth
                        label={"New folder name"}
                        variant="outlined"
                        value={newFolderName}
                        onChange={handleNewFolderNameChange}
                    />
                    <br />
                    <br />
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <Button variant="contained" style={{ float: "left" }} onClick={handleCreateFolderModalClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" style={{ float: "right" }} onClick={handleCreateFolderClick}>
                                Create Folder
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </Box>
        
    )
}