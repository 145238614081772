import React, { useState } from 'react';
import { Card, CardHeader, Collapse, IconButton, CardContent, Grid, Typography, TableRow } from "@mui/material";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import NodeList from './NodeList';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { useClasses } from '../../customHooks';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const styles = {
    overrides: {
        MUIDataTableSelectCell: {
            expandDisabled: {
                // Soft hide the button.
                visibility: 'hidden',
            },
        },
        MuiChip: {
            root: {
                backgroundColor: "gray"
            }
        },
        MUIDataTableFilter: {
            filterPaper: {
                width: "250px",
            },
        },
        MuiPopover: {
            paper: {
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 10,
                minWidth: '90%'
            }
        }
    },
}

export default function FolderCard(props) {
    const classes = useClasses(styles);

    //Members used bu the MUI Datatable component
    const [expanded, setExpanded] = useState(true);
    const [rowsExpanded, setRowsExpanded] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [tableRowsPerPage, setTableRowsPerPage] = useState(15);       // PageSize parameter for search
    const [currentPage, setCurrentPage] = useState(0);   // PageNumber parameter for search

    //Open/close the folder view
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };


    const columns = [
        {
            name: "DisplayName",
            label: " ",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            {props.list[dataIndex].DisplayName}
                        </span>
                    );
                },
                setCellProps: () => ({
                    style: { width: '50%', maxWidth: '50%', borderTop: "1px solid rgba(224, 224, 224, 1)" } // Attempt to make the cell as wide as possible
                }),
                searchable: true,
                filter: false,
                sort: true,
                customHeadRender: () => null,
            },
        },
        {
            //Show the name of the folder the node is in so the user can filter by folder
            name: "FolderName",
            label: "Folder Name",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    if (props.list[dataIndex].FolderName != "") {
                        return (
                            <span>
                                <FolderIcon sx={{ mr: "10px" }} color="primary" />
                                {props.list[dataIndex].FolderName}
                            </span>
                        );
                    }
                },
                setCellProps: () => ({
                    style: { width: '100%', maxWidth: '100%', borderTop: "1px solid rgba(224, 224, 224, 1)" } // Attempt to make the cell as wide as possible
                }),
                searchable: true,
                filter: true,
                filterType: 'multiselect',
                filterOptions: {
                    fullWidth: true,
                },
                sort: true,
                customHeadRender: () => null,
            },
        },
        {
            //Render a component determined by the parent. This can be a button, etc.
            name: "ParentAction",
            label: " ",
            options: {
                customBodyRenderLite: (dataIndex) => { return (props.renderParentAction(props.list[dataIndex])) },
                setCellProps: () => ({
                    style: { width: '100%', maxWidth: '100%', borderTop: "1px solid rgba(224, 224, 224, 1)" } // Attempt to make the cell as wide as possible
                }),
                searchable: true,
                filter: false,
                sort: true,
                customHeadRender: () => null,
            },
        }
    ]

    const options = {
        elevation: 5,
        pagination: true,
        filter: props.showFilter,
        search: true,
        serverSide: false,
        viewColumns: false,
        download: false,
        print: false,
        selectableRows: 'none',
        responsive: 'standard',
        enableNestedDataAccess: ".",
        page: currentPage,
        onChangePage: (pageNum) => {
            setCurrentPage(pageNum);
        },
        rowsPerPage: tableRowsPerPage,
        onChangeRowsPerPage: (numberOfRows) => {
            setTableRowsPerPage(numberOfRows)
        },
        selectableRowsHideCheckboxes: true,
        searchAlwaysOpen: true,
        searchText: searchText,
        searchProps: {
            onBlur: (e) => {
                setSearchText(e.target.value);
            },
            onKeyUp: (e) => {
                setSearchText(e.target.value);
            }
        },
        searchPlaceholder: 'Search',
        rowsExpanded: rowsExpanded,
        expandableRows: props.showChildren,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
            let myRowsExpanded = allRowsExpanded.map(item => {
                return item.dataIndex;
            });
            setRowsExpanded(myRowsExpanded)
        },
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <TableRow>
                    <NodeList
                        list={props.list[rowMeta.dataIndex].Children}
                        renderChildAction={props.renderChildAction}
                    />
                </TableRow>
            );
        },
        onFilterChange: props.onFilterChange
    };
    return (
        <MUIDataTable
            title={""}
            data={props.list}
            columns={columns}
            options={options}
            className={classes.overrides}
            components={components}
        />
    )
}