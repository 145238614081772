import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ClearIcon from '@mui/icons-material/Clear';
import CreateIcon from '@mui/icons-material/Create';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { Button, IconButton, Paper, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { createToastError, createToastSuccess } from "../../Generic/MiscFunctions";
import StatusBackdrop from "../../Generic/StatusBackdrop";
import StatusMessage from "../../Generic/StatusMessage";
import { ColorlibConnector, ColorlibStepIconRoot, styles } from '../../Generic/StepperComponents';
import { VBTxConfigurationFormModel } from "../Models/VBTxConfigurationFormModel";
import VBTxConfigurationValidationSchema from "../Models/VBTxConfigurationValidationSchema";
import {
    fetchCompanyNodesVBTx, fetchVibrationVBTxEnums, postAssetVBTx, updateAssetVBTx
} from "../VibrationQueryFunctions";
import {
    createPostObject,
    createPostObjectConfig, getInitialValues,
    getInitialValuesFromConfig, mapEnumToFormik
} from './VBTxConfigurationHelpers';
import VBTxConfigurationStep1 from './VBTxConfigurationStep1';
import VBTxConfigurationStep2 from './VBTxConfigurationStep2';
import VBTxConfigurationStep3 from './VBTxConfigurationStep3';
import VBTxConfigurationStep4 from './VBTxConfigurationStep4';

const assetSteps = [
    {
        name: 'step0',
        label: 'Asset Information',
        description1: `Name the asset and provide some basic information`,
        description2: ``,
        validationSchema: VBTxConfigurationValidationSchema[0]
    },
    {
        name: 'step1',
        label: 'Assign and Configure Nodes',
        description1: `Assign VBTx nodes to the asset and set their individual configuraions.`,
        description2: ``,
        validationSchema: VBTxConfigurationValidationSchema[1]
    },
    {
        name: 'step2',
        label: 'Set Sampling Periods',
        description1: `Set vibration and temperature sampling period and set triggers`,
        description2: ``,
        validationSchema: VBTxConfigurationValidationSchema[2]
    },
    {
        name: 'step3',
        label: 'Set Defect Identification Level',
        description1: `Set the desired level of defect classification`,
        description2: ``,
        validationSchema: VBTxConfigurationValidationSchema[3]
    },
]

const configSteps = [
    {
        name: 'step0',
        label: 'Configure Node Vibration Settings',
        description1: `Set shaft speeds and enabled axes`,
        description2: ``,
        validationSchema: VBTxConfigurationValidationSchema[1]
    },
    {
        name: 'step2',
        label: 'Set Sampling Periods',
        description1: `Set vibration and temperature sampling period and set triggers`,
        description2: ``,
        validationSchema: VBTxConfigurationValidationSchema[2]
    },
    {
        name: 'step3',
        label: 'Set Defect Identification Level',
        description1: `Set the desired level of defect classification`,
        description2: ``,
        validationSchema: VBTxConfigurationValidationSchema[3]
    },
]

const { formId } = VBTxConfigurationFormModel;

function ColorlibStepIconAsset(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <CreateIcon />,
        2: <AssignmentIcon />,
        3: <AccessTimeIcon />,
        4: <TroubleshootIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

function ColorlibStepIconConfig(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <AssignmentIcon />,
        2: <AccessTimeIcon />,
        3: <TroubleshootIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const TOAST_SUCCESS_LIFE = 3000;
const TOAST_ERROR_LIFE = 30000;

export default function VBTxConfigurationStepper(props) {
    const { companyID, userID, objectID, viewAll } = useParams();
    const navigate = useNavigate();
    const toast = useRef(null);
    const steps = props.viewAsset ? assetSteps : configSteps;

    const [activeStep, setActiveStep] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const [finishing, setFinishing] = useState(false);
    const [errorState, setErrorState] = useState();

    const [unassignedNodes, setUnassignedNodes] = useState([]);
    const [enums, setEnums] = useState([]);

    const [isLoadedVBTxNodes, setIsLoadedVBTxNodes] = useState(!props.viewAsset);
    const [isLoadedVBTxEnums, setIsLoadedVBTxEnums] = useState(false);

    const { isLoading: enumsVBTxLoading, error: enumsVBTxError, data: dataB, refetch: refetchVBTxEnums } =
        useQuery(["vibrationVBTxEnums"], fetchVibrationVBTxEnums, {
            fetchPolicy: "network-only",
            onSuccess: (dataB) => {
                let enums = {
                    machineTypes: dataB.MachineTypes.map(mapEnumToFormik),
                    driveTypes: dataB.DriveTypes.map(mapEnumToFormik),
                    samplingPeriods: dataB.SamplingPeriods.map(mapEnumToFormik),
                    triggerSources: dataB.TriggerSources.map(mapEnumToFormik),
                    shaftSpeedSources: dataB.ShaftSpeedSources.map(mapEnumToFormik).filter((item) => item.value != 4), //Remove Gateway based source for now
                    OrientationTypes: dataB.OrientationTypes
                };
                setEnums(enums)
                setIsLoadedVBTxEnums(true);
            }
        });

    const { isLoading: VBTxNodesLoading, error: VBTxNodesError, data: dataV } =
        useQuery({
            queryKey: ["vbtxnodes", companyID],
            queryFn: fetchCompanyNodesVBTx,
            onSuccess: (dataV) => {
                const tempUnassignedNodes = []
                tempUnassignedNodes.push({ "label": "None", "value": 0 })
                dataV.CompanyNodes.map(({ NodeID, DisplayName }) => {
                    if (!dataV.AssignedCompanyNodes.some(n => n.NodeID == NodeID)) {
                        tempUnassignedNodes.push({ "label": DisplayName, "value": NodeID })
                    }
                })
                if (props.editing) {
                    props.vbtxAssetNodes?.map((node) => {
                        tempUnassignedNodes.push({ "label": node.DisplayName, "value": node.NodeID })
                    })
                }
                setUnassignedNodes(tempUnassignedNodes);
                setIsLoadedVBTxNodes(true);
            },
            fetchPolicy: "network-only",
            enabled: props.viewAsset
        });

    const doUpdateAssetVBTx =
        useMutation((asset) => updateAssetVBTx(asset), {
            onSuccess: (_) => {
                toast.current.show(createToastSuccess('Asset Successfully Updated', TOAST_SUCCESS_LIFE));
                setTimeout(() => {
                    navigate(`/Vibration/AssetDashboard/${companyID}/${userID}/${viewAll}`);
                }, TOAST_SUCCESS_LIFE); // Match the life of the toast 
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to edit the asset.', TOAST_ERROR_LIFE));
                // Wait for the toast to likely close before navigating
                setTimeout(() => {
                    navigate(`/Vibration/AssetDashboard/${companyID}/${userID}/${viewAll}`);
                }, TOAST_ERROR_LIFE); // Match the life of the toast
            }
        });

    const doPostAssetVBTx =
        useMutation((asset) => postAssetVBTx(asset), {
            onSuccess: (_) => {
                toast.current.show(createToastSuccess('New Asset Created', TOAST_SUCCESS_LIFE));
                setTimeout(() => {
                    navigate(`/Vibration/AssetDashboard/${companyID}/${userID}/${viewAll}`);
                }, TOAST_SUCCESS_LIFE); // Match the life of the toast 
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to create the asset.', TOAST_ERROR_LIFE));
                // Wait for the toast to likely close before navigating
                setTimeout(() => {
                    navigate(`/Vibration/AssetDashboard/${companyID}/${userID}/${viewAll}`);
                }, TOAST_ERROR_LIFE); // Match the life of the toast
            }
        });

    const isLastStep = activeStep === steps.length - 1;
    async function handleFinalSubmit(formik) {
        if (props.viewAsset) {
            const postAssetObj = createPostObject(formik.values, props.editing, userID, companyID, objectID);
            if (props.editing) {
                doUpdateAssetVBTx.mutateAsync(postAssetObj);
            } else {
                doPostAssetVBTx.mutateAsync(postAssetObj);
            }
        } else {
            const postConfigsObject = createPostObjectConfig(formik.values, props.vbtxAsset);
            props.handleVbtxConfigSubmit(postConfigsObject);
        }

    }

    function createHandleSubmit(formik) {
        return async function handleSubmit() {
            const isLastStep = activeStep === steps.length - 1;

            const currentStepFields = VBTxConfigurationValidationSchema.reduce((acc, schema) => {
                const fields = Object.keys(schema.describe().fields);
                return acc.concat(fields);
            }, []);

            const errors = await formik.validateForm();
            const stepErrors = currentStepFields.some((field) => errors[field]);
            if (!stepErrors) {
                if (!isLastStep) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    // Final step submission is handled by handleFinalSubmit, nothing needs to be done here
                }
            } else {
                // Set touched state for current step fields
                currentStepFields.forEach((field) => {
                    formik.setFieldTouched(field, true);
                });
            }
        };
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    return (
        <Paper style={props.viewAsset ? styles.paper : styles.modalpaper} elevation={6}>
            {(VBTxNodesLoading || enumsVBTxLoading) &&
                <StatusBackdrop open={(VBTxNodesLoading || enumsVBTxLoading)} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Edit Asset"
                    statusCode={errorState?.request.status}
                    message={errorState?.message}
                    error={errorState}
                />
            }
            <Toast ref={toast} />
            {!props.viewAsset && <IconButton sx={{ float: "right" }} onClick={props.handleCloseVbtxConfigModal}>
                <ClearIcon fontSize={"large"} />
            </IconButton>}
            <div style={{ height: '10px' }} /> {/* Add space above Stepper */}
            <Stepper alternativeLabel activeStep={activeStep} style={styles.stepper} connector={<ColorlibConnector />}>
                {steps.map(({ label }) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={props.viewAsset ? ColorlibStepIconAsset : ColorlibStepIconConfig}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {isLoadedVBTxEnums && isLoadedVBTxNodes &&
                <Formik
                    initialValues={props.viewAsset ? getInitialValues(props.vbtxAsset, props.editing) : getInitialValuesFromConfig(props.vbtxAsset)}
                    validationSchema={steps[activeStep].validationSchema}
                    onSubmit={handleFinalSubmit}
                >
                    {(formik) => (
                        <Form id={formId}>
                            <div>
                                <div>
                                    <Typography variant="h5" align="center">{steps[activeStep].label}</Typography>
                                    <Typography variant="body1" align="center">
                                        {steps[activeStep].description1}
                                    </Typography>
                                    <Typography variant="body1" align="center" style={{ marginTop: '0.5em', marginBottom: '2em' }}>
                                        {steps[activeStep].description2}
                                    </Typography>
                                    {(activeStep === 0 && props.viewAsset) ? (
                                        <VBTxConfigurationStep1
                                            formik={formik}
                                            machineTypes={enums.machineTypes}
                                        />
                                    ) : null}
                                    {((activeStep === 1 && props.viewAsset) || (activeStep === 0 && !props.viewAsset)) ? (
                                        isLoadedVBTxNodes && <VBTxConfigurationStep2
                                            viewAsset={props.viewAsset}
                                            formik={formik}
                                            unassignedNodes={unassignedNodes}
                                            enums={enums}
                                            users={props.users}
                                            userGroups={props.userGroups}
                                        />
                                    ) : null}
                                    {((activeStep === 2 && props.viewAsset) || (activeStep === 1 && !props.viewAsset)) ? (
                                        <VBTxConfigurationStep3
                                            formik={formik}
                                            samplingPeriods={enums.samplingPeriods}
                                            triggerSources={enums.triggerSources}
                                        />
                                    ) : null}
                                    {((activeStep === 3 && props.viewAsset) || (activeStep === 2 && !props.viewAsset)) ? (
                                        <VBTxConfigurationStep4
                                            formik={formik}
                                        />
                                    ) : null}
                                    <Stack
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="baseline"
                                        spacing={4}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={styles.button}
                                            onClick={() => {
                                                // Call the handleReset function on the onClick event
                                                formik.resetForm();
                                                setActiveStep(0);
                                            }}
                                            disabled={disableButton}
                                        >
                                            Reset
                                        </Button>
                                        {activeStep !== 0 && (
                                            <Button
                                                style={styles.button}
                                                disabled={disableButton}
                                                onClick={handleBack}
                                                color="primary"
                                                variant="contained"
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={disableButton}
                                            onClick={(e) => {
                                                if (isLastStep) {
                                                    setFinishing(true);
                                                    setDisableButton(true);
                                                    if (props.viewAsset) {
                                                        props.setConfirmProps({
                                                            message: {
                                                                message: 'Are you sure you want to submit?',
                                                                header: 'Confirmation',
                                                            },
                                                            acceptFunction: () => {
                                                                handleFinalSubmit(formik);
                                                                props.setConfirmProps(prevState => ({
                                                                    ...prevState,
                                                                    showConfirmDialog: false,
                                                                }));
                                                            },
                                                            rejectFunction: () => {
                                                                setFinishing(false);
                                                                setDisableButton(false);
                                                                props.setConfirmProps(prevState => ({
                                                                    ...prevState,
                                                                    showConfirmDialog: false,
                                                                }));
                                                            },
                                                            showConfirmDialog: true,
                                                        });
                                                    } else {
                                                        handleFinalSubmit(formik);
                                                    }
                                                } else {
                                                    createHandleSubmit(formik)();
                                                }
                                            }}
                                        >
                                            {isLastStep ? "Finish" : "Next"}
                                        </Button>
                                    </Stack>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>}
            <div style={{ height: '10px' }} /> {/* Add space below Stepper */}

        </Paper>
    )
}