import { Grid, IconButton, Typography} from "@mui/material";
import { Box } from '@mui/system';
import React, { useState } from "react";
import DefectDetailDropdown from "./DefectDetailDropdown";
import HelpIcon from '@mui/icons-material/Help';
import theme from "../../../theme";
import DefectHelpModal from "./DefectHelpModal";

// Expected Parameter: defects: Array, result set from master defect list
export default function Defects({ userID, defects, onDropdownChange, selectedValue, defaultRecipeStateID }) {

    const [open, setOpen] = useState(false);

    const helpButtonClick = () => {
        setOpen(true);
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Box sx={{ py: 2 }}>
            
            <Grid container spacing={2} align="center" justifyContent="center" alignItems="center" >
                <Grid item xs={10}>
                    <Typography variant="h4" fontWeight="bold">Defect List</Typography>
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={helpButtonClick} sx={{ float: 'right', mr: 2, color: theme.palette.primary.main }}>
                        <HelpIcon fontSize={"large"} />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <DefectDetailDropdown
                        data={defects}
                        userID={userID}
                        handleDropdownChange={onDropdownChange}
                        selectedValue={selectedValue}
                        defaultRecipeStateID={defaultRecipeStateID}
                    />
                </Grid>
            </Grid>
            <DefectHelpModal
                open={open}
                handleModalClose={handleModalClose}
            />
        </Box>
    );
}