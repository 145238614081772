import { Backdrop, Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StatusBackdrop from "../Generic/StatusBackdrop";
import AuthService from './AuthService';

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const navigateToReturnUrl = () => {
        const params = new URLSearchParams(location.search);
        const returnUrl = params.get('returnUrl') || '/';
        window.location.href = returnUrl;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await AuthService.login(username, password);
            navigateToReturnUrl();
        } catch (error) {
            const resMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            setMessage(resMessage);
            setLoading(false);
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            bgcolor="background.default"
        >
            <StatusBackdrop open={true} />
            <Backdrop open={loading} style={{ zIndex: 11 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} style={{ padding: 20, width: 480, zIndex: 10 }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Login
                </Typography>
                <form onSubmit={handleLogin}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: 16 }}
                    >
                        Login
                    </Button>
                </form>
                {message && <Typography color="error" style={{ marginTop: 16 }}>{message}</Typography>}
            </Paper>
        </Box>
    );
};

export default Login;
