import React, { createContext, useState } from 'react';

// Create and export the context
export const SessionContext = createContext(null);

export const SessionProvider = ({ children }) => {
    const [sessionData, setSessionData] = useState({
        userID: 207,
        defaultCompanyID: 168,
        defaultCompanyName: 'Maintenance Hub 2.6',
        currentCompanyID: 168,
        currentCompanyName: 'Maintenance Hub 2.6',
        viewAll: false
    });

    const updateSessionData = (newData) => {
        setSessionData(prevState => ({ ...prevState, ...newData }));
    };

    return (
        <SessionContext.Provider value={{ sessionData, updateSessionData }}>
            {children}
        </SessionContext.Provider>
    );
};


/************************
 * UserID
 * CompanyID
 * Login Token?
 * ViewAll
 */