import apiClient from "../../http-common";
import moment from 'moment';

export const RequestType = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete'
};

export const fetchRequest = async (url, errorMessage, body = null, httpMethod = null) => {
    let response;

    try {
        if (httpMethod && typeof apiClient[httpMethod] === 'function') {
            if (body) {
                response = await apiClient[httpMethod](url, body);
            } else {
                response = await apiClient[httpMethod](url);
            }
        } else {
            if (body) {
                // If a body is provided, use POST request
                response = await apiClient.post(url, body);
            } else {
                // If no body, use GET request
                response = await apiClient.get(url);
            }
        }
    } catch (error) {
        if (process.env.REACT_APP_ENABLE_AUTH && error?.response?.status == 401) {
            window.location.href = `/login?returnUrl=${encodeURIComponent(window.location.pathname)}`;
        } else {
            throw error;
        }
    }

    //if (Object.keys(response.data).length === 0) {
    //    throw new Error(`${errorMessage}: Received empty data from server.`);
    //}
    return response.data;
}

export const postRequest = async (url, errorMessage, body = null) => {
    let response;

    response = await apiClient.post(url, body);
    return response.data;
}

export const fetchBlobRequest = async (url, errorMessage, body = null) => {
    let response;

    if (body) {
        // If a body is provided, use POST request
        response = await apiClient.post(url, body, { responseType: 'blob' });
    } else {
        // If no body, use GET request
        response = await apiClient.get(url,  { responseType: 'blob' });
    }

    return URL.createObjectURL(response.data);
}

const DEFAULT_OPACITY = 0.65;
export const GetSeverityStatusColor = (severity, alarmStatusInfo, secondsSinceCheckIn, computedValue) => {
    // When no computed value then show default status color
    if (typeof computedValue === 'undefined' || computedValue === null) {
        let statusOff = alarmStatusInfo.find(a => a.StatusDisplayName === "Off");
        return GetDefaultSeverityStatus(statusOff);
    } else {
        return GetSeverityStatus(severity, alarmStatusInfo, secondsSinceCheckIn);
    }
}

export const GetSeverityStatusColorVIB = (severity, alarmStatusInfo, secondsSinceCheckIn) => {
    return GetSeverityStatus(severity, alarmStatusInfo, secondsSinceCheckIn);
}

const GetSeverityStatus = (severity, alarmStatusInfo, secondsSinceCheckIn) => {
    let statusOff = alarmStatusInfo.find(a => a.StatusDisplayName === "Off");

    //  Check in information doesn't exist - never checked in then show default status color
    if (typeof secondsSinceCheckIn === 'undefined' || !secondsSinceCheckIn || secondsSinceCheckIn < 0) {
        return GetDefaultSeverityStatus(statusOff);
    } else {
        let status = GetStatusBySeverity(severity, alarmStatusInfo);
        return GetSeverityStatusBySecondsSinceCheckIn(secondsSinceCheckIn, status, statusOff, DEFAULT_OPACITY);
    }
};

const GetStatusBySeverity = (severity, alarmStatusInfo) => {
    let status;
    if (typeof severity === 'undefined' || severity == null) {
        status = alarmStatusInfo.find(a => a.StatusDisplayName === "Normal");
    } else {
        status = alarmStatusInfo.find(a => a.Severity === severity);
    }
    return status;
};

const GetDefaultSeverityStatus = (status) => {
    return CreateSeverityStatus(status.Severity, status.StatusDisplayName, status.StatusColor, 1);
};

const GetSeverityStatusBySecondsSinceCheckIn = (secondsSinceCheckIn, status, offStatus, opacity) => {
    const solid = 1;
    const hours24 = 86400;
    const days7 = 604800;

    if (secondsSinceCheckIn <= hours24) {
        return CreateSeverityStatus(status.Severity, status.StatusDisplayName, status.StatusColor, solid);
    } else if (secondsSinceCheckIn > hours24 && secondsSinceCheckIn <= days7) {
        return CreateSeverityStatus(status.Severity, status.StatusDisplayName, status.StatusColor, opacity);
    } else if (secondsSinceCheckIn > days7) {
        let showIcon = true;
        let tooltip = 'Data was last captured over a week ago.  Alarm status may not be accurate.';
        return CreateSeverityStatus(
            offStatus.Severity, offStatus.StatusDisplayName, offStatus.StatusColor, opacity, showIcon, tooltip);
    }
};

const CreateSeverityStatus = (severity, displayName, hexColor, opacity, showIcon = false, tooltip = '') => {
    return {
        Severity: severity,
        StatusDisplayName: displayName,
        StatusColor: Opacity(hexColor, opacity),
        Opacity: opacity,
        ShowIcon: showIcon,
        Tooltip: tooltip,
    };
};

export const FormatDate = (date) => {
    let start = new Date(date);

    if (start instanceof Date && !isNaN(start)) {
        let year = start.getFullYear(),
            month = start.getMonth() + 1, // months are zero indexed
            day = start.getDate(),
            hour = start.getHours(),
            minute = start.getMinutes(),
            second = start.getSeconds(),
            hourFormatted = hour % 12 || 12, // hour returned in 24 hour format
            minuteFormatted = ("0" + minute).slice(-2),
            morning = hour < 12 ? "am" : "pm";

        return ("0" + month).slice(-2) + "/" + ("0" + day).slice(-2) + "/" + year + " " + hourFormatted + ":" +
            minuteFormatted + ":" + ("0" + second).slice(-2) + " " + morning;
    } else {
        return "No Data Exists";
    }
}

export const RemoveTimeFromDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(undefined, options);
};

export const Opacity = (hex, opacity) => {
    let editedHex = hex; 

    if (typeof hex === 'string') {
        editedHex = hex.replace(/FF$/, '');
    }

    if (typeof editedHex !== 'string' || !/^#([A-Fa-f0-9]{3}$|[A-Fa-f0-9]{6}$)/.test(editedHex))
        throw new Error('Invalid hexadecimal color value');

    if (typeof opacity !== 'number' || opacity > 1 || opacity < 0)
        throw new Error('Opacity should be a float between 0 and 1');

    let r = 0, g = 0, b = 0;

    if (editedHex.length === 4) {
        // e.g., #RGB -> #RRGGBB
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (editedHex.length === 7) {
        // e.g., #RRGGBB
        r = parseInt(hex.substring(1, 3), 16);
        g = parseInt(hex.substring(3, 5), 16);
        b = parseInt(hex.substring(5, 7), 16);
    }

    return `rgba(${r},${g},${b},${opacity})`;
};


export const CalculateTimeDifference = (pastDate) => {
    return moment().diff(moment(pastDate), 'seconds');
};

export const KeysToLowerCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(value => KeysToLowerCase(value));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce((accumulator, key) => {
            accumulator[key.charAt(0).toLowerCase() + key.slice(1)] = KeysToLowerCase(obj[key]);
            return accumulator;
        }, {});
    }
    return obj;
}

export const createToastSuccess = (message, lifespan) => {
    return {
        severity: 'success',
        summary: 'Success Message',
        detail: message,
        life: lifespan,
        position: 'center'
    }
}

export const createToastError = (message, lifespan) => {
    return {
        severity: 'error',
        summary: 'Failure Message',
        detail: message,
        life: lifespan,
        position: 'center',
    }
}
