import React from 'react';
import { useField, ErrorMessage } from 'formik';
import FieldHelperText from './FieldHelperText';
import {
    Switch,
    Box,
    FormControl,
    FormControlLabel
} from '@mui/material';

export default function FormikSwitch(props) {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: props.width,
                    maxWidth: '100%',
                }}
            >
                <FormControl {...rest} fullWidth>
                    <FormControlLabel
                        control={
                            <Switch
                                {...field} // Connects the Switch to Formik
                                checked={field.value} // Checked state from Formik
                                color="primary"
                            />
                        }
                        label={props.label}
                    />
                </FormControl>
                {meta.touched && meta.error &&
                    <ErrorMessage name={field.name}>
                        {msg => <FieldHelperText message={msg} />}
                    </ErrorMessage>
                }
            </Box>
        </Box>
    );
}