import React from 'react';
import BorderlessAssetImage from '../images/BorderlessAssetImage.png';

export default function BigAssetImage({style }) {

    return (
        <img
            src={BorderlessAssetImage}
            alt={'GenericMachineType'}
            style={style}>
        </img>); 
}