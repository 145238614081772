import PhaseAngleDiagram from './PhaseAngleDiagram';
import Grid from '@mui/material/Grid';

export default function PoisitionAngle({ ingredient, phaseAngles }) {

    //Find the right label for each axis and place them on the graph correctly

    let axial = phaseAngles.find(axis => axis.axisDisplayName === "Axial");
    let horizontal = phaseAngles.find(axis => axis.axisDisplayName === "Horizontal");
    let vertical = phaseAngles.find(axis => axis.axisDisplayName === "Vertical");
    return (
        <>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <PhaseAngleDiagram
                        angle={vertical?.angle}
                        positionText={vertical?.channelDisplayName}
                        axisText={vertical?.angle + " " + ingredient.PrimaryComputedData.UnitTypeName}
                        color={vertical?.color}
                        orientation="vertical"
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhaseAngleDiagram
                        angle={horizontal?.angle}
                        positionText={horizontal?.channelDisplayName}
                        axisText={horizontal?.angle + " " + ingredient.PrimaryComputedData.UnitTypeName}
                        color={horizontal?.color}
                        orientation="horizontal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhaseAngleDiagram
                        angle={axial?.angle}
                        positionText={axial?.channelDisplayName}
                        axisText={axial?.angle + " " + ingredient.PrimaryComputedData.UnitTypeName}
                        color={axial?.color}
                        orientation="axial"
                    />
                </Grid>
            </Grid>
        </>
    )
}