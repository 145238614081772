import React from 'react';
import CoolingTowerDrive from './images/Cooling Tower Drive.jpg';
import FanBlowerBelt from './images/Fan-Blower (Belt Driven).jpg';
import CentrifugalPumpVertical from './images/Centrifugal Pump (Vertical).jpg';
import CentrifugalPump from './images/Centrifugal Pump.jpg';
import ChillerUnit from './images/Chiller Unit.jpg';
import CompressorCentrifugal from './images/Compressor (Centrifugal).jpg';
import CompressorReciprocating from './images/Compressor (Reciprocating).jpg';
import CompressorScrewType from './images/Compressor (Screw Type).jpg';
import FanBlowerIntegralShaft from './images/Fan-Blower (Integral Shaft).jpg';
import FanBlowerDirect from './images/FanBlowerDirectDriven.jpg';
import MotorGenerator from './images/Motor-Generator.jpg';
import TurbineGenerator from './images/Turbine Generator.jpg';
import GenericMachineType from './images/GenericMachineType.jpg';

export default function AssetImage(props) {

        switch (props.machineType) {
            case 0:
                return <img
                    src={GenericMachineType}
                    alt={'GenericMachineType'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;                
            case 1:
            case 2:
            case 3:
                return <img
                    src={CoolingTowerDrive}
                    alt={'CoolingTowerDrive'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 4:
                return <img
                    src={CompressorReciprocating}
                    alt={'CompressorReciprocating'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 5:
                return <img
                    src={CompressorScrewType}
                    alt={'CompressorScrewType'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 6:
                return <img
                    src={CompressorCentrifugal}
                    alt={'CompressorCentrifugal'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 7:
                return <img
                    src={FanBlowerIntegralShaft}
                    alt={'FanBlowerIntegralShaft'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 8:
                return <img
                    src={FanBlowerDirect}
                    alt={'FanBlowerDirect'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 9:
                return <img
                    src={FanBlowerBelt}
                    alt={'FanBlowerBelt'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 10:
            case 11:
                return <img
                    src={MotorGenerator}
                    alt={'MotorGenerator'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 12:
            case 13:
                return <img
                    src={ChillerUnit}
                    alt={'ChillerUnit'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 14:
            case 15:
                return <img
                    src={TurbineGenerator}
                    alt={'TurbineGenerator'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 16:
            case 17:
                return <img
                    src={CentrifugalPump}
                    alt={'CentrifugalPump'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            case 18:
                return <img
                    src={CentrifugalPumpVertical}
                    alt={'CentrifugalPumpVertical'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;
                
            default:
                return <img
                    src={GenericMachineType}
                    alt={'GenericMachineType'}
                    style={{ maxHeight: '200px', maxWidth: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                </img>;   

        }   
}