import { VBTxConfigurationFormModel } from './VBTxConfigurationFormModel';

const {
    formField: {
        assetName,
        machineType,
        driveType,
        p1AssignedNode,
        p2AssignedNode,
        p3AssignedNode,
        p4AssignedNode,
        gearboxLocation,
        bladeCount,
        inputToothCount,
        outputToothCount,
        shaftSpeed,
        shaftSpeedHiBound,
        shaftSpeedLoBound,
        vibrationSamplingPeriod,
        temperatureSamplingPeriod,
        triggerSource,
        threshold,
        maxTriggers,
        defectLevel,
        maximizeNodes
    }
} = VBTxConfigurationFormModel;

export const VBTxConfigurationInitialValues = {
    [assetName.name]: '',
    [machineType.name]: null,
    [driveType.name]: null,
    [p1AssignedNode.name]: {
        "nodeID": 0,
        "axisAlignment": 0,
        "shaftSpeedSource": 1,
        "xEnabled": true,
        "yEnabled": true,
        "zEnabled": true,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "warningRecipientIDs": [],
        "dangerRecipientIDs": [],

    },
    [p2AssignedNode.name]: {
        "nodeID": 0,
        "axisAlignment": 0,
        "shaftSpeedSource": 1,
        "xEnabled": true,
        "yEnabled": true,
        "zEnabled": true,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "warningRecipientIDs": [],
        "dangerRecipientIDs": [],
    },
    [p3AssignedNode.name]: {
        "nodeID": 0,
        "axisAlignment": 0,
        "shaftSpeedSource": 1,
        "xEnabled": true,
        "yEnabled": true,
        "zEnabled": true,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "warningRecipientIDs": [],
        "dangerRecipientIDs": [],
    },
    [p4AssignedNode.name]: {
        "nodeID": 0,
        "axisAlignment": 0,
        "shaftSpeedSource": 1,
        "xEnabled": true,
        "yEnabled": true,
        "zEnabled": true,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "warningRecipientIDs": [],
        "dangerRecipientIDs": [],
    },
    [gearboxLocation.name]: null,
    [bladeCount.name]: 0,
    [inputToothCount.name]: 0,
    [outputToothCount.name]: 0,
    [shaftSpeed.name]: 1800,
    [shaftSpeedLoBound.name]: 1500,
    [shaftSpeedHiBound.name]: 3900,
    [vibrationSamplingPeriod.name]: 3600,
    [temperatureSamplingPeriod.name]: 3600,
    [triggerSource.name]: 1,
    [threshold.name]: 0,
    [maxTriggers.name]: 0,
    [defectLevel.name]: 2,
    [maximizeNodes.name]: false,
};