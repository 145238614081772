import React, { useState } from 'react';
import { TableCell, TableRow, IconButton, Typography, TextField } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


export default function FolderRow(props) {
    const [isEditing, setIsEditing] = useState(false);

    const handleEditButtonClick = () => {
        setIsEditing(true);
    }

    const handleSaveButtonClick = () => {
        setIsEditing(false);
        props.handleFolderNameSave(props.folder.ObjectID);
    }

    return (
        <TableRow key={props.folder.ObjectID}>
            <TableCell>
                <FolderIcon color="primary" />
            </TableCell>
            <TableCell component="th" scope="row">
                {isEditing ? (
                    <TextField
                        variant="outlined"
                        value={props.folder.DisplayName}
                        onChange={(event) => props.handleFolderNameChange(event, props.folder.ObjectID)}
                    />) : (
                        <Typography>
                            {props.folder.DisplayName}
                    </Typography>)}
            </TableCell>
            <TableCell>
                {isEditing ? (
                    <IconButton onClick={handleSaveButtonClick}>
                        <CheckIcon color="primary" />
                    </IconButton>
                ) : (
                    <IconButton onClick={handleEditButtonClick}>
                            <EditIcon color="primary" />
                    </IconButton>)}
            </TableCell>
            <TableCell>
                <IconButton onClick={() => { props.handleDeleteFolderClick(props.folder.ObjectID) }}>
                    <ClearIcon color="primary" />
                </IconButton>
            </TableCell>
            {props.viewAll &&
                <TableCell>
                    <IconButton onClick={() => {props.handleAssignModalOpen(props.folder) } }>
                        <DriveFileMoveIcon color="primary" />
                    </IconButton>
                </TableCell>
                }
        </TableRow>
    )
}