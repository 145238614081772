import { alarmFormModel } from './alarmFormModel';

const {
    formField: {
        alarmName,
        alarmSummary,
        thresholdDataTypeID,
        thresholdDataTypeName,
        assignedComputedDataSourceIDs,
        isCheckedTargets,
        thresholdComparisonID,
        thresholdTriggerValue,
        thresholdUnitTypeID,
        alarmSeverityID,
        // *** NOTIFICATIONS ARE NOT REQUIRED *** 
        sendNotification,
        notificationID,
        //notificationPeriodID,
        notificationRecipients,
        alarmID
    }
} = alarmFormModel;

export const alarmInitialValues = {
    [alarmName.name]: '',
    [alarmSummary.name]: undefined,
    [thresholdDataTypeID.name]: null,
    [thresholdDataTypeName.name]: '',
    [assignedComputedDataSourceIDs.name]: [],
    [isCheckedTargets.name]: null,
    [thresholdComparisonID.name]: null,
    [thresholdTriggerValue.name]: null,
    [thresholdUnitTypeID.name]: null,
    [alarmSeverityID.name]: '',
    // *** NOTIFICATIONS ARE NOT REQUIRED *** 
    [sendNotification.name]: true,
    [notificationID.name]: null,
    //[notificationPeriodID.name]: '',
    [notificationRecipients.name]: [],
    [alarmID.name]: null
  
};

export const alarmAddInitialValues_Step1 = {
    [alarmName.name]: '',
    [alarmSummary.name]: '',
    [thresholdDataTypeID.name]: null,
    [thresholdDataTypeName.name]: '',
    [assignedComputedDataSourceIDs.name]: [],
    [isCheckedTargets.name]: null,
};

export const alarmAddInitialValues_Step2 = {
    [thresholdComparisonID.name]: null,
    [thresholdTriggerValue.name]: null,
    [thresholdUnitTypeID.name]: '',
    [alarmSeverityID.name]: null,
    // *** NOTIFICATIONS ARE NOT REQUIRED *** 
    [sendNotification.name]: true,
    [notificationID.name]: null,
    //[notificationPeriodID.name]: '',
    [notificationRecipients.name]: []
};