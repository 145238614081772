import React, { useRef, useState } from 'react';
import { Box, Grid, Typography, IconButton, Button } from "@mui/material"
import { fetchControlGateTree, fetchDashboardTree, removeParent, setNewParent, downloadL5X, downloadAOP, downloadMemoryMap, fetchDocumentList, downloadDocument, fetchDashboardList } from './ControlQueryFunctions'
import { Toast } from 'primereact/toast';
import StatusMessage from "../Generic/StatusMessage";
import StatusBackdrop from "../Generic/StatusBackdrop";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import FolderCard from "../Generic/FolderCard";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ControlGateMenu from './ControlGateMenu';
import AssignModal from './AssignModal';
import DocumentModal from './DocumentModal'
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

const TreeObjectType = {
    /** An unknown or invalid DashboardObjectType */
    Unknown: 0,
    /** Represents a folder in the tree structure */
    Folder: 1,
    /** Represents a hardware node in the tree structure */
    Node: 2,
    /** Represents direct sensor data */
    SensorData: 3,
    /** Represents computed sensor data */
    ComputedData: 4,
    /** Represents a set of vibration data channels */
    Vibration: 5,
    /** Represents a set of HSM data channels */
    HSM: 6,
    /** Represents a set of oversampled data channels */
    Oversample: 7,
    /** Represents a set of diagnostics data channels */
    Diagnostics: 8,

    XAxis: 9,
    YAxis: 10,
    ZAxis: 11,

    /** Represents an existing alarm */
    Alarm: 12,

    DataType: 13,

    SensorType: 14
}

// Page Styling
const pageStyle = {
    margin: "2%"
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '480px',
    overflow: 'auto',
    display: 'block',
    outline: 'none'
};

export default function ControlGateConfiguration() {

    const { companyID } = useParams();
    const toast = useRef(null);

    //Members used to control the states of components
    const [errorState, setErrorState] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedControlGate, setSelectedControlGate] = useState();
    const [selectedRowId, setSelectedRowId] = useState(null);

    //Data pulled from the API
    const [dashboardID, setDashboardID] = useState();
    const [cgRootNode, setCGRootNode] = useState();
    const [dashRootNode, setDashRootNode] = useState();
    const [dashNodes, setDashNodes] = useState([]);
    const [cgNodes, setCGNodes] = useState([]);
    const [docList, setDocList] = useState([]);

    //Members used to determine if API requests are loading
    const [isLoadedCGTree, setIsLoadedCGTree] = useState(false);
    const [isLoadedDashboardTree, setIsLoadedDashboardTree] = useState(false);
    const [isLoadedDocumentList, setIsLoadedDocumentList] = useState(false);
    const [isLoadedDashList, setIsLoadedDashList] = useState(false);
    const [documentDownloading, setDocumentDownloading] = useState(false);

    //Controls whether certain modals or menus are open
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [documentModalOpen, setDocumentModalOpen] = useState(false);
    const controlGateMenuOpen = Boolean(anchorEl);
    const id = controlGateMenuOpen ? 'simple-popover' : undefined;

    /*
        Filters the trees pulled from the API according to the function passed as a parameter
        Used to filter out unwanted children (Diagnostics, Sensor data, etc.)
    */
    const filterTree = (tree, filterChildren) => {
        for (let i = 0; i < tree.Children.length; i++) {
            if (!filterChildren(tree.Children[i])) {
                tree.Children.splice(i, 1);
                i--;
            }
            else {
                filterTree(tree.Children[i], filterChildren);
            }
        }
    }

    /*
        Only show nodes that are not in the assigned list
    */
    const filterUnassignedChildren = (node) => {
        switch (node.ObjectType) {
            case TreeObjectType.Diagnostics:
            case TreeObjectType.Vibration:
            case TreeObjectType.Oversample:
            case TreeObjectType.HSM:
            case TreeObjectType.ComputedData:
            case TreeObjectType.SensorData:
                return false;
            default:
                break;
        }
        return true;
    }

    /*
        Only show nodes
    */
    const filterAssignedChildren = (node) => {
        switch (node.ObjectType) {
            case TreeObjectType.Diagnostics:
            case TreeObjectType.Vibration:
            case TreeObjectType.Oversample:
            case TreeObjectType.HSM:
            case TreeObjectType.ComputedData:
            case TreeObjectType.SensorData:
                return false;
            default:
                break;
        }
        return true;
    }

    /*
        Turn the tree into an array for display. Set the folder name and filter out CloudGates
    */
    const convertUnassignedTreeToArray = (tree, folderName) => {
        let arr = [];
        if (tree.ObjectType == TreeObjectType.Folder) {
            folderName = tree.DisplayName;
        }
        if (tree.ObjectType != TreeObjectType.Folder && !tree.IsCloudGate && !assignedNodesContains(tree)) {
            tree.FolderName = folderName;
            arr.push(tree);
        }
        for (let i = 0; i < tree.Children.length; i++) {
            arr = arr.concat(convertUnassignedTreeToArray(tree.Children[i], folderName));
        }
        return arr;
    }

    /*
        Turn the tree into an array for display. Only allow CloudGates and remove folders
    */
    const convertAssignedTreeToArray = (tree) => {
        let arr = [];
        if (tree.ObjectType != TreeObjectType.Folder && tree.IsCloudGate) {
            tree.FolderName = ""
            arr.push(tree);
        }
        for (let i = 0; i < tree.Children.length; i++) {
            if (tree.Children[i].ObjectType != TreeObjectType.Diagnostics) {
                arr = arr.concat(convertAssignedTreeToArray(tree.Children[i]));
            }
        }
        return arr;
    }

    /*
        Determine if the assigned node list contains a node (this is indicated by A_ in front of Object ID)
    */
    const assignedNodesContains = (node) => {
        for (let i = 0; i < cgNodes.length; i++) {
            for (let j = 0; j < cgNodes[i].Children.length; j++) {
                if (node.ObjectID == Number(cgNodes[i].Children[j].ObjectID.split("_")[1])) {
                    return true;
                }
            }
            
        }
        return false;
    }

    /*
        Gets dashboards and trees using API calls
    */
    const { isLoading: dashListLoading, error: dashListError, data: dataT } =
        useQuery(["dashlist", companyID], fetchDashboardList, {
            onSuccess: (dataT) => {
                setDashboardID(dataT[0].Item1);
                setIsLoadedDashList(true);
            },
            onError: (dashboardsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dashboardsError || "An unexpected error occurred.");
            }
        });

    const { isLoading: cgTreeLoading, error: cgTreeError, data: dataC } =
        useQuery({
            queryKey: ["cgtree", dashboardID], queryFn: fetchControlGateTree,
            onSuccess: (dataC) => {
                filterTree(dataC, filterAssignedChildren);
                setCGRootNode(dataC);
                setCGNodes(convertAssignedTreeToArray(dataC));
                setIsLoadedCGTree(true);
            },
            onError: (dashboardsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dashboardsError || "An unexpected error occurred.");
            },
            enabled: !!dashboardID
        });

    const { isLoading: dashTreeLoading, error: dashTreeError, data: dataD } =
        useQuery({
            queryKey: ["dashtree", dashboardID],
            queryFn: fetchDashboardTree,
            onSuccess: (dataD) => {
                filterTree(dataD, filterUnassignedChildren);
                setDashRootNode(dataD);
                setDashNodes(convertUnassignedTreeToArray(dataD, ""));
                setIsLoadedDashboardTree(true);
            },
            onError: (dashboardsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dashboardsError || "An unexpected error occurred.");
            },
            enabled: !!dashboardID && !!cgRootNode
        });

    const { isLoading: documentListLoading, error: documentListError, data: dataO } =
        useQuery(["doclist"], fetchDocumentList, {
            onSuccess: (dataO) => {
                setDocList(dataO);
                setIsLoadedDocumentList(true);
            },
            onError: (dashboardsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dashboardsError || "An unexpected error occurred.");
            }
        });

    
    /*
        Performs post requests to assign/unassign nodes and download documents
    */
    const doRemoveParent =
        useMutation((childObjectID) => removeParent(childObjectID, companyID), {
            onSuccess: (result) => {
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'Node unassigned',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
                setTimeout(() => {
                    window.location.reload();
                }, 3000); // Match the life of the toast 
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to unassign node',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    const doSetNewParent =
        useMutation(({ childObjectID, parentObjectID }) => setNewParent(childObjectID, parentObjectID, companyID), {
            onSuccess: (result) => {
                setAssignModalOpen(false);
                setSelectedRowId(null);
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'Node assigned',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
                setTimeout(() => {
                    window.location.reload();
                }, 3000); // Match the life of the toast 
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to assign node',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    const doDownloadL5X =
        useMutation(({ dashboardObjectID, displayName }) => downloadL5X(dashboardObjectID, displayName, companyID), {
            onSuccess: (result) => {
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'File created',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to create file',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    const doDownloadAOP =
        useMutation(({ dashboardObjectID, displayName }) => downloadAOP(dashboardObjectID, displayName, companyID), {
            onSuccess: (result) => {
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'File created',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to create file',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    const doDownloadMemoryMap =
        useMutation(({ dashboardObjectID, displayName }) => downloadMemoryMap(dashboardObjectID, displayName, companyID), {
            onSuccess: (result) => {
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'File created',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to create file',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    const doDownloadDocument =
        useMutation((fileName) => downloadDocument(fileName), {
            onSuccess: (result) => {
                setDocumentModalOpen(false);
                setDocumentDownloading(false);
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'File downloaded',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to downloaded file',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    /*
        Handles the opening and closing of menus and modals
    */
    const handleControlGateMenuClose = () => {
        setSelectedRowId(null);
        setAnchorEl(null);
    }

    const handleControlGateMenuClick = (event, id) => {
        setSelectedRowId(id);
        setAnchorEl(event.currentTarget);
    }

    const handleAssignModalOpen = (id) => {
        setSelectedRowId(id);
        setAssignModalOpen(true);
    }

    const handleAssignModalClose = () => {
        setAssignModalOpen(false);
        setSelectedRowId(null);
    }

    const handleSelectedControlGateChange = (event) => {
        setSelectedControlGate(event.target.value);
    }

    const handleDocumentModalOpen = () => {
        setDocumentModalOpen(true);
    }

    const handleDocumentModalClose = () => {
        setDocumentModalOpen(false);
    }

    /*
        Handle downloading of documents
    */
    const handleDownloadL5X = (dashboardObjectID) => {
        let displayName = cgNodes.find((node) => node.ObjectID == dashboardObjectID).DisplayName
        if ((typeof dashboardObjectID) === "string") {
            dashboardObjectID = Number(dashboardObjectID.split("_")[1])
        }
        doDownloadL5X.mutateAsync({ dashboardObjectID: dashboardObjectID, displayName: displayName });
    }

    const handleDownloadAOP = (dashboardObjectID) => {
        let displayName = cgNodes.find((node) => node.ObjectID == dashboardObjectID).DisplayName
        if ((typeof dashboardObjectID) === "string") {
            dashboardObjectID = Number(dashboardObjectID.split("_")[1])
        }
        doDownloadAOP.mutateAsync({ dashboardObjectID: dashboardObjectID, displayName: displayName });
    }

    const handleDownloadMemoryMap = (dashboardObjectID) => {
        let displayName = cgNodes.find((node) => node.ObjectID == dashboardObjectID).DisplayName
        if ((typeof dashboardObjectID) === "string") {
            dashboardObjectID = Number(dashboardObjectID.split("_")[1])
        }
        doDownloadMemoryMap.mutateAsync({ dashboardObjectID: dashboardObjectID, displayName: displayName });
    }

    const handleDocumentDownload = (fileName) => {
        setDocumentDownloading(true);
        setDocumentModalOpen(false);
        doDownloadDocument.mutateAsync(fileName);
    }

    /*
        Handles assigning and unassigning of nodes
    */
    const handleUnassignClick = (id) => {
        if ((typeof id) === "string") {
            id = Number(id.split("_")[1])
        }
        doRemoveParent.mutateAsync(id);
    }

    const handleAssignClick = (childID, parentID) => {
        if ((typeof childID) === "string") {
            childID = Number(childID.split("_")[1])
        }
        if ((typeof parentID) === "string") {
            parentID = Number(parentID.split("_")[1])
        }
        doSetNewParent.mutateAsync({ childObjectID: childID, parentObjectID: parentID });
    }


    /*
        Renders the buttons on the right side of the rows for both tables
    */
    const renderChildAction = (node) => {
        return (<Button
            style={{ float: "right" }}
            onClick={() => handleUnassignClick(node.ObjectID)}
            endIcon={<ClearIcon />}
        >
            Unassign
        </Button>)
    }

    const renderAssignedParentAction = (node) => {
        return (
            <Box>
                <IconButton onClick={(event) => handleControlGateMenuClick(event, node.ObjectID)}>
                    <MoreHorizIcon />
                </IconButton>
                {selectedRowId === node.ObjectID && (<ControlGateMenu
                    id={node.ObjectID}
                    objectID={node.ObjectID}
                    controlGateMenuOpen={controlGateMenuOpen}
                    handleControlGateMenuClose={handleControlGateMenuClose}
                    anchorEl={anchorEl}
                    handleDownloadL5X={handleDownloadL5X}
                    handleDownloadAOP={handleDownloadAOP}
                    handleDownloadMemoryMap={handleDownloadMemoryMap}
                />)}
            </Box>)
    }

    const renderUnassignedParentAction = (node) => {
        return (
            <Box>
                <Button
                    onClick={() => handleAssignModalOpen(node.ObjectID)}
                    endIcon={<AddIcon />}
                >
                    Assign
                </Button>
                {selectedRowId === node.ObjectID && (<AssignModal
                    id={node.ObjectID}
                    displayName={node.DisplayName}
                    controlgates={cgNodes}
                    selectedControlGate={selectedControlGate}
                    handleSelectedControlGateChange={handleSelectedControlGateChange}
                    assignModalOpen={assignModalOpen}
                    handleAssignModalClose={handleAssignModalClose}
                    handleAssignClick={handleAssignClick}
                    modalStyle={modalStyle}
                />)}
            </Box>
            )
    }
    return (
        <Box sx={pageStyle}>
            {(cgTreeLoading || dashTreeLoading || documentListLoading || dashListLoading || documentDownloading) &&
                <StatusBackdrop open={cgTreeLoading || dashTreeLoading || documentListLoading || dashListLoading || documentDownloading} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="warning"
                    location="Company"
                    statusCode={errorState?.request?.status}
                    message={errorState?.message}
                    error={errorState}
                />
            }
            <Toast ref={toast} />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">ControlGate Configuration</Typography>
                </Grid>
                {isLoadedCGTree && <Grid container item xs={12}>
                    <Grid item xs={10}>
                        <Typography variant="h4" align="left">Assigned Nodes</Typography>
                    </Grid>
                    <br /><br />
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={handleDocumentModalOpen}>Documents</Button>
                        {isLoadedDocumentList && (< DocumentModal
                            docList={docList}
                            modalStyle={modalStyle}
                            documentModalOpen={documentModalOpen}
                            handleDocumentDownload={handleDocumentDownload}
                            handleDocumentModalClose={handleDocumentModalClose}
                        />)}
                    </Grid>

                    <Grid item xs={12}>
                        <FolderCard
                            node={cgRootNode}
                            list={cgNodes}
                            renderChildAction={renderChildAction}
                            showChildren={true}
                            renderParentAction={renderAssignedParentAction}
                            showFilter={false}
                        />
                    </Grid>
                </Grid>}
                {isLoadedDashboardTree && <Grid item xs={12}>
                    <br /><br />
                    <Typography variant="h4" align="left">Unassigned Nodes</Typography>
                    <br />
                    <FolderCard
                        node={dashRootNode}
                        list={dashNodes}
                        renderChildAction={renderChildAction}
                        showChildren={false}
                        renderParentAction={renderUnassignedParentAction}
                        showFilter={true}
                    />
                </Grid>}
            </Grid>
        </Box>
    )
}