import React, { useState } from 'react';
import { TableCell } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useClasses } from '../../customHooks';

const styles = {
    overrides: {
        MUITableHeadRow: {
            root: {
                display: 'none'
            }
        }
    },
}

export default function NodeList(props) {

    const classes = useClasses(styles);

    // Define columns for node-level table
    const columns = [
        {
            name: "DisplayName",
            label: "",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            {props.list[dataIndex].DisplayName}
                        </span>
                    );
                },
                searchable: true,
                filter: false,
                sort: true,
                customHeadRender: () => null,

            },
        },
        {
            //Renders a custom component determined by parent. This can be used to display buttons, etc.
            name: "ChildAction",
            label: "",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (props.renderChildAction(props.list[dataIndex]))
                },
                searchable: true,
                filter: false,
                sort: true,
                customHeadRender: () => null,

            },
        },
    ];

    const options = {
        fixedHeader: true,
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: null,
        responsive: 'vertical',
        pagination: false,
        selectableRowsHideCheckboxes: true,
        enableNestedDataAccess: ".",
    };


    return (
        <TableCell colSpan={4}>
                <MUIDataTable
                    title={""}
                    columns={columns}
                    data={props.list}
                    options={options}
                    className={classes.overrides}
              />
        </TableCell>
    );
}