import React, { useState } from 'react';
import { Checkbox, TableCell, TableRow, IconButton, Typography, TextField } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';


export default function DiagnosticsRow(props) {
    const [isEditing, setIsEditing] = useState(false);

    const handleEditButtonClick = () => {
        setIsEditing(true);
    }

    const handleSaveButtonClick = () => {
        setIsEditing(false);
        props.handleNameSave(props.ObjectID);
    }

    return (
        <TableRow key={props.ObjectID}>
            <TableCell>
                <TimelineIcon color="primary" />
            </TableCell>
            <TableCell component="th" scope="row">
                {isEditing ? (
                    <TextField
                        variant="outlined"
                        value={props.DisplayName}
                        onChange={(event) => props.handleNameChange(event, props.ObjectID)}
                    />) : (
                    <Typography>
                        {props.DisplayName}
                    </Typography>) }
            </TableCell>
            <TableCell>
                {isEditing ? (
                    <IconButton onClick={handleSaveButtonClick}>
                        <CheckIcon color="primary" />
                    </IconButton>
                ) : (
                    <IconButton onClick={handleEditButtonClick}>
                            <EditIcon color="primary" />
                    </IconButton>)}
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={props.IsEnabled}
                    onChange={(event) => props.handleCheckboxChange(event, props.ObjectID)}
                />
            </TableCell>
        </TableRow>
    )
}