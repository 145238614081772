import apiClient from "../../http-common";
import { fetchRequest } from "../Generic/MiscFunctions"

export const fetchDashboardList = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;

    if (!companyID) {
        throw new Error("The 'companyID' parameters are required.");
    }

    return fetchRequest(`/Control/getdashboardlist/${companyID}`, "Failed to fetch dashboard list");
};

export const fetchDashboardTree = async ({ queryKey }) => {
    const [_key, dashboardID] = queryKey;

    if (!dashboardID) {
        throw new Error("The 'dashboardID' parameters are required.");
    }

    return fetchRequest(`/Dashboard/getdashboardtree/${dashboardID}`, "Failed to fetch dashboard tree");
};

export const fetchCompanyList = async ({ queryKey }) => {
    const [_key] = queryKey;

    return fetchRequest(`/Company/companylist`, "Failed to fetch company list");
};

export const fetchNodeConfig = async ({ queryKey }) => {
    const [_key, dashboardObjectID, companyID] = queryKey;

    if (!dashboardObjectID || !companyID) {
        throw new Error("The 'dashboardObjectID' and 'companyID' parameters are required.");
    }

    return fetchRequest(`/Settings/getnodeconfig/${dashboardObjectID}/${companyID}`, "Failed to fetch node configuration");
};

export const fetchVibrationConfig = async ({ queryKey }) => {
    const [_key, dashboardObjectID, companyID] = queryKey;

    if (!dashboardObjectID || !companyID) {
        throw new Error("The 'dashboardObjectID' and 'companyID' parameters are required.");
    }

    return fetchRequest(`/Settings/getvibrationconfig/${dashboardObjectID}/${companyID}`, "Failed to fetch vibration configuration");
};

export const fetchNodeChannelConfig = async ({ queryKey }) => {
    const [_key, dashboardObjectID, companyID] = queryKey;

    if (!dashboardObjectID || !companyID) {
        throw new Error("The 'dashboardObjectID' and 'companyID' parameters are required.");
    }

    return fetchRequest(`/Settings/getnodechannelconfig/${dashboardObjectID}/${companyID}`, "Failed to fetch node channel configuration");
};

export const fetchCommands = async ({ queryKey }) => {
    const [_key, dashboardObjectID] = queryKey;

    if (!dashboardObjectID) {
        throw new Error("The 'dashboardObjectID' parameters are required.");
    }

    return fetchRequest(`/Settings/getcommands/${dashboardObjectID}`, "Failed to fetch commands");
};

export const updateNodeName = async (dashboardObjectID, displayName, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/updatenodename/${dashboardObjectID}/${displayName}/${ companyID }/${ userID }`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error updating node name:", error);
        throw new Error("Error updating node name:");
    }
};

export const updateNodeChannelName = async (dashboardObjectID, displayName, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/updatenodechannelname/${dashboardObjectID}/${displayName}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error updating node channel name:", error);
        throw new Error("Error updating node channel name:");
    }
};

export const updateFolderName = async (dashboardObjectID, displayName, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/updatefoldername/${dashboardObjectID}/${displayName}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error updating folder name:", error);
        throw new Error("Error updating folder name:");
    }
};


export const setChannelEnabled = async (dashboardObjectID, isEnabled, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/setenable/${dashboardObjectID}/${isEnabled}/${ companyID }/${ userID }`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error setting channel enabled:", error);
        throw new Error("Error setting channel enabled:");
    }
};

export const setNodeConfig = async (dashboardObjectID, companyID, configs) => {
    try {
        const response = await apiClient.post(`/Settings/setnodeconfig/${dashboardObjectID}/${companyID}`, configs);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error setting node configuration:", error);
        throw new Error("Error setting node configuration:");
    }
};

export const setVibrationConfig = async (dashboardObjectID, companyID, configs) => {
    try {
        const response = await apiClient.post(`/Settings/setvibrationconfig/${dashboardObjectID}/${companyID}`, configs);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error setting vibration configuration:", error);
        throw new Error("Error setting vibration configuration:");
    }
};

export const setNodeChannelConfig = async (dashboardObjectID, companyID, configs) => {
    try {
        const response = await apiClient.post(`/Settings/setnodechannelconfig/${dashboardObjectID}/${companyID}`, configs);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error setting node channel configuration:", error);
        throw new Error("Error setting node channel configuration:");
    }
};

export const createFolder = async (parentDashboardObjectID, displayName, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/createfolder/${parentDashboardObjectID}/${displayName}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error creating folder:", error);
        throw new Error("Error creating folder:");
    }
};

export const deleteFolder = async (dashboardID, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/deletefolder/${dashboardID}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error deleting folder:", error);
        throw new Error("Error deleting folder:");
    }
};

export const addObjectToFolder = async (dashboardObjectID, newParentObjectID, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/addobjecttofolder/${dashboardObjectID}/${newParentObjectID}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error adding object to folder:", error);
        throw new Error("Error adding object to folder:");
    }
};

export const moveNodeToCompany = async (dashboardObjectID, newCompanyID, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/movenodetocompany/${dashboardObjectID}/${newCompanyID}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error moving node to company:", error);
        throw new Error("Error moving node to company:");
    }
};

export const assignFolder = async (dashboardObjectID, newCompanyID, companyID) => {
    try {
        const response = await apiClient.post(`/Settings/assignFolder/${dashboardObjectID}/${newCompanyID}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error assigning folder to company:", error);
        throw new Error("Error assigning folder to company:");
    }
};

export const queueCommands = async (dashboardObjectID, commandTypeID) => {
    try {
        const response = await apiClient.post(`/Settings/queuecommand/${dashboardObjectID}/${commandTypeID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error queueing commands:", error);
        throw new Error("Error queueing commands:");
    }
};

export const deleteCommands = async (dashboardObjectID, commandTypeID) => {
    try {
        const response = await apiClient.post(`/Settings/deletecommand/${dashboardObjectID}/${commandTypeID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error deleting commands:", error);
        throw new Error("Error deleting commands:");
    }
};

export const fetchSettingsEnums = async () => {
    return fetchRequest(`/Settings/getenums`, "Failed to fetch node settings enums");
};

export const fetchSamplingRates = async ({ queryKey }) => {
    const [_key, dashboardObjectIDs, companyID] = queryKey;
    if (dashboardObjectIDs.length == 0 || !companyID) {
        throw new Error("The 'dashboardObjectID' and 'companyID' parameters are required.");
    }
    let url = `/Settings/samplingrates/${companyID}`;
    dashboardObjectIDs.forEach((id, index) => {
        if (index == 0) {
            url += `?dashboardObjectIDs=${id}`
        } else {
            url += `&dashboardObjectIDs=${id}`
        }
    });
    return fetchRequest(url, "Failed to fetch node sampling rates");
};

export const setSamplingRates = async (companyID, configs) => {
    try {
        const response = await apiClient.post(`/Settings/samplingrates/${companyID}`, configs);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error setting sampling rates:", error);
        throw new Error("Error setting sampling rates:");
    }
};






