import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, Grid, IconButton, InputAdornment, Link, TextField } from "@mui/material";
import { Box } from '@mui/material';
import { Toast } from 'primereact/toast';
import React, { useContext, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CompanyInfoContext } from '../../App';
import { fetchCompanyInfo } from '../Company/CompanyQueryFunctions';
import CustomHeader from '../Generic/CustomHeader';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import { fetchVendorList, postVendor } from './IntegrationQueryFunctions';

function FormComponent() {
    const mutation = useMutation(postVendor);
    const [values, setValues] = useState({
        VendorName: '',
        Subdomain: '',
        ApplicationKey: '',
        AccessKey: '',
        SecretKey: '',
        VendorID: '',
        CompanyID: '',
        UserID: '',
    });
    const [viewPasswords, setViewPasswords] = useState({
        showPasswords: {
            ApplicationKey: false,
            AccessKey: false,
            SecretKey: false,
        },
    });
    const [errorState, setErrorState] = useState();
    const [vendor, setVendor] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isApiCredentialCreated, setIisApiCredentialCreated] = useState(false);
    const { companyID, userID, vendorID } = useParams();
    const toast = useRef(null);
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);

    const { isLoading: isCompanyLoading, error: companyError, data: companyData } =
        useQuery(["companyInfo", companyID], fetchCompanyInfo, {
            onSuccess: (data) => {
                setCompanyInfo(data);
            },
            onError: (error) => {
                // Handle the error here. For example, you can log the error or set an error state.
                setErrorState(error || "An unexpected error occurred.");
            }
        });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const { isLoading: isVendorLoading, error: vendorError, data: vendorData } =
        useQuery(["vendorList", 2], fetchVendorList, {
            onSuccess: (data) => {
                const vendor = data.find(v => v.ID == vendorID);
                setVendor(vendor);
                setValues({ ...values, ["VendorName"]: vendor.Name, ["VendorID"]: vendor.ID, ["CompanyID"]: companyID });
            },
            onError: (error) => {
                // Handle the error here. For example, you can log the error or set an error state.
                setErrorState(error || "An unexpected error occurred.");
            }
        });

    const handleClickShowPassword = (prop) => {
        setViewPasswords({
            ...values,
            showPasswords: {
                ...viewPasswords.showPasswords,
                [prop]: !viewPasswords.showPasswords[prop]
            }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaving(true);
        mutation.mutate({
            queryKey: ["postVendor", { ...values, CompanyID: companyID, UserID: userID }]
        },
            {
                onSuccess: () => {
                    // Handle success
                    setIsSaving(false);
                    setIisApiCredentialCreated(true);
                },
                onError: () => {
                    setIsSaving(false);
                    // TODO: implement better error responses in API so we can display them here.
                    const toastMessage = {
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Credentials were not accepted.',
                        life: 5000,
                        position: 'center'
                    };
                    toast.current.show(toastMessage);
                }
            });
    };
    const handleContinue = (event) => {
        const redirectUrl = new URL(document.referrer);
        redirectUrl.pathname = '/ServiceIntegration';
        redirectUrl.searchParams.set('vendorID', values['VendorID']);
        window.top.location = redirectUrl;
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Box width={500}>
                <CustomHeader headerText={`${values.VendorName} Integration`} />
                <Toast ref={toast} />
                {(isCompanyLoading || isVendorLoading || isSaving)
                    && <StatusBackdrop open={(isCompanyLoading || isVendorLoading || isSaving)} />}
                {
                    errorState &&
                    <StatusMessage
                        open={errorState}
                        severity="error"
                        location={`${values.VendorName} Integration`}
                        statusCode={errorState.request.status}
                        message={errorState.message}
                        error={errorState}
                    />
                }
                {(!isCompanyLoading && !isVendorLoading && !isApiCredentialCreated &&
                    <form onSubmit={handleSubmit} >
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <TextField required label="Subdomain" fullWidth value={values.Subdomain} onChange={handleChange('Subdomain')} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    type={viewPasswords.showPasswords.ApplicationKey ? "text" : "password"}
                                    label="Application Key"
                                    fullWidth
                                    value={values.ApplicationKey}
                                    onChange={handleChange('ApplicationKey')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleClickShowPassword('ApplicationKey')}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {viewPasswords.showPasswords.ApplicationKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    type={viewPasswords.showPasswords.AccessKey ? "text" : "password"}
                                    label="Access Key"
                                    fullWidth
                                    value={values.AccessKey}
                                    onChange={handleChange('AccessKey')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleClickShowPassword('AccessKey')}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {viewPasswords.showPasswords.AccessKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    type={viewPasswords.showPasswords.SecretKey ? "text" : "password"}
                                    label="Secret Key"
                                    fullWidth
                                    value={values.SecretKey}
                                    onChange={handleChange('SecretKey')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleClickShowPassword('SecretKey')}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {viewPasswords.showPasswords.SecretKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
                            </Grid>
                        </Grid>
                    </form>)}
                {isApiCredentialCreated && (
                    <div>
                        <h1>Credentials have been imported!</h1>
                        <br />
                        <Button variant="contained" color="success" fullWidth onClick={handleContinue}>Continue</Button>
                    </div>
                )}
            </Box>
        </Box>
    );
}

export default FormComponent;