export const alarmFormModel = {
    formID: 'alarmAdd',
    formField: {
        alarmName: {
            name: 'alarmName',
            label: 'Alarm Name',
            requiredErrorMsg: 'Required',
            invalidErrorMsg: 'Alarm Name can only be 255 characters long'
        },
        alarmSummary: {
            name: 'alarmSummary',
            label: 'Summary',
            requiredErrorMsg: 'Required'
        },
        thresholdDataTypeID: {
            name: 'thresholdDataTypeID',
            label: 'Target Data Type',
            requiredErrorMsg: 'Required'
        },
        thresholdDataTypeName: {
            name: 'thresholdDataTypeName',
            label: 'Target Data Type Name',
            //requiredErrorMsg: 'Target Data Type is required'
        },
        assignedComputedDataSourceIDs: {
            name: 'assignedComputedDataSourceIDs',
            label: 'Targets',
            requiredErrorMsg: 'Required'
        },
        isCheckedTargets: {
            name: 'isCheckedTargets',
            label: 'isCheckedTargets',
            requiredErrorMsg: 'Required'
        },
        thresholdComparisonID: {
            name: 'thresholdComparisonID',
            label: '',
            requiredErrorMsg: 'Required'
        },
        thresholdTriggerValue: {
            name: 'thresholdTriggerValue',
            label: '',
            requiredErrorMsg: 'Required',
            minErrorMsg: 'The trigger value cannot be less than -1,000,000.',
            maxErrorMsg: 'The trigger value cannot be greater than 1,000,000.'
        },
        thresholdUnitTypeID: {
            name: 'thresholdUnitTypeID',
            label: '',
            requiredErrorMsg: 'Required'
        },
        alarmSeverityID: {
            name: 'alarmSeverityID',
            label: '',
            requiredErrorMsg: 'Required'
        },
        // *** NOTIFICATIONS ARE NOT REQUIRED *** 
        sendNotification: {
            name: "sendNotification",
            label: '',
            requiredErrorMsg: 'Severity is required'
        },
        notificationID: {
            name: 'notificationID',
            label: '',
            requiredErrorMsg: 'Severity is required'
        },
        //Not implemented at this time - needs CG API work
        //notificationPeriodID: {
        //    name: 'notificationPeriodID',
        //    label: '',
        //    //requiredErrorMsg: 'Notification Frequency is required'
        //},        
        notificationRecipients: {
            name: 'notificationRecipients',
            label: '',
            requiredErrorMsg: 'Notification Frequency is required'
        },
        alarmID: {
            name: 'alarmID', 
            label: 'alarmID'
        }

    }
}

export const alarmEditFormModel = {
    formID: 'alarmEdit',
    formField: {
        alarmName: {
            name: 'alarmName',
            label: 'Alarm Name',
            requiredErrorMsg: 'Required',
            invalidErrorMsg: 'Alarm Name can only be 255 characters long'
        },
        alarmSummary: {
            name: 'alarmSummary',
            label: 'Summary',
            requiredErrorMsg: 'Required'
        },
        thresholdDataTypeID: {
            name: 'thresholdDataTypeID',
            label: 'Target Data Type',
            requiredErrorMsg: 'Required'
        },
        thresholdDataTypeName: {
            name: 'thresholdDataTypeName',
            label: 'Target Data Type Name',
            //requiredErrorMsg: 'Target Data Type is required'
        },
        assignedComputedDataSourceIDs: {
            name: 'assignedComputedDataSourceIDs',
            label: 'Targets',
            requiredErrorMsg: 'Required'
        },
        isCheckedTargets: {
            name: 'isCheckedTargets',
            label: 'isCheckedTargets',
            requiredErrorMsg: 'Required'
        },
        thresholdComparisonID: {
            name: 'thresholdComparisonID',
            label: '',
            requiredErrorMsg: 'Required'
        },
        thresholdTriggerValue: {
            name: 'thresholdTriggerValue',
            label: '',
            requiredErrorMsg: 'Required'
        },
        thresholdUnitTypeID: {
            name: 'thresholdUnitTypeID',
            label: '',
            requiredErrorMsg: 'Required'
        },
        alarmSeverityID: {
            name: 'alarmSeverityID',
            label: '',
            requiredErrorMsg: 'Required'
        },
        // *** NOTIFICATIONS ARE NOT REQUIRED *** 
        sendNotification: {
            name: "sendNotification",
            label: '',
            requiredErrorMsg: 'Required'
        },
        notificationID: {
            name: 'notificationID',
            label: '',
            //requiredErrorMsg: 'Severity is required'
        },
        //Not implemented at this time - needs CG API work
        //notificationPeriodID: {
        //    name: 'notificationPeriodID',
        //    label: '',
        //    //requiredErrorMsg: 'Notification Frequency is required'
        //},        
        notificationRecipients: {
            name: 'notificationRecipients',
            label: '',
            //requiredErrorMsg: 'Notification Frequency is required'
        },
        alarmID: {
            name: 'alarmID',
            label: 'alarmID'
        }

    }
}