import React, { useState, useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import AcctMgmtHomeUser from './AcctMgmtHomeUser';
import AcctMgmtHomeCompanies from './AcctMgmtHomeCompanies'; 
import AcctMgmtHomeNotifications from './AcctMgmtHomeNotifications';
import { styled } from '@mui/system';

const DarkDivider = styled(Divider)`
  background-color: rgba(0, 0, 0, 0.87);
`;

export default function AccountMgmtHome(props) {

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
        >
            <AcctMgmtHomeUser
                userAccount={props.userAccount}
                setCurrentTab={props.handleTabChange}
            />
            <DarkDivider orientation="vertical" flexItem />
            <AcctMgmtHomeCompanies
                userAccount={props.userAccount}
                companyID={props.companyID}
                userCompanies={props.userCompanies}
                setDefaultCompanyID={props.handleSetAsDefault}
                setCompanyID={props.handleSwitchToCompanyID}
                currentCompany={props.currentCompany}
            />
            <DarkDivider orientation="vertical" flexItem />
            <AcctMgmtHomeNotifications
                userAccount={props.userAccount}
                setCurrentTab={props.handleTabChange}
            />
        </Box>
    );
}
