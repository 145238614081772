import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AppsIcon from '@mui/icons-material/Apps';
import ListItemIcon from '@mui/material/ListItemIcon';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PreviewIcon from '@mui/icons-material/Preview';
import RssFeedIcon from '@mui/icons-material/RssFeed';



export default function DataVisMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>
            <Tooltip title="Switch To">
                <IconButton
                    aria-label="all apps menu or switch to"
                    aria-controls={open ? 'switchto-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit"
                    aria-expanded={open ? 'true' : undefined}
                    size="large">
                    <AppsIcon sx={{ width: 32, height: 32 }} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="switchto-menu"
                open={open}
                onClose={handleClose}                               
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                Switch to
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <ApartmentIcon />
                    </ListItemIcon>
                        Grace Technologies Onsite Deployments
                </MenuItem>                
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <ApartmentIcon />
                    </ListItemIcon>
                    Grace Developers
                </MenuItem>
                <Divider />
                Discover
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <PreviewIcon />
                    </ListItemIcon>
                        GracePort
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <PreviewIcon />
                    </ListItemIcon>
                    PESDs
                </MenuItem>
                <Divider />
                More
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <RssFeedIcon />
                    </ListItemIcon>
                        Grace Technologies Blog
                </MenuItem>                
            </Menu>
        </React.Fragment>
    );
}


