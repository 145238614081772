import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PositionAngle from './PositionAngle';
import BigAssetImage from './BigAssetImage';
import theme from "../../../theme";
import HelpIcon from '@mui/icons-material/Help';
import PhaseHelpModal from './PhaseHelpModal';

export default function PhaseDefect({ingredient, phaseAngleData })
{
    const [open, setOpen] = useState(false);

    let p1 = phaseAngleData.find(item => item.positionID == 1);
    let p2 = phaseAngleData.find(item => item.positionID == 2);
    let p3 = phaseAngleData.find(item => item.positionID == 3);
    let p4 = phaseAngleData.find(item => item.positionID == 4);
    let positions = [p1, p2, p3, p4];

    const renderPositionGraph = (position) => {
        return (
            <Grid item xs={4} key={position.positionID}>
                <Paper>
                    <Typography>{position.position}</Typography>
                    <PositionAngle
                        ingredient={ingredient}
                        phaseAngles={position.phaseAngles}
                    />
                </Paper>
            </Grid>
        );
    }

    const renderEmptyPosition = (position) => {
        return (
            <Grid item xs={2} key={position.positionID}>
                <Paper sx={{ height: '100%', padding: 2 }}>
                    <Grid container direction="row" sx={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <Typography>{position?.position}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Extraneous for this defect.</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const helpButtonClick = () => {
        setOpen(true);
    }

    const handleModalClose = () => {
        setOpen(false);
    }
    return (
        <div style={styles.container}>
            {/* Description that stretches across the top */}
            <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mb: 1 }}
            >
                <Grid item xs={9}>
                    <Typography align="right" variant="h5" style={styles.description}>
                        {ingredient.IngredientDescription}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <IconButton onClick={helpButtonClick} sx={{ float: 'left', ml: 2, pb: 1, color: theme.palette.primary.main }}>
                        <HelpIcon fontSize={'large'} />
                    </IconButton>
                </Grid>

            </Grid>
            {/* Stack of charts rendered below the description */}
            {phaseAngleData.length > 0 ? (< Grid
                container
                direction="row"
                alignItems="stretch"
                justifyContent="center"
                spacing={2}
            >
                {positions.map((pos) => {
                    return pos.phaseAngles.find(angle => angle.isHighlighted) !== undefined ? (renderPositionGraph(pos)) : (renderEmptyPosition(pos))
                })}
                <Grid item xs={12}>
                    <br />
                    <BigAssetImage
                        style={styles.asset}
                    />

                </Grid>
            </Grid>) : (<Typography sx={{ my: "10%" }}>No data present for this ingredient.</Typography>)}
            <PhaseHelpModal
                open={open}
                handleModalClose={handleModalClose}
            />
        </div>
    );
}

const styles = {
    container: {
        width: '100%',        // Ensure the container takes full width
        textAlign: 'center',  // Center the content
        margin: '0 auto',     // Center the container horizontally
    },
    description: {
        width: '100%',        // Make sure the description spans across the entire width
        marginBottom: '16px', // Space between the description and the charts
        paddingRight: '10%'
    },
    asset: {
        width: '85%',
        maxHeight: 350,
        margin: 'auto',
        marginTop: '1%'
    }
};
