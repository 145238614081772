import React, { useRef, useEffect } from 'react';
import { Grid, Typography, Stack } from '@mui/material';

export default function PhaseAngleDiagram({ angle, positionText, axisText, color, orientation }) {
    const canvasRef = useRef(null);

    const style = {
        transform: `rotate(${angle}deg)`,
        transformOrigin: 'center', // Rotate around the center
        transition: 'transform 500ms ease', // Smooth transition
    };

    const draw = ctx => {
        ctx.clearRect(0, 0, 80, 80); // Clear the canvas before drawing
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.arc(40, 40, 20, 0, 2 * Math.PI); // Center the circle at (40, 40)
        ctx.stroke();
        ctx.moveTo(40, 20);
        ctx.lineTo(40, 0); // Line goes to the edge of the canvas
        ctx.stroke();
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.height = 80;
        canvas.width = 80;
        draw(context);
    }, [draw]);
    return (
        <Stack direction="column" alignItems="center">
            <div style={style}>
                <canvas ref={canvasRef} />
            </div>
            <Grid container item xs="auto" direction="column" align="left">
                <Grid item xs={12}>
                    <Typography color={color}>{positionText}</Typography>
                    <Typography color={color}>{axisText}</Typography>
                </Grid>
            </Grid>
        </Stack>
    );
    
}