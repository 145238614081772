import apiClient from "../../http-common";
import { fetchRequest } from "../Generic/MiscFunctions";

export const fetchCompanyEnums = async ({ queryKey }) => {
    const [_key, companyID, viewAll] = queryKey;
    const viewAllParam = viewAll === undefined ? false : viewAll;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Company/getcompanyenums/${companyID}`, "Failed to fetch Company Enums");
};

export const fetchAlarmDashboard = async ({ queryKey }) => {
    const [_key, companyID, userID] = queryKey;
    if (!companyID || !userID) {
        throw new Error("Both 'companyID' and 'userID' parameters are required.");
    }
    return fetchRequest(`/Alarm/managed-alarms/ad/${companyID}/${userID}`, "Failed to fetch Alarm Dashboard");
};

export const fetchAlarmRecipients = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }
    return fetchRequest(`/Alarm/managed-alarms/ar/${companyID}`, "Failed to fetch Alarm Recipients");
};

export const fetchAlarmDashboardData = async ({ queryKey }) => {
    const [_key, companyID, userID] = queryKey;
    if (!companyID || !userID) {
        throw new Error("Both 'companyID' and 'userID' parameters are required.");
    }
    return fetchRequest(`/Alarm/managed-alarms/dd/${companyID}/${userID}`, "Failed to fetch Alarm Dashboard Data");
};

export const fetchAlarmNodeChannels = async ({ queryKey }) => {
    const [_key, companyID, dataTypeID] = queryKey;
    if (!companyID || !dataTypeID) {
        return [{
            "NodeChannelDisplayName": "",
            "PrimaryDataSourceID": null,
            "NodeLocation": "",
            "NodeDisplayName": "",
            "ProductionReadableSN": "",
            "DataTypeID": null,
            "DataTypeDisplayName": ""
        }];
    }
    return fetchRequest(`/Alarm/managed-alarms/ncdt/${companyID}/${dataTypeID}`, "Failed to fetch Alarm Node Channels");
};

export const fetchAlarmAssignments = async ({ queryKey }) => {
    const [_key, alarmID] = queryKey;
    if (!alarmID) {
        throw new Error("The 'alarmID' parameter is required and cannot be null or empty.");
    }
    return fetchRequest(`/Alarm/managed-alarms/assignments/${alarmID}`, "Failed to fetch Alarm Assignments");
};

export const fetchAlarm = async ({ queryKey }) => {
    const [_key, alarmID, viewAllParam] = queryKey;
    let viewAll = viewAllParam === undefined ? false : viewAllParam;

    if (!alarmID) {
        return {
            "AlarmDetails": {
                "AlarmID": null,
                "CompanyID": null,
                "DisplayName": null,
                "SystemStatusID": null,
                "Summary": null,
                "NotificationID": null,
                "AlarmNotificationPeriodID": null
            },
            "AlarmThresholds": [],
            "AlarmRecipients": [],
            "AlarmInfoNodes": []
        };
    }
    return fetchRequest(`/Alarm/managed-alarms/${alarmID}/${viewAll}`, "Failed to fetch Alarm ");
};

export const fetchStdAlarmDetailsFromURL = async ({ queryKey }) => {
    const [_key, urlFragment] = queryKey;

    if (!urlFragment) {
        throw new Error("The 'urlFragment' parameter is required and cannot be null or empty.");
    }
    return fetchRequest(`/Alarm/managed-alarms/alarm-link-content/${urlFragment}`,
        "Failed to fetch Standard Alarm details from URL");
};

export const fetchVIBAlarmDetailsFromURL = async ({ queryKey }) => {
    const [_key, urlFragment] = queryKey;

    if (!urlFragment) {
        throw new Error("The 'urlFragment' parameter is required and cannot be null or empty.");
    }
    return fetchRequest(`/Alarm/managed-alarms/alarm-link-content/vib/${urlFragment}`,
        "Failed to fetch Vibration Alarm details from URL");
};

export const fetchStdAlarmDetailsForView = async ({ queryKey }) => {
    const [_key, computedDataSourceID, alarmID, companyID, userID] = queryKey;

    if (!computedDataSourceID || !alarmID) {
        throw new Error("The 'computedDataSourceID' and 'alarmID' parameters are " +
            "required and cannot be null or empty.");
    }
    return fetchRequest(`/Alarm/managed-alarms/view/${computedDataSourceID}/${alarmID}/${companyID}/${userID}`,
        "Failed to fetch Standard Alarm details for View");
};

export const fetchVIBAlarmDetailsForView = async ({ queryKey }) => {
    const [_key, computedDataSourceID, alarmID, companyID, userID] = queryKey;

    if (!computedDataSourceID || !alarmID) {
        throw new Error("The 'computedDataSourceID' and 'alarmID' parameters are " +
            "required and cannot be null or empty.");
    }
    return fetchRequest(`/Alarm/managed-alarms/view/vib/${computedDataSourceID}/${alarmID}/${companyID}/${userID}`,
        "Failed to fetch Vibration Alarm details for View");
};

export const deleteAlarmFromAll = async ({ alarmID, userID }) => {
    try {
        const response = await apiClient.delete(`/Alarm/managed-alarms/${userID}/${alarmID}`);
        return response.data;
    } catch (error) {
        console.error("Error in deleteAlarmFromAll:", error.response || error);
        throw new Error(`Error deleting alarm from all: ${error.message}`);
    }
};

export const deleteAlarmFromComputedDataSourceID = async ({ computedDataSourceID, alarmID, userID }) => {
    try {
        const response = await apiClient.delete(`/Alarm/managed-alarms/${alarmID}/${userID}/${computedDataSourceID}`);
        //console.log("DELETE ONE: " + JSON.stringify(response));
        return response.data;
    } catch (error) {
        console.error("Error in deleteAlarmFromComputedDataSourceID:", error.response || error);
        throw error; 
    }
};

export const postAlarm = async (data) => {
    try {
        const response = await apiClient.post(`/Alarm/managed-alarms`, data);
        return response;
    } catch (error) {
        throw new Error(`Error posting alarm: ${error.message}`);
    }
};

export const updateAlarm = async (data) => {
    try {
        const response = await apiClient.patch(`/Alarm/managed-alarms/${data.AlarmID}`, data);
        return response;
    } catch (error) {
        throw new Error(`Error updating alarm: ${error.message}`);
    }
};