import React from 'react';
import { Typography, Stack, Modal, Button, IconButton, Paper, Grid, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


export default function DefectHelpModal({ open, handleModalClose }) {
    return (
        <Modal
            open={open}
        >
            <Paper className="modal-paper">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                        <IconButton sx={{ float: "right" }} onClick={handleModalClose}>
                            <ClearIcon fontSize={"large"} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Typography variant="h6" align="center">The Advanced Defect Classification selection will enable all sensor channels we need to
                    provide the best defect classification results possible. This is the recommended setting in most cases, but
                    non-Advanced settings can be used to improve battery life, minimize RF requirements, and increase the number of
                    nodes that can provide data to a PLC through a single gateway.</Typography>
                <br />
            </Paper>
        </Modal>
    )
}