import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import UpdateIcon from '@mui/icons-material/Update';


export default function HardwareSettingsMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>
        
                <Button
                    onClick={handleClick}
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'hwSettings-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    color="inherit"
                >
                    Hardware 
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>               

            <Menu
                anchorEl={anchorEl}
                id="hwSettings-menu"
                open={open}
                onClose={handleClose}                
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    Node Settings
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <BuildIcon />
                    </ListItemIcon>
                        ControlGate Configuration
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <UpdateIcon />
                    </ListItemIcon>
                        Firmware Updates
                </MenuItem>                
            </Menu>
        </React.Fragment>
    );
}


