import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function StatusBackdrop(props) {

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: 10 }}
                open={props.open}
            >
                <CircularProgress color="primary" size={ 50 }/>
            </Backdrop>
        </div>
    );
}
