import { Typography, Stack, Modal, Button, IconButton, Paper, Grid, Tooltip, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PeriodicTable from '../images/PeriodicTable.png'
import Element from '../images/Element.png'

const helpText = [
    {
        title: "How do I Interpret the Vibration Element Table?",
        text: <Stack>
            <Typography align="center">The Vibration Element Table displays <strong>Defects (Elements)</strong> that our system
                can detect. It groups them by <strong>Frequency</strong> using the Frequency Headers at the top. </Typography>
            <img
                src={Element}
                alt={'Element'}
                style={{
                    width: '9%',
                    margin: 'auto',
                    marginTop: 10,
                    marginBottom: 5,
                }}
            />
        </Stack>
    },
    {
        title: "What Information is Displayed on the Vibration Element Tile?",
        text: <Stack>
            <Typography align="center">Top Left Corner: <strong>Frequency</strong> (Options: Synchronous (S), Sub-Synchronous (Ss),
                Harmonic (H), Non-Synchronous (Ns))</Typography>
            <Typography align="center">Bottom Left Corner: <strong>Direction</strong> (Options: Radial (R), Axial (A), Radial
                or Axial (R/A))</Typography>
            <Typography align="center">Top Left Corner: <strong>Isolation Method</strong> (Options: Phase, Time Waveform, Ultrasonic (Ux),
                Transient, Orbit)</Typography>
        </Stack>
    },
]

export default function PeriodicTableHelpModal({ open, handleModalClose }) {
    return (
        <Modal
            open={open}
        >
            <Paper className="help-modal">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                        <IconButton sx={{ float: "right" }} onClick={handleModalClose}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Stack alignItems="center">
                    {<img
                        src={PeriodicTable}
                        alt={'Periodic Table'}
                        style={{
                            width: '40%'
                        }}
                    />}
                    {helpText.map((block, index) => {
                        return (
                            <Box key={index} sx={{ my: 0.5 }}>
                                <Typography variant="body1" fontWeight="bold" align="center">{block.title}</Typography>
                                {block.text}
                            </Box>
                        )
                    })}
                </Stack>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Button variant="contained" sx={{ mx: '25%', width: '50%' }} onClick={handleModalClose}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}