import React from "react";
import FieldHelperText from '../Generic/FieldHelperText';
import { useField, ErrorMessage } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import {FormControl, Box, FormLabel} from '@mui/material';

export default function NumericInputField(props) {
    const { ...rest } = props;
    const [field, meta] = useField(props);
    const userLocale = navigator.language;

    const handleNumericOnChange = (e) => {
        if (props.preventempty == "true" && (e == null || e.value == undefined || e.value == null)) {
            return;
        } else {
            // Check if the value is a valid number
            if (e.value === '-' || e.value === '' || e == null || e.value == undefined || e.value == null) {
                // If the value is a negative sign, set it as the field value
                props.form?.setFieldValue(field.name, e.value);
            }
            else if (!isNaN(e.value)) {
                const newValue = e.value.toFixed(props.digits); // round to 2 decimal points
                props.form?.setFieldValue(field.name, newValue); // update formik field value
            }
        }
    };

    return ( 
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: props.width,
                    maxWidth: '100%',
                }}
            >
                <FormControl {...rest} fullWidth>
                    <FormLabel id={"inputNumber-label-" + field.name}>{props.label}</FormLabel>
                        <InputNumber
                        {...field}
                        inputId={field.name}
                        id={field.name}
                        name={field.name}
                        value={field.value}
                        step={props.step}
                        min={-1000000}
                        max={1000000}
                        showButtons
                        size={1}
                        onChange={handleNumericOnChange}
                        locale={userLocale}
                        minFractionDigits={props.digits}
                        buttonLayout="stacked"
                        style={{ height: '60px' }}
                        mode="decimal"
                        onFocus={(e) => e.target.select()}
                        className={meta.touched && Boolean(meta.error) ? 'p-invalid' : ''}
                        placeholder={props.placeholder}
                    />                    
                </FormControl>
                {meta.touched && meta.error &&
                    <ErrorMessage name={field.name}>
                        {msg => <FieldHelperText message={msg} />}
                    </ErrorMessage>
                }
            </Box>
        </Box>
    )
}


