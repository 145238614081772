import React, { useState } from 'react';
import { Modal, Button, Box, Grid } from "@mui/material";
import { VibrationAnalysisType } from "./VibrationAnalysisType";
import CheckboxTableRow from "../Generic/CheckboxTableRow";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
};



export default function VibrationBandConfigure(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    return (
        <React.Fragment>
            <Button fullWidth onClick={handleModalOpen} variant="contained">
                {props.analysisType == VibrationAnalysisType.AdvancedVBT ? "Configure Bands" : "View Bands"}
            </Button>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={0} columns={12}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={1}>OALL</Grid>
                        <Grid item xs={1}>SUB</Grid>
                        <Grid item xs={1}>1x</Grid>
                        <Grid item xs={1}>2x</Grid>
                        <Grid item xs={1}>3x</Grid>
                        <Grid item xs={1}>BRG</Grid>
                        <Grid item xs={1}>HLo</Grid>
                        <Grid item xs={1}>HHi</Grid>
                    </Grid>
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.x_amplitude} enableChange={props.enableChange} />
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.y_amplitude} enableChange={props.enableChange} />
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.z_amplitude} enableChange={props.enableChange} />
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.x_frequency} enableChange={props.enableChange} />
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.y_frequency} enableChange={props.enableChange} />
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.z_frequency} enableChange={props.enableChange} />
                    <Grid container spacing={0} columns={12} sx={{ marginTop: '4%' }}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={1}>1x</Grid>
                        <Grid item xs={1}>2x</Grid>
                        <Grid item xs={1}>3x</Grid>
                    </Grid>
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.x_analysis} enableChange={props.enableChange} />
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.y_analysis} enableChange={props.enableChange} />
                    <CheckboxTableRow checkboxRowInfo={props.enableFlags.z_analysis} enableChange={props.enableChange} />
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={8} />

                        {/* Save Button Grid Item */}
                        <Grid item xs={2}>
                            <Button fullWidth onClick={handleModalClose} variant="contained">Save</Button>
                        </Grid>

                        {/* Cancel Button Grid Item */}
                        <Grid item xs={2}>
                            <Button fullWidth onClick={handleModalClose} variant="contained">Cancel</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </React.Fragment>
    )
}