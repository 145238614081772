import apiClient from "../../http-common";
import fileDownload from 'js-file-download';

export const fetchControlGateTree = async ({ queryKey }) => {
    const [_key, dashboardID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!dashboardID) {
        throw new Error("The 'dashboardID' parameters are required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Control/getcontrolgatetree/${dashboardID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch control gate tree after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const fetchDashboardList = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameters are required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Control/getdashboardlist/${companyID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch control gate tree after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const fetchDashboardTree = async ({ queryKey }) => {
    const [_key, dashboardID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!dashboardID) {
        throw new Error("The 'dashboardID' parameters are required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Dashboard/getdashboardtree/${dashboardID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch control gate tree after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const fetchDocumentList = async ({ queryKey }) => {
    const [_key] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Control/listdocuments`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch control gate tree after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const removeParent = async (childObjectID, companyID) => {
    try {
        const response = await apiClient.post(`/Node/removeparent/${childObjectID}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error removing parent:", error);
        throw new Error("Error removing parent:");
    }
};

export const setNewParent = async (childObjectID, parentObjectID, companyID) => {
    try {
        const response = await apiClient.post(`/Node/setnewparent/${childObjectID}/${parentObjectID}/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error setting new parent:", error);
        throw new Error("Error setting new parent:");
    }
};

export const downloadL5X = async (dashboardObjectID, displayName, companyID) => {
    try {
        await apiClient.get(`/Control/getl5xcontent/${dashboardObjectID}/${companyID}`)
            .then(response => {
                if (response.data.type != "application/json") {
                    let blob = new Blob([response.data], { type: 'application/xml' });
                    fileDownload(blob, `${displayName}.l5x`);
                }
            })
            .catch(error => console.error('Error downloading the file:', error));
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error downloading the file:", error);
        throw new Error("Error downloading the file");
    }
}

export const downloadAOP = async (dashboardObjectID, displayName, companyID) => {
    try {
        await apiClient.get(`/Control/getaopcontent/${dashboardObjectID}/${companyID}`)
            .then(response => {
                if (response.data.type != "application/json") {
                    let blob = new Blob([response.data], { type: 'application/xml' });
                    fileDownload(blob, `${displayName}.xml`);
                }
            })
            .catch(error => console.error('Error downloading the file:', error));
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error downloading the file:", error);
        throw new Error("Error downloading the file");
    }
}

export const downloadMemoryMap = async (dashboardObjectID, displayName, companyID) => {
    try {
        await apiClient.get(`/Control/getmemorymap/${dashboardObjectID}/${companyID}`)
            .then(response => {
                if (response.data.type != "application/json") {
                    let blob = new Blob([response.data], { type: 'application/csv' });
                    fileDownload(blob, `${displayName}.csv`);
                }
            })
            .catch(error => console.error('Error downloading the file:', error));
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error downloading the file:", error);
        throw new Error("Error downloading the file");
    }
}

export const downloadDocument = async (fileName) => {
    try {
        if (fileName.includes(".redirect")) {
            await apiClient.get(`/Control/downloaddocument/${fileName}`)
                .then(response => {
                    if (response.data.type != "application/json") {
                        window.open(response.data)
                    }
                })
                .catch(error => console.error('Error downloading the file:', error));
            
        }
        else {
            await apiClient.get(`/Control/downloaddocument/${fileName}`, { responseType: 'arraybuffer' })
                .then(response => {
                    if (response.data.type != "application/json") {
                        let blob = new Blob([response.data], { type: 'application/octet-binary' });
                        fileDownload(blob, fileName);
                    }
                })
                .catch(error => console.error('Error downloading the file:', error));
        }
        
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error downloading the file:", error);
        throw new Error("Error downloading the file");
    }
}