import { IconButton } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export default function ColorChangeIconButton({ isSelected, onClick, ariaLabel, Icon, selectedColor, defaultColor, disabled })
{
    const color = isSelected ? selectedColor : defaultColor;
    return (
        <IconButton
            aria-label={ariaLabel}
            onClick={onClick}
            color={color}
            disabled={disabled}
        >
            {isSelected && !disabled && (
                <Icon />
            )}
            {!isSelected && !disabled && (
                <RadioButtonUncheckedIcon />
            )}
        </IconButton>
    );
};
