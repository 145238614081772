import { useState } from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ScienceIcon from "@mui/icons-material/Science";

import ForemanChatModal from "./ForemanChatModal";

export default function ForemanChatInitiatorCard(props) {
    const [chatOpen, setChatOpen] = useState(false);

    const handleOpen = () => {
        setChatOpen(true);
    };

    const handleClose = () => {
        setChatOpen(false);
    };

    return (
        <div>
            <Card
                variant="outlined"
                sx={{
                    height: "100%",
                    width: 285,
                    bgcolor: "#eee", // Very light grey
                }}
            >
                <CardContent style={{ paddingBottom: 8 }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="strong" color="text.secondary" style={{ fontSize: "1.215em" }}>
                            <ScienceIcon sx={{ mr: 0.2 }} />
                            <strong>Maintenance Lab</strong>
                        </Typography>
                        <Typography color="primary">ALPHA</Typography>
                    </div>
                    <Divider sx={{ mt: 0.2, mb: 1, bgcolor: "gray" }} />
                    <Typography variant="h5">
                        <strong>ForemanGPT</strong>
                    </Typography>
                    <Typography>ForemanGPT aids with in-depth investigations to uncover and resolve problems.</Typography>
                </CardContent>
                <CardActions>
                    <Button size="medium" onClick={handleOpen}>
                        <Typography>Start chat</Typography>
                    </Button>
                </CardActions>
            </Card>

            {/* Chat modal*/}
            <ForemanChatModal chatOpen={chatOpen} handleClose={handleClose} recipeStateID={props.recipeStateID} />
        </div>
    );
}
