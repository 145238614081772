export const VBTxConfigurationFormModel = {
    formID: 'VBTxConfiguration',
    formField: {
        assetName: {
            name: 'assetName',
            label: 'Asset Name',
            requiredErrorMsg: 'Required',
            invalidErrorMsg: 'Asset Name can only be 255 characters long'
        },
        machineType: {
            name: 'machineType',
            label: 'Machine Type',
            requiredErrorMsg: 'Required'
        },
        driveType: {
            name: 'driveType',
            label: 'Drive Type',
            requiredErrorMsg: 'Required'
        },
        p1AssignedNode: {
            name: 'p1AssignedNode',
            label: 'P1 Assigned Node',
            nodeID: {
                name: 'p1AssignedNode.nodeID'
            },
            axisAlignment: {
                name: 'p1AssignedNode.axisAlignment'
            },
            shaftSpeedSource: {
                name: 'p1AssignedNode.shaftSpeedSource'
            },
            xEnabled: {
                name: 'p1AssignedNode.xEnabled'
            },
            yEnabled: {
                name: 'p1AssignedNode.yEnabled'
            },
            zEnabled: {
                name: 'p1AssignedNode.zEnabled'
            },
            warningThreshold: {
                name: 'p1AssignedNode.warningThreshold'
            },
            dangerThreshold: {
                name: 'p1AssignedNode.dangerThreshold'
            },
            warningRecipientIDs: {
                name: 'p1AssignedNode.warningRecipientIDs'
            },
            dangerRecipientIDs: {
                name: 'p1AssignedNode.dangerRecipientIDs'
            },
        },
        p2AssignedNode: {
            name: 'p2AssignedNode',
            label: 'P2 Assigned Node',
            nodeID: {
                name: 'p2AssignedNode.nodeID'
            },
            axisAlignment: {
                name: 'p2AssignedNode.axisAlignment'
            },
            shaftSpeedSource: {
                name: 'p2AssignedNode.shaftSpeedSource'
            },
            xEnabled: {
                name: 'p2AssignedNode.xEnabled'
            },
            yEnabled: {
                name: 'p2AssignedNode.yEnabled'
            },
            zEnabled: {
                name: 'p2AssignedNode.zEnabled'
            },
            warningThreshold: {
                name: 'p2AssignedNode.warningThreshold'
            },
            dangerThreshold: {
                name: 'p2AssignedNode.dangerThreshold'
            },
            warningRecipientIDs: {
                name: 'p2AssignedNode.warningRecipientIDs'
            },
            dangerRecipientIDs: {
                name: 'p2AssignedNode.dangerRecipientIDs'
            },
        },
        p3AssignedNode: {
            name: 'p3AssignedNode',
            label: 'P3 Assigned Node',
            nodeID: {
                name: 'p3AssignedNode.nodeID'
            },
            axisAlignment: {
                name: 'p3AssignedNode.axisAlignment'
            },
            shaftSpeedSource: {
                name: 'p3AssignedNode.shaftSpeedSource'
            },
            xEnabled: {
                name: 'p3AssignedNode.xEnabled'
            },
            yEnabled: {
                name: 'p3AssignedNode.yEnabled'
            },
            zEnabled: {
                name: 'p3AssignedNode.zEnabled'
            },
            warningThreshold: {
                name: 'p3AssignedNode.warningThreshold'
            },
            dangerThreshold: {
                name: 'p3AssignedNode.dangerThreshold'
            },
            warningRecipientIDs: {
                name: 'p3AssignedNode.warningRecipientIDs'
            },
            dangerRecipientIDs: {
                name: 'p3AssignedNode.dangerRecipientIDs'
            },
        },
        p4AssignedNode: {
            name: 'p4AssignedNode',
            label: 'P4 Assigned Node',
            nodeID: {
                name: 'p4AssignedNode.nodeID'
            },
            axisAlignment: {
                name: 'p4AssignedNode.axisAlignment'
            },
            shaftSpeedSource: {
                name: 'p4AssignedNode.shaftSpeedSource'
            },
            xEnabled: {
                name: 'p4AssignedNode.xEnabled'
            },
            yEnabled: {
                name: 'p4AssignedNode.yEnabled'
            },
            zEnabled: {
                name: 'p4AssignedNode.zEnabled'
            },
            warningThreshold: {
                name: 'p4AssignedNode.warningThreshold'
            },
            dangerThreshold: {
                name: 'p4AssignedNode.dangerThreshold'
            },
            warningRecipientIDs: {
                name: 'p4AssignedNode.warningRecipientIDs'
            },
            dangerRecipientIDs: {
                name: 'p4AssignedNode.dangerRecipientIDs'
            },
        },
        gearboxLocation: {
            name: 'gearboxLocation',
            label: 'Gear Box Location',
            requiredErrorMsg: 'Required'
        },
        bladeCount: {
            name: 'bladeCount',
            label: 'Blade Count',
            requiredErrorMsg: 'Required',
            minErrorMsg: 'Blade Count cannot be lower than 0',
            maxErrorMsg: 'Blade Count cannot be greater than 255',
        },
        inputToothCount: {
            name: 'inputToothCount',
            label: 'Input Gear Tooth Count',
            requiredErrorMsg: 'Required',
            minErrorMsg: 'Input Tooth Count cannot be lower than 0',
            maxErrorMsg: 'Input Tooth Count cannot be greater than 255',
        },
        outputToothCount: {
            name: 'outputToothCount',
            label: 'Output Gear Tooth Count',
            requiredErrorMsg: 'Required',
            minErrorMsg: 'Output Tooth Count cannot be lower than 0',
            maxErrorMsg: 'Output Tooth Count cannot be greater than 255',
        },
        shaftSpeed: {
            name: 'shaftSpeed',
            label: 'Shaft Speed',
            requiredErrorMsg: 'Required',
            minErrorMsg: 'Shaft speed cannot be lower than 600',
            maxErrorMsg: 'Shaft speed cannot be greater than 5400.'
        },
        shaftSpeedHiBound: {
            name: 'shaftSpeedHiBound',
            label: 'Shaft Speed Upper Bound',
            requiredErrorMsg: 'Required',
            minErrorMsg: 'The upper bound cannot be lower than 600.',
            maxErrorMsg: 'The upper bound cannot be greater than 5400.'
        },
        shaftSpeedLoBound: {
            name: 'shaftSpeedLoBound',
            label: 'Shaft Speed Lower Bound',
            requiredErrorMsg: 'Required',
            minErrorMsg: 'The lower bound cannot be lower than 600.',
            maxErrorMsg: 'The lower bound cannot be greater than 5400.'
        },
        vibrationSamplingPeriod: {
            name: 'vibrationSamplingPeriod',
            label: 'Vibration Sampling Period',
            requiredErrorMsg: 'Required'
        },
        temperatureSamplingPeriod: {
            name: 'temperatureSamplingPeriod',
            label: 'temperature Sampling Period',
            requiredErrorMsg: 'Required'
        },
        triggerSource: {
            name: 'triggerSource',
            label: 'Vibration Analysis Trigger Source',
            requiredErrorMsg: 'Required'
        },
        threshold: {
            name: 'threshold',
            label: 'Trigger Threshold',
            requiredErrorMsg: 'Required'
        },
        maxTriggers: {
            name: 'maxTriggers',
            label: 'Maximum Triggers Per Period',
            requiredErrorMsg: 'Required'
        },
        defectLevel: {
            name: 'defectLevel',
            label: 'Desired Defect Identification Level',
            requiredErrorMsg: 'Required'
        },    
        maximizeNodes: {
            name: 'maximizeNodes',
            label: 'Maximized Nodes per ControlGate',
            requiredErrorMsg: 'Required'
        },    
    }
}