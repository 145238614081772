import React from 'react';
import { Paper, Modal, Table, TableBody, TableContainer, Button, Box, Grid, Typography, Divider, IconButton} from '@mui/material';
import DiagnosticRow from './DiagnosticRow';
import ClearIcon from '@mui/icons-material/Clear';


export default function DiagnosticsModal(props) {
    return (
        <Modal
            open={props.diagnosticsModalOpen}
        >
            <Paper className="modal-paper">
                <Grid container direction="row">
                    <Grid item xs={10}>
                        <Typography variant="h5">Diagnostics</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton sx={{ float: "right" }} onClick={props.handleDiagnosticModalClose}>
                            <ClearIcon fontSize={"large"} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <TableContainer>
                    <Table>
                        <TableBody>
                            {props.node?.Children.map((child) => {
                                return (
                                    <DiagnosticRow
                                        key={child.ObjectID}
                                        ObjectID={child.ObjectID}
                                        DisplayName={child.DisplayName}
                                        IsEnabled={child.IsEnabled}
                                        handleNameChange={props.handleNameChange}
                                        handleCheckboxChange={props.handleCheckboxChange}
                                        handleNameSave={props.handleNameSave}
                                    />
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    <Button variant="contained" align="left" onClick={props.handleDiagnosticModalClose}>
                        Close
                    </Button>
                </Box>
                
            </Paper>
        </Modal>
    )
}