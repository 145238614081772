import React from 'react';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import {
    Alert,
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Button
} from '@mui/material';

export default function AcctMgmtHomeNotifications(props) {
    const findActiveInvitations = () => {
        return props.userInvitations?.filter(invite => invite.AcceptedOn === null && invite.DeclinedOn === null);
    }

    const renderInvitationAlert = () => {
        const activeInvitations = findActiveInvitations();
        const invitationCount = activeInvitations?.length || 0;
        const isPlural = invitationCount > 1;

        if (invitationCount === 0) {
            return (
                <Alert severity="info">
                    No new notifications at this time.
                </Alert>
            );
        } else {
            return (
                <Alert severity="info">
                    You have {isPlural ? "multiple invitations" : "an invitation"} pending.
                    Click {<Button size="small" onClick={() => props.setCurrentTab(3)}>here</Button>},
                    or navigate to the <b> Company Invitations </b> tab above to respond.
                </Alert>
            );
        }
    }

    return (
        <Card sx={{ minWidth: 275, boxShadow: 0 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main' }} aria-label="notifications">
                        <MarkEmailUnreadOutlinedIcon />
                    </Avatar>
                }
                title={'Notifications'}
                titleTypographyProps={{ variant: "h5", component: "div" }}
            />
            <CardContent>
                <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
                    {renderInvitationAlert()}
                </Box>
            </CardContent>
        </Card>
    );
}
