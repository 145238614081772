import React, { useState } from 'react';
import { Typography, Grid, Paper, Button, Box, Modal, Divider } from '@mui/material';
import EditCompanyModal from './EditCompanyModal';

let hcdashboards = [
    {
        DashboardName: '',
        Admins: [
            {
                ID: "",
                DisplayName: ""
            }
        ]
    }
]
export default function CompanyHome(props) {
    const [modalDashboard, setModalDashboard] = useState(hcdashboards[0]);
    const [adminModalOpen, setAdminModalOpen] = useState(false);
    

    let buttonRight = {
        float: "right"
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '480px',
        overflow: 'auto',
        display:'block'
    };

    const handleViewAdminClick = (event, dash) => {
        setModalDashboard(dash);
        setAdminModalOpen(true);
    }

    const handleCloseViewAdminClick = (event) => {
        setAdminModalOpen(false);
    }

    return (
        <Paper
            elevation={2}
            sx={{
                mx: 'auto',
                width: '50%'
            }}
        >
            <Grid container
                direction="column"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">{props.dashboards.CompanyName}</Typography>
                </Grid>
                <Grid container item xs={12} direction="row" justifyContent="center" spacing={1}>
                    <Grid item xs={3}>
                        <Typography><b>Primary Admin</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography align="right">{props.dashboards.AdminUser.DisplayName}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Dashboards</Typography>
                </Grid>
                {props.dashboards.Dashboards.map((dash, index) => {return(
                    <Grid container item xs={12} direction="row" justifyContent="center" spacing={1} key={index}>
                        <Grid item xs={8}>
                            <Typography><b>{dash.DashboardName}</b></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="outlined" sx={buttonRight} onClick={(e) => { handleViewAdminClick(e, dash) }} key={dash.DashboardName}>View Admin List</Button>
                        </Grid>
                    </Grid>
                )
                })}
                <Modal
                    open={adminModalOpen}
                >
                    <Paper sx={modalStyle}>
                        <Typography variant="h6">{"Admins for " + modalDashboard.DashboardName}</Typography>
                        <Divider />
                        <br />
                        {modalDashboard.Admins.map((admin, index) => {
                            return (
                                <Box key={index}>
                                    <Typography sx={{p: "6px"}}>{admin.DisplayName}</Typography>
                                    <Divider variant="middle" />
                                </Box>
                            )
                        })}
                        <br />
                        <Button variant="contained" onClick={handleCloseViewAdminClick}>Close</Button>
                    </Paper>           
                </Modal>
                <br />
                <Grid container item xs={12} direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <Box textAlign='center'>
                            <Button variant="contained" onClick={props.handleCreateCompanyClick}>New Company</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box textAlign='center'>
                            <Button variant="contained" onClick={props.handleEditCompanyClick}>Edit Company</Button>
                        </Box>
                    </Grid>
                </Grid>
                <EditCompanyModal
                    editCompanyModalOpen={props.editCompanyModalOpen}
                    modalStyle={modalStyle}
                    save={props.isEditing}
                    saveLabel={props.isEditing ? "Save" : "Create"}
                    companyName={props.companyName}
                    timeZone={props.timeZone}
                    timeZonesList={props.timeZonesList}

                    onTimeZoneChange={props.onTimeZoneChange}
                    handleTimeZoneChange={props.onTimeZoneChange}
                    handleCompanyNameChange={props.handleCompanyNameChange}
                    handleCloseClick={props.handleCloseEditModalClick}
                    handleSaveClick={props.handleSaveClick}
                />
                <br />
            </Grid>
        </Paper>
        
    )
}