import React from 'react';
import { Grid, Checkbox } from "@mui/material";


export default function CheckboxTableRow(props) {
    return (
        <Grid container spacing={0} columns={12}>
            <Grid item xs={4}>{props.checkboxRowInfo.rowTitle}</Grid>
            {props.checkboxRowInfo.checkboxInfo.map((item, index) => {
                return (
                    <Grid key={"CheckBoxGrid" + props.checkboxRowInfo.rowTitle + index} item xs={1}>
                        <Checkbox
                            checked={item.checked}
                            onChange={event => props.checkboxRowInfo.handleChange(event, index)}
                            disabled={!props.enableChange}
                        />
                    </Grid>
                )
            })}
        </Grid>
    );
}