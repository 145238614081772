import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ModalMenuItem from './ModalMenuItem';


export default function IconModalMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Tooltip title={props.menuTitle} >
                <IconButton
                    aria-label={props.MenuTitle}
                    onClick={handleClick}
                    color="inherit"
                    size="large">
                    {props.menuIcon} 
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id={props.MenuTitle + "-menu"}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                style={{padding:'10px'} }
            >
                {props.menuPreamble}
                {props.menuItems.map(({ text, icon }, index) => {
                    return (
                        <ModalMenuItem
                            menuIcon={icon}
                            menuText={text}
                            handleModalOpen={props.handleModalOpen}
                            key={index}
                        />
                    );
                })}
            </Menu>
        </React.Fragment>
    )
}