import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function AmplitudeDefectDominant({ ingredient, seriesData, max }) {
    const handleColumnClick = (dashboardDataID) => {
        const redirectUrl = new URL(document.referrer);
        redirectUrl.pathname = '/Data';
        redirectUrl.searchParams.set('NodeChannelIDs', dashboardDataID);
        window.top.location = redirectUrl;
    };

    // Function to format threshold data as a step line
    const formatThresholdSeries = (data, attr) => {
        let thresholdSeries = [];
        let index = 0;
        data.forEach(d => {
            thresholdSeries.push([index - 0.5, d[attr]]);
            index++;
        });
        if (thresholdSeries.length > 0) {
            thresholdSeries[0][0] += 0.01;
            thresholdSeries.push([thresholdSeries[thresholdSeries.length - 1][0] + 0.99, thresholdSeries[thresholdSeries.length - 1][1]]);
        }
        return thresholdSeries;
    };

    const categories = seriesData.map(item => item.x); // Using `x` from the seriesData for categories

    const chartOptions = {
        chart: {
            type: 'column',
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories,
            labels: {
                autoRotationLimit: 40,
            },
        },
        yAxis: {
            title: {
                text: `Vibration Amplitude (${ingredient.UnitTypeSymbol || 'ips'})`,
                style: {
                    fontSize: 14
                }
            },
            max: max
        },
        legend: {
            enabled: false, // Enable the legend           
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${this.point.y.toFixed(3)} (${ingredient?.PrimaryComputedData.UnitTypeSymbol})<br/> ${this.point.channelDisplayName}`;
            }
        },
        plotOptions: {
            column: {
                grouping: false, // Ensures that columns don't overlap if there are multiple series
                pointPadding: 0.2, // Padding between columns
                groupPadding: 0.1, // Padding between groups of columns
                point: {
                    events: {
                        click: function () {
                            handleColumnClick(this.options.dashboardDataID);
                        }
                    }
                }
            },
            line: {
                step: 'left' // Ensure the line is stepped
            }
        },
        series: [
            ...seriesData.map((item, index) => ({
                type: 'column',
                name: item.x,    // Use the category name as the series name for legend
                data: [{
                    y: item.y,                 // The data point value
                    color: item.color,         // Use the specified color
                    x: index,
                    name: item.x,
                    channelDisplayName: item.channelDisplayName,  // Add the channelDisplayName to the point data
                    dashboardDataID: item.dashboardDataID
                }],
                showInLegend: true,
                enableMouseTracking: true
            })),
            {
                type: 'line',  // Set the type to 'line' for the warning threshold
                name: 'warning', // Customize the name for the legend
                data: formatThresholdSeries(seriesData, "warningAlarmLevel"),
                color: '#F0AD4E',
                step: 'left', // Step line series for threshold
                showInLegend: false,
                marker: {
                    enabled: false
                },
                enableMouseTracking: false
            },
            {
                type: 'line',  // Set the type to 'line' for the warning threshold
                name: 'danger', // Customize the name for the legend
                data: formatThresholdSeries(seriesData, "dangerAlarmLevel"),
                color: '#B30101',
                step: 'left', // Step line series for threshold
                showInLegend: false,
                marker: {
                    enabled: false
                },
                enableMouseTracking: false
            }
        ],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    }
                }
            }]
        }
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    );
}
