import { fetchRequest } from "../Generic/MiscFunctions";

//FETCH
export const fetchUserInfo = async ({ queryKey }) => {
    const [_key, companyID, userID] = queryKey;

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/User/getuserinfo/${companyID}/${userID}`,
        `Failed to fetch User Info: 
            companyID: ${companyID} 
            userID: ${userID}`);
};

export const fetchApiSubscription = async ({ queryKey }) => {
    const [_key, companyID, email] = queryKey;

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/APIManagement/search?CompanyID=${companyID}&Email=${encodeURIComponent(email)}`,
        `Failed to fetch API Subscription: 
            companyID: ${companyID} 
            email: ${email}`);
};

//POST
export const postApiSubscription = async ({ queryKey }) => {
    const [_key, body] = queryKey;
    return fetchRequest(`/APIManagement/register`,
        `Failed to register API Subscription for user: ${body.Email}`, body);
};