import React, { useContext, useState } from 'react';
import { ModalContext } from './DefectDetail';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PhaseIcon from './Icons/Phase Icon.png';
import OrbitIcon from './Icons/Orbit Icon.png';
import TransientIcon from './Icons/Transient Icon.png';
import TWFIcon from './Icons/TWF Icon.png';
import UltrasonicIcon from './Icons/Ultrasonic Icon.png';
import theme from "../../../theme";
import { Opacity } from '../../Generic/MiscFunctions';

export default function DefectElement({ elementData, isActiveElement, isPlaceHolder, isActiveDefect, handleElementChange, activeDefects, userID }) {
    const modalContext = useContext(ModalContext);
    const [isFocused, setFocus] = useState(false);

    const getIconPath = (iconName) => {
        switch (iconName) {
            case 'Phase':
                return PhaseIcon;
            case 'Orbit':
                return OrbitIcon;
            case 'Transient':
                return TransientIcon;
            case 'TWF':
                return TWFIcon;
            case 'Ultrasonic':
                return UltrasonicIcon;
            default:
                return ''; // default case to handle undefined or unexpected values
        }
    };

    const iconPath = getIconPath(elementData?.Icon);

    const getElementColor = (isPlaceHolder, dominantDirectionalGrouping, defect) => {
        if (isPlaceHolder === 'Whitespace') {
            return theme.palette.element.white
        }
        else if (isPlaceHolder === "Placeholder") {
            return Opacity(theme.palette.grey.main, 0.2)
        }
        if (elementData) {
            if (defect) {
                switch (dominantDirectionalGrouping) {
                    case 'R':
                    case 'A':
                    case 'R/A':
                        if (defect.PercentComplete > 0) {
                            return Opacity(theme.palette.error.main, defect.PercentComplete / 100);
                        } else {
                            return theme.palette.grey.main
                        }

                    case '': return theme.palette.grey.main;
                    default: return theme.palette.grey.main;
                }
            } else {
                return theme.palette.grey.main
            }
        } else {
            return theme.palette.element.white
        }
        
    };
    let defect = activeDefects.find(dr => dr.DefectName == elementData.DisplayName);
    // Calculate background color based on placeholder status and active/defect states
    const bgColor = getElementColor(isPlaceHolder, elementData.DominantDirectionalGrouping, defect);
    const hoverColor = (isPlaceHolder !== "Placeholder" && isPlaceHolder !== "Whitespace")
        ? theme.palette.grey.main
        : theme.palette.element.white;

    let elementStyles = {
        backgroundColor: bgColor,
        cursor: (isPlaceHolder !== "Placeholder" && isPlaceHolder !== "Whitespace")
            ? 'pointer' : 'default',
        borderRadius: '16px',
        transition: 'background-color 0.3s, transform 0.2s ease-in-out',
        width: '100%',
        height: 90,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: (isPlaceHolder !== "Placeholder" && isPlaceHolder !== "Whitespace") ? hoverColor : bgColor,
            transform: (isPlaceHolder !== "Placeholder" && isPlaceHolder !== "Whitespace") ? 'scale(1.05)' : '',
        },
    };

    if (isActiveElement) {
        elementStyles['&:hover'] = {
            backgroundColor: (isPlaceHolder !== "Placeholder" && isPlaceHolder !== "Whitespace") ? hoverColor : bgColor,
            transform: (isPlaceHolder !== "Placeholder" && isPlaceHolder !== "Whitespace") ? 'scale(1.05)' : '',
            border: `2px solid ${hoverColor}`
        };
        elementStyles.boxShadow = '0px 0px 10px rgba(0,0,0,0.5)';
        elementStyles.border = `2px solid ${hoverColor}`;
    }

    // Event handlers only for active element
    const handleMouseEnter = () => setFocus(true);
    const handleMouseLeave = () => setFocus(false);
    const handleClick = () => {
        if (elementData) {
            modalContext(elementData);
            let defect = activeDefects.find(dr => dr.DefectName == elementData.DisplayName);
            if (defect) {
                handleElementChange(defect);
            }
        } else {
        }
    };

    return (
        <Box
            {...(isActiveDefect && {
                onClick: handleClick,
                onMouseEnter: handleMouseEnter,
                onMouseLeave: handleMouseLeave,
            })}
            sx={elementStyles}
        >
            {elementData && elementData.RecipeID !== null && (
                <Box sx={{
                    mt: 0.15,
                    mb: 0.15,
                    position: 'relative',
                    width: "90%", // 100% width on extra small screens, fixed width otherwise
                    height: 80, // auto height on extra small screens, fixed height otherwise
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .responsive-text': {
                        fontSize: { xs: '1rem', sm: '0.75rem' }, // Responsive font size
                    }
                }}>
                    {/* Top Left */}
                    <Typography sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        fontSize: '0.75rem',
                        color: theme.palette.element.white,
                        display: { xs: 'none', xl: 'block' }
                    }}>
                        {elementData?.FrequencyGrouping?.length === 2
                            ? <>
                                {elementData.FrequencyGrouping.charAt(0)}
                                <sub>{elementData.FrequencyGrouping.charAt(1)}</sub>
                            </>
                            : elementData.FrequencyGrouping
                        }
                    </Typography>


                    {/* Top Right */}
                    {iconPath && (
                        <Box sx={{ position: 'absolute', top: 0, right: 0, display: { xs: 'none', xl: 'block' } }}>
                            <img src={iconPath} alt={elementData.Icon} style={{ width: '15px', height: '15px' }} />
                        </Box>
                    )}

                    {/* Bottom Left */}
                    <Typography sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        fontSize: '0.75rem',
                        color: theme.palette.element.white,
                        display: { xs: 'none', xl: 'block' }
                    }}>
                        {elementData.DominantDirectionalGrouping}
                    </Typography>

                    {/* Bottom Right */}
                    <Typography sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        fontSize: '0.75rem',
                        color: theme.palette.element.white
                    }}>
                        {/* {elementData.Icon}*/}
                    </Typography>

                    {/* Center Content */}
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                color: theme.palette.element.white
                            }}
                        >
                            {elementData.MainSymbol}{<sub>{elementData.SubSymbol}</sub>}
                        </Typography>
                        <Typography sx={{
                            fontSize: '0.75rem',
                            color: theme.palette.element.white
                        }}>
                            {elementData.ShortName}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
