import { Box, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import React, { useState } from "react";
import Data from "./data.json";
import Modal from "./DefectModal";
import DefectPeriodicTableGrid from "./DefectPeriodicTableGrid";


// Expected Parameter: defects: Array, result set from master defect list
export default function DefectPeriodTable({
    userID, activeRecipeID, defectRecipes, defaultRecipeStateID, selectedValue, handleElementChange, open, modalData, toggleModal })
{
    const [textColor] = useState(blueGrey);

    return (
        <Box sx={{ pt: '2%' }}>
            <Modal
                open={open}
                toggleModal={toggleModal}
                modalData={modalData}
                userID={userID}
                selectedDefect={selectedValue}
            />
            <DefectPeriodicTableGrid
                tableElements={Data.elements}
                recipeID={activeRecipeID}
                activeDefects={defectRecipes}
                defaultRecipeStateID={defaultRecipeStateID}
                handleElementChange={handleElementChange}
                userID={userID}
            />
        </Box>

    )
}


