import React, { useState } from 'react';
import { Paper, Grid, Button, Box, Modal, Typography } from '@mui/material';
import FormikSelectField from '../../Generic/FormikSelectField';
import NumericInputField from '../../Generic/NumericInputField';
import FormikNodeAssignment from './FormikNodeAssignment';
import AssetAlignmentModalContent from '../AssetAlignmentModalContent';
import FormikSwitch from '../../Generic/FormikSwitch';
import FormikGearBox from "./FormikGearBox";
import AssetImage from "../AssetImage";

const gearBoxLocations = [
    {
        "value": 0,
        "label": "No Gearbox"
    },
    {
        "value": 1,
        "label": "P1/P2"
    },
    {
        "value": 2,
        "label": "P2/P3"
    },
    {
        "value": 3,
        "label": "P3/P4"
    },
]

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function VBTxConfigurationStep2(props) {

    const [alignModalOpen, setAlignModalOpen] = useState(false);
    const handleAlignModalOpen = () => setAlignModalOpen(true);
    const handleAlignModalClose = () => setAlignModalOpen(false);

    const handleOrientationChange = (event, posIdx) => {
        props.formik?.setFieldValue('p1AssignedNode.axisAlignment', event.target.value)
    }

    const handleDriveTypeChange = (event) => {
        props.formik?.handleBlur({
            target: {
                name: 'driveType',
            },
        });
        if (props.formik?.values.driveType === 0) {
            props.formik?.setFieldValue('shaftSpeed', 1800);

            props.formik?.setFieldValue('p1AssignedNode.shaftSpeedSource', 1);
            props.formik?.setFieldValue('p2AssignedNode.shaftSpeedSource', 1);
            props.formik?.setFieldValue('p3AssignedNode.shaftSpeedSource', 1);
            props.formik?.setFieldValue('p4AssignedNode.shaftSpeedSource', 1);
            props.formik?.setFieldValue('disableShaftSpeedSource', true);
        } else {
            if (props.formik?.values.driveType === 2) {
                props.formik?.setFieldValue('p1AssignedNode.shaftSpeedSource', 2);
                props.formik?.setFieldValue('p2AssignedNode.shaftSpeedSource', 2);
                props.formik?.setFieldValue('p3AssignedNode.shaftSpeedSource', 2);
                props.formik?.setFieldValue('p4AssignedNode.shaftSpeedSource', 2);
            }
            props.formik?.setFieldValue('shaftSpeedLoBound', 1500);
            props.formik?.setFieldValue('shaftSpeedHiBound', 3900);

            props.formik?.setFieldValue('disableShaftSpeedSource', false);
        }
        
    }

    const handleGearboxChange = (event) => {
        props.formik?.handleBlur({
            target: {
                name: 'gearboxLocation',
            },
        });
        if (event.target.value == 0) {
            props.formik?.setFieldValue('inputToothCount', 0);
            props.formik?.setFieldValue('outputToothCount', 0);
        } else {
            props.formik?.setFieldValue('bladeCount', 0);
        }

    }
    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >
                {props.viewAsset && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'driveType'}
                        label={'Drive Type'}
                        data={props.enums.driveTypes}
                        onBlur={handleDriveTypeChange}
                        errors={props.formik?.errors["driveType"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {(props.formik?.values.driveType == 0 || !props.viewAsset) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'shaftSpeed'}
                        label={'Shaft Speed'}
                        placeholder={'Shaft Speed'}
                        onBlur={props.formik?.onBlur}
                        form={props.formik}
                        step={60}
                        errors={props.formik?.errors['shaftSpeed']}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>}
                {(props.formik?.values.driveType != 0 || !props.viewAsset) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'shaftSpeedLoBound'}
                        label={'Shaft Speed Lower Bound'}
                        placeholder={'Shaft Speed Lower Bound'}
                        form={props.formik}
                        step={60}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['shaftSpeedLoBound']}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>}
                {(props.formik?.values.driveType != 0 || !props.viewAsset) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'shaftSpeedHiBound'}
                        label={'Shaft Speed Upper Bound'}
                        placeholder={'Shaft Speed Upper Bound'}
                        form={props.formik}
                        step={60}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['shaftSpeedHiBound']}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>}
                {props.viewAsset && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'gearboxLocation'}
                        label={'Gearbox Location'}
                        data={gearBoxLocations}
                        onBlur={handleGearboxChange}
                        errors={props.formik?.errors["gearboxLocation"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {(props.formik?.values.gearboxLocation == 0 || !props.viewAsset) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'bladeCount'}
                        label={props.viewAsset ? 'Blade Count' : 'Blade/Gear Tooth Count'}
                        placeholder={props.viewAsset ? 'Blade Count' : 'Blade/Gear Tooth Count'}
                        form={props.formik}
                        step={1}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['bladeCount']}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>}
                {props.formik?.values.gearboxLocation != 0 && props.formik?.values.gearboxLocation != null &&
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikGearBox
                        formik={props.formik}
                    />
                </Grid>}
                {props.viewAsset && < Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikNodeAssignment
                        formik={props.formik}
                        name={"p1AssignedNode"}
                        label={"P1 Assigned Node"}
                        unassignedNodes={props.unassignedNodes}
                        enums={props.enums}
                        users={props.users}
                        userGroups={props.userGroups}
                    />
                </Grid>}
                {props.viewAsset && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikNodeAssignment
                        formik={props.formik}
                        name={"p2AssignedNode"}
                        label={"P2 Assigned Node"}
                        unassignedNodes={props.unassignedNodes}
                        enums={props.enums}
                        users={props.users}
                        userGroups={props.userGroups}
                    />
                </Grid>}
                {props.viewAsset && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikNodeAssignment
                        formik={props.formik}
                        name={"p3AssignedNode"}
                        label={"P3 Assigned Node"}
                        unassignedNodes={props.unassignedNodes}
                        enums={props.enums}
                        users={props.users}
                        userGroups={props.userGroups}
                    />
                </Grid>}
                {props.viewAsset && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikNodeAssignment
                        formik={props.formik}
                        name={"p4AssignedNode"}
                        label={"P4 Assigned Node"}
                        unassignedNodes={props.unassignedNodes}
                        enums={props.enums}
                        users={props.users}
                        userGroups={props.userGroups}
                    />
                </Grid>}
                {props.viewAsset && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <AssetImage machineType={props.formik?.values.machineType} />
                </Grid>}
                {!props.viewAsset && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'p1AssignedNode.shaftSpeedSource'}
                        label={'Shaft Speed Source'}
                        data={props.enums.shaftSpeedSources}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['p1AssignedNode']?.shaftSpeedSource}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {!props.viewAsset && <Grid item xs={3} display="flex" justifyContent="flex-start" alignItems="center">
                    <Button variant="contained" onClick={handleAlignModalOpen}>Set Axis Alignment</Button>
                    <Modal
                        open={alignModalOpen}
                        onClose={handleAlignModalClose}
                        aria-labelledby="align-modal-title"
                        aria-describedby="align-modal-description"
                    >
                        <Box sx={modalStyle}>
                            <AssetAlignmentModalContent
                                enums={props.enums}
                                positionIdx={props.position}
                                value={props.formik?.values['p1AssignedNode']?.axisAlignment}
                                onChange={handleOrientationChange}
                                closeModals={handleAlignModalClose}
                            />
                        </Box>
                    </Modal>
                </Grid>}
                {!props.viewAsset && <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={'p1AssignedNode.xEnabled'}
                        label={'X Axis Enabled'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['p1AssignedNode']?.xEnabled}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {!props.viewAsset && <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={'p1AssignedNode.yEnabled'}
                        label={'Y Axis Enabled'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['p1AssignedNode']?.yEnabled}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {!props.viewAsset && <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={'p1AssignedNode.zEnabled'}
                        label={'Z Axis Enabled'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['p1AssignedNode']?.zEnabled}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {!props.viewAsset &&
                    props.formik?.errors['p1AssignedNode'] && <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
                        <Typography color="red">{props.formik?.errors['p1AssignedNode']}</Typography>
                    </Grid>}
            </Grid>
        </Paper>
    )
}